import bound from 'bound-decorator';
import ApplicationController from "../application_controller"

export default class extends ApplicationController {

  static targets = [
    'itemContainer',
    'navigationDotsContainer',
    'navigationDotTemplate',
  ];


  connect() {
    const maxDotsAttribute = parseInt(this.data.get('max-dots-value'), 10);
    this.maxDots = maxDotsAttribute ? maxDotsAttribute : 3;
    this.updateNavigation();
    this.window.addEventListener('resize', this.updateNavigation, false);
    this.itemContainerTarget.addEventListener('scroll', this.onItemContainerScroll, false);
    this.goToSlide(0);
  }


  @bound
  updateNavigation() {
    const totalDots = Math.min(this.maxDots, Math.ceil(this.itemContainerTarget.scrollWidth / this.itemContainerTarget.offsetWidth));

    if (this.itemContainerTarget.scrollWidth > this.itemContainerTarget.offsetWidth) {
      this.element.classList.add('slider--x-enabled');
    } else {
      this.element.classList.remove('slider--x-enabled');
    }

    this.navigationDotsContainerTarget.innerHTML = '';
    for (var i = 0; i < totalDots; i++) {
      let navigationDotTemplateInstance = this.navigationDotTemplateTarget.content.cloneNode(true);
      this.navigationDotsContainerTarget.appendChild(navigationDotTemplateInstance);
      let navigationDotTemplateInstanceNode = this.navigationDotsContainerTarget.children[i];
      navigationDotTemplateInstanceNode.setAttribute('href', '#' + i);
    }

    this.updateNavDots();
  }


  @bound
  goToSlide(slideIndex) {
    const totalDots = this.navigationDotsContainerTarget.children.length;
    const scrollableWidth = this.itemContainerTarget.scrollWidth - this.itemContainerTarget.offsetWidth;
    let newScrollLeft = 0;

    if (totalDots > 1) {
      newScrollLeft = (slideIndex / (totalDots - 1)) * scrollableWidth;
    }

    this.itemContainerTarget.scrollLeft = newScrollLeft;
    this.updateNavigation();
  }


  @bound
  updateNavDots(slideIndex = 0) {
    [].forEach.call(this.navigationDotsContainerTarget.children, (dot, i) => {
      if (i === slideIndex) {
        dot.classList.add('active');
      } else {
        dot.classList.remove('active');
      }
    });
  }


  @bound
  onItemContainerScroll() {
    const totalDots = this.navigationDotsContainerTarget.children.length;
    const scrollableWidth = this.itemContainerTarget.scrollWidth - this.itemContainerTarget.offsetWidth;
    const scrollLeft = this.itemContainerTarget.scrollLeft;
    const slideIndex = Math.round((scrollLeft / scrollableWidth) * (totalDots - 1));

    this.updateNavDots(slideIndex);
  }


  @bound
  onNavigationDotClick(e) {
    e.preventDefault();
    const href = e.target.getAttribute('href');
    const index = parseInt(href.substr(href.indexOf('#') + 1, href.length), 10);
    this.goToSlide(index);
  }

}
