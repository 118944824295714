import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  connect() {
    const playbackDelay = parseInt(this.data.get('playback-delay'), 10) || 5000;

    this.itemDOMNodes = this.element.querySelectorAll('a[data-src]');
    this.selectedIndex = 0;
    this.totalItems = this.itemDOMNodes.length;

    this.itemDOMNodes.forEach((itemDOMNode) => {
      itemDOMNode.setAttribute('target', '_blank');
      const img = this.window.document.createElement('img');
      img.src = itemDOMNode.getAttribute('data-src');
      itemDOMNode.appendChild(img);
    });

    if (this.totalItems > 1) {
      this.playbackDelayID = this.window.setInterval(this.onPlaybackDelay, playbackDelay);
    }
    this.setSelectedBanner(this.selectedIndex);
  }

  disconnect() {
    if (this.playbackDelayID) {
      window.clearInterval(this.playbackDelayID);
    }
  }

  @bound
  onPlaybackDelay() {
    this.showNextBannerOrLoopToFirst();
  }

  @bound
  showNextBannerOrLoopToFirst() {
    this.selectedIndex = ((this.selectedIndex + 1) === this.totalItems) ? 0 : (this.selectedIndex + 1);
    this.setSelectedBanner(this.selectedIndex);
  }

  @bound
  setSelectedBanner(index) {
    this.itemDOMNodes.forEach((itemDOMNode, i) => {
      itemDOMNode.setAttribute('aria-current', (i === index) ? 'true' : 'false');
    });
  }

}
