import ApplicationController from 'javascript/controllers/application_controller';
import Rails from '@rails/ujs';
import replaceContent from 'javascript/utils/replace_content';
import { trackPageView } from 'javascript/utils/utils';

export default class extends ApplicationController {
  get eventSlug() {
    return this.data.get('eventSlug');
  }

  get registrationId() {
    return this.data.get('registrationId');
  }

  success() {
    const path = `/events/${this.eventSlug}/registrations/${this.registrationId}/details`;
    Rails.ajax({
      url: path,
      type: 'GET',
      success: response => {
        trackPageView(path);
        replaceContent(this.element.parentElement, response.body.childNodes);
      }
    });
  }
}
