import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['menu', 'overlay', 'button'];

  get state() {
    return this.data.get('state');
  }

  set state(value) {
    this.data.set('state', value);
  }

  connect() {
    this.document.addEventListener('turbolinks:before-cache', this.hideAll, false);
  }

  disconnect() {
    this.document.removeEventListener('turbolinks:before-cache', this.hideAll);
  }

  @bound
  hideAll() {
    this.state = 'closed';
    this.menuTarget.style.display = 'none';
    this.overlayTarget.style.display = 'none';
    this.buttonTarget.classList.remove('open');
  }

  open() {
    this.state = 'opening';
    this.buttonTarget.classList.add('open');

    this.show();

    setTimeout(() => {
      this.document.body.classList.add('hamburger-is-open');

      let openOverlayHandler = () => {
        this.overlayTarget.removeEventListener("transitionend", openOverlayHandler, false);
        this.menuTarget.classList.add('is-open');
        this.state = 'open';
      };

      this.overlayTarget.addEventListener("transitionend", openOverlayHandler, false);
    },0);
  }

  close() {
    this.state = 'closing';
    this.document.body.classList.remove('hamburger-is-open');
    this.buttonTarget.classList.remove('open');

    let closeOverlayHandler = () => {
      this.overlayTarget.removeEventListener("transitionend", closeOverlayHandler, false);
      this.menuTarget.classList.remove('is-open');
      this.hide();
      this.state = 'closed';
    };

    this.overlayTarget.addEventListener("transitionend", closeOverlayHandler, false);
  }

  toggle() {
    switch (this.state) {
      case 'closed':
        this.open();
        break;
      case 'open':
      case 'opening':
        this.close();
        break;
      case 'closing':
        break;
    }
  }

  show() {
    this.menuTarget.style.display = 'block';
    this.overlayTarget.style.display = 'block';
  }

  hide() {
    this.menuTarget.style.display = 'none';
    this.overlayTarget.style.display = 'none';
  }
}