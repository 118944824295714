import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

const EVENT_CAROUSEL_SETTINGS = {
  mobileFirst: true,
  infinite: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  slide: '.event-carousel__slide',
  dots: false,
  centerMode: true,
  centerPadding: '1rem'
};

const TITLE_ACTIVE_CLASS = 'event-carousel__title--active';

export default class extends ApplicationController {
  static targets = [
    'track',
    'title'
  ];

  get isSlickInitialized() {
    return this.trackTarget.classList.contains('slick-initialized');
  }

  connect() {
    this.document.addEventListener('turbolinks:load', this.setup, false);

    $(this.trackTarget).on('afterChange', this.afterChange);

    $(this.window).on('changed.zf.mediaquery', this.slickOrUnslick);
    this.document.addEventListener('turbolinks:before-cache', this.unslick, false);

    this.slickOrUnslick();
  }

  disconnect() {
    $(this.window).off('changed.zf.mediaquery', this.slickOrUnslick);
    this.document.removeEventListener('turbolinks:before-cache', this.unslick);
  }

  @bound
  setup() {
    this.document.removeEventListener('turbolinks:load', this.setup);
    this.slickOrUnslick();
  }

  goToSlide({ target }) {
    const title = target.closest(`[data-target="${this.identifier}.title"]`);
    $(this.trackTarget).slick('slickGoTo', parseInt(title.dataset.slideIndex, 10));
  }

  @bound
  afterChange(_, { currentSlide }) {
    [].forEach.call(this.titleTargets, t => {
      if (currentSlide === parseInt(t.dataset.slideIndex, 10)) {
        t.classList.add(TITLE_ACTIVE_CLASS);
      } else {
        t.classList.remove(TITLE_ACTIVE_CLASS);
      }
    });
  }

  @bound
  slickOrUnslick() {
    if (Foundation.MediaQuery.atLeast('medium')) {
      this.unslick();
    } else {
      this.slick();
    }
  }

  slick() {
    if (!this.isSlickInitialized) {
      $(this.trackTarget).slick(EVENT_CAROUSEL_SETTINGS);
      this.afterChange(null, { currentSlide: 0 })
    }
  }

  @bound
  unslick() {
    if (this.isSlickInitialized) {
      $(this.trackTarget).slick('unslick');
    }
  }
}