import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'output',
    'completionForm',
    'timeDays',
    'timeHours',
    'timeMinutes',
    'timeSeconds'
  ];

  get startsAt() {
    return this.data.get('startsAt') ? new Date(this.data.get('startsAt')) : new Date();
  }

  get expiresAt() {
    return new Date(this.data.get('expiresAt'));
  }

  connect() {
    const browserDateNow = new Date();
    const suppliedDateNow = this.startsAt;
    this.browserVersusSuppliedClockDelta = browserDateNow.getTime() - suppliedDateNow.getTime();
    this.start();
  }

  disconnect() {
    this.stop();
  }

  start() {
    this.stop();
    this.update(new Date());
    this.interval = setInterval(() => {
      this.update(new Date());
    }, 100);
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  update(now) {
    const distance = Math.max(Math.ceil((this.expiresAt.getTime() - now.getTime() + this.browserVersusSuppliedClockDelta) / 1000), 0);

    if (distance === 0) {
      if (this.hasCompletionFormTarget) {
        Rails.fire(this.completionFormTarget, 'submit');
      } else if (this.data.get('shouldFireCompleteEvent')) {
        Rails.fire(this.element, 'howler:countdown.complete');
      } else {
        window.location.reload();
      }
      this.stop();
    }

    this.updateTimeOutput(distance);
  }

  updateTimeOutput(distance) {
    const days = String(Math.floor(distance / (60 * 60 * 24))).padStart(2, '0');
    const hours = String(Math.floor((distance % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0');
    const minutes = String(Math.floor((distance % (60 * 60)) / (60))).padStart(2, '0');
    const seconds = String(Math.floor((distance % (60)))).padStart(2, '0');

    if (this.hasOutputTarget) {
      this.outputTarget.textContent = `${minutes}:${seconds}`;
    } else if (this.hasTimeDaysTarget) {
      this.timeDaysTarget.textContent = days;
      this.timeHoursTarget.textContent = hours;
      this.timeMinutesTarget.textContent = minutes;
      this.timeSecondsTarget.textContent = seconds;
    }
  }
}
