import { childTarget } from './../../utils/utils';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = [
    'addTicketInsuranceRadio',
    'donation',
    'donationLineItem',
    'donationLineItemValue',
    'customerFeeEstimate',
    'customerFeeLineItem',
    'conditionalLineItem',
    'cartTotal',
    'labelWithRefundProtect',
    'labelWithoutRefundProtect',
    'layupEstimate',
    'lineItem',
    'refundProtect',
    'refundProtectLineItem',
    'refundProtectLineItemValue',
    'paymentOptionInput',
  ];

  get currencyFormatter() {
    return (this._currencyFormatter ||= new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: this.currency,
    }));
  }

  get cartTotalMinusPaymentFee() {
    // This should be in values
    return this.cartTotal - this.customerFeeLineItemTarget.dataset.value;
  }

  get cartTotal() {
    return this.lineItemTargets.reduce(
      (total, lineItem) =>
        (total += this.tryParseFloat(lineItem.dataset.value)),
      0.0,
    );
  }

  get currency() {
    return this.data.get('currency');
  }

  cartUpdated(e) {
    const { source, newValue, refundProtectSelected } = e.detail;

    const newAmount = this.tryParseFloat(newValue);

    switch (source) {
      case 'donation':
        this.updateLineItem(this.donationLineItemTarget, newAmount);
        this.donationTarget.value = newAmount;
        break;
      case 'refundProtect':
        this.refundProtectTarget.value = refundProtectSelected ? 1 : 0;
        this.updateLineItem(this.refundProtectLineItemTarget, newAmount);
    }

    this.updateLayupEstimate(refundProtectSelected);
    this.updateCustomerFeeEstimate();
    this.updateCustomerFeeLineItemTarget();
  }

  updateCustomerFeeLineItemTarget() {
    const checkedTarget = this.paymentOptionInputTargets.find(
      (inputTarget) => inputTarget.checked,
    );
    let newValue = 0;

    if (checkedTarget) {
      const target = this.customerFeeEstimateTargets.find(
        (estimateTarget) =>
          estimateTarget.dataset.paymentMethod == checkedTarget.value,
      );
      newValue = this.formattedCustomerFeeEstimate(target);
    }
    this.updateLineItem(this.customerFeeLineItemTarget, newValue);
  }

  updateLayupEstimate(refundProtectSelected) {
    this.layupEstimateTargets.forEach((target) => {
      const targetLabel = refundProtectSelected
        ? target.dataset.labelWithRefundProtect
        : target.dataset.labelWithoutRefundProtect;

      target.innerText = targetLabel;
    });
  }

  updateCustomerFeeEstimate() {
    this.customerFeeEstimateTargets.forEach((target) => {
      const estimate =
        target.dataset.labelCurrency +
        this.formattedCustomerFeeEstimate(target);

      target.innerText = estimate;

      // This could potentially be improved upon - find a more Stimulus way
      const customerFeeDescription = document.querySelector(
        `span.${target.dataset.paymentMethod}_customer_fee`,
      );

      if (customerFeeDescription) {
        customerFeeDescription.innerHTML = estimate;
      }
    });
  }

  formattedCustomerFeeEstimate(target) {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(this.calculateCustomerFeeEstimate(target));
  }

  calculateCustomerFeeEstimate(target) {
    if (!target) {
      return 0;
    }

    if (target.dataset.fixedFee) {
      return target.dataset.fixedFee;
    }

    const amount = this.bankersRound(
      (this.cartTotalMinusPaymentFee * target.dataset.percentageFee) / 100,
    );

    const minFee = parseFloat(target.dataset.minFee);
    const maxFee = parseFloat(target.dataset.maxFee);
    if (amount < minFee) {
      return minFee;
    } else if (amount > maxFee) {
      return maxFee;
    }

    return amount;
  }

  bankersRound(number) {
    const cents = number * 100;
    const rounded = Math.round(cents);

    let returnValue = rounded;
    if (cents % 1 === 0.5) {
      returnValue = rounded % 2 === 0 ? rounded : rounded - 1;
    }
    return returnValue / 100;
  }

  updateLineItem(lineItem, newAmount) {
    const lineItemValue = childTarget(this, 'lineItemValue', lineItem);

    lineItemValue.innerText = this.currencyFormatter.format(newAmount);
    lineItem.dataset.value = newAmount;

    this.updateCartDisplayValue();
    this.showHideConditionalLineItem(lineItem);
  }

  updateCartDisplayValue() {
    this.cartTotalTarget.innerText = this.currencyFormatter
      .format(this.cartTotal)
      .replace(/,/g, ' ');
  }

  showHideConditionalLineItem(lineItem) {
    const action =
      this.tryParseFloat(lineItem.dataset.value) > 0 ? 'remove' : 'add';
    lineItem.classList[action]('hidden');
  }

  showHideConditionalLineItems() {
    this.conditionalLineItemTargets.forEach((lineItem) =>
      this.showHideConditionalLineItem(lineItem),
    );
    this.updateCartDisplayValue();
  }

  tryParseFloat(value) {
    try {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? 0 : parsedValue;
    } catch (exception) {
      return 0;
    }
  }

  connect() {
    this.showHideConditionalLineItems();
  }
}
