import { performOnVisaCheckoutReady } from './visa-checkout-script';

export default function visaCheckoutInit(total, currency) {
  performOnVisaCheckoutReady(function() {
    V.init({
      apikey: "HP7W0WRW94M92NNRA2OC13k-JNa_XSNLFvfzvTKs08hvUwsZg",
      paymentRequest: {
        currencyCode: currency,
        total: total
      }
    });
  });
}