import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['field', 'input', 'fieldWrapper']


  get accountType() {
    return this.data.get('type');
  }

  get country() {
    return this.data.get('country');
  }

  get refreshFormUrl() {
    return this.data.get('refresh-form-url')
  }

  get formData() {
    const inputs = this.inputTargets
      .filter(input => input.dataset.refreshRequirementsOnChange === 'true')
      .map(input => `${input.name}=${input.value}`);

    return [...inputs, `country=${this.country}`, `account_type=${this.accountType}`].join('&');
  }

  refreshBankAccountForm() {
    Rails.fire(this.element, 'howler:transferwise--form.refreshStart');
    Rails.ajax({
      url: this.refreshFormUrl,
      type: 'POST',
      data: this.formData,
      success: response => {
        this.updateForm(response)
        Rails.fire(this.element, 'howler:transferwise--form.refreshEnd');
      }
    });
  }

  updateForm(response) {
    const fields = this.removeMissingFields(this.fieldTargets, response)
    this.updateChangedFields(fields, response)
    this.addNewFields(fields, response)
  }

  removeMissingFields(fields, response) {
    const keysTheyHave = response.fields.map(s => s.key)
    return [].filter.call(fields, f => {
      if (keysTheyHave.includes(f.dataset.fieldKey)) return true;
      f.parentElement.removeChild(f);
      return false;
    })
  }

  updateChangedFields(fields, response) {
    fields.forEach(f => {
      const s = response.fields.find(s => s.key === f.dataset.fieldKey)
      if (s.hash !== f.dataset.fieldHash) {
        f.innerHTML = s.html
        f.dataset.fieldHash = s.hash
      }
    })
  }

  addNewFields(fields, response) {
    for (let findex = 0, sindex = 0; sindex < response.fields.length; sindex++) {
      const f = fields[findex];
      const s = response.fields[sindex];

      if (!f) { // reached end of fields, append
        this.fieldWrapperTarget.appendChild(this.createNewField(s))
      } else if (f.dataset.fieldKey === s.key) { // same field, move on
        findex++
      } else { // found new field, insert
        f.parentElement.insertBefore(this.createNewField(s), f)
      }
    }
  }

  createNewField(s) {
    const div = document.createElement('div')
    div.classList.add('field');
    div.dataset.fieldKey = s.key;
    div.dataset.fieldHash = s.hash;
    div.dataset.target = `${this.identifier}.field`;
    div.dataset.refreshRequirementsOnChange = s.refresh_requirements_on_change
    div.innerHTML = s.html;
    return div;
  }
}
