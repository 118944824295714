import ApplicationController from 'javascript/controllers/application_controller';
import Rails from '@rails/ujs';

function dig(obj, key, ...keys) {
  if (obj === null || obj === undefined) return null;
  if (key === undefined) return obj;
  return dig(obj[key], ...keys);
}

export default class extends ApplicationController {
  static targets = [
    'form',
    'question'
  ];

  beforeSubmit() {
    this.element.classList.add('contents-loading');
    [].forEach.call(this.questionTargets, t => {
      Rails.fire(t, 'howler:data-capture.beforeSubmit');
    });
  }

  submitError({ detail: [response, error, xhr] }) {
    this.element.classList.remove('contents-loading');
    if (xhr.status !== 422) {
      this.window.alert(`Something went wrong: ${error}`);
      return;
    }
    [].forEach.call(this.questionTargets, t => {
      const id = parseInt(t.dataset.questionInstanceId, 10);
      const error = dig(response, 'errors', 'answers', id, 'response', 0);
      t.dataset.questionError = error ? error : '';
    });
  }

  change({ target, detail: [value] }) {
    target.dataset.questionError = '';
    const id = parseInt(target.dataset.questionInstanceId, 10);
    [].forEach.call(this.element.querySelectorAll(`[data-conditional-question-instance-id="${id}"]`), t => {
      const conditionalValue = t.dataset.conditionalQuestionValue;
      t.dataset.questionHidden = conditionalValue === value ? 'false' : 'true';
    });
  }
}