import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['content'];

  connect() {
    this.update = this.update.bind(this);

    this.window.addEventListener('load', this.update, false);
    this.window.addEventListener('resize', this.update, false);

    this.update();
  }

  disconnect() {
    this.window.removeEventListener('load', this.update);
    this.window.removeEventListener('resize', this.update);
  }

  update() {
    if (this.contentTarget.offsetHeight > this.element.offsetHeight) {
      this.element.classList.add('paragraph-overflows');
    } else {
      this.element.classList.remove('paragraph-overflows');
    }
  }
}