export function retrieveCookies(document = window.document) {
  const cookieHash = {};
  document.cookie.split(';').forEach(cookiePair => {
    const [key, value] = cookiePair.split('=').map(y => y.trim());
    cookieHash[key] = value;
  });
  return cookieHash;
}

const ONE_HOUR = 3600000;
export function setCookie(key, value, expires = new Date(Date.now() + ONE_HOUR), document = window.document) {
  document.cookie = `${key}=${value}; expires=${expires.toGMTString()}; path=/`
}

export function getCookie(key, document = window.document) {
  return retrieveCookies(document)[key];
}

export function deleteCookie(key, document = window.document) {
  setCookie(key, null, new Date(0), document);
}
