import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { doesMimeTypeExistInHTMLAcceptAttribute } from 'javascript/utils/utils';

export default class extends ApplicationController {

  static targets = [
    'fileInput',
    'previewImage',
  ];

  connect() {
    this.maxFileSizeAsBytes = this.data.get('maxFileSizeAsBytes') ? parseInt(this.data.get('maxFileSizeAsBytes'), 10) : Number.POSITIVE_INFINITY;
    this.setPreviewImageInitialState();
  }

  @bound
  onFileInputChange(e) {
    const file = e.target.files[0];
    if (e.target.files &&
      doesMimeTypeExistInHTMLAcceptAttribute(file.type, this.fileInputTarget.getAttribute('accept')) &&
      file.size < this.maxFileSizeAsBytes) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.updatePreviewImageState(e.target.result);
      }
      reader.readAsDataURL(file);
    } else {
      this.revertPreviewImageToInitialState();
    }
  }

  onFileUploadButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.fileInputTarget.click();
  }

  onCancelButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.revertPreviewImageToInitialState();
  }

  setPreviewImageInitialState() {
    this.fileInputInitialValue = this.fileInputTarget.value;
    this.previewImageTarget.setAttribute('data-initial-style', this.previewImageTarget.getAttribute('style'));
  }

  updatePreviewImageState(imageURL) {
    this.element.classList.add('user-avatar--preview-state-changed');
    this.previewImageTarget.setAttribute('style', `background-image: url('${imageURL}');`);
  }

  revertPreviewImageToInitialState() {
    this.element.classList.remove('user-avatar--preview-state-changed');
    this.previewImageTarget.setAttribute('style', this.previewImageTarget.getAttribute('data-initial-style'));
    this.fileInputTarget.value = this.fileInputInitialValue;
  }

}
