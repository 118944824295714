import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import Rails from '@rails/ujs';
import { trackGa4Event } from 'javascript/utils/utils';

export default class extends ApplicationController {
  static targets = ['errorContainer', 'error', 'input'];

  beforeSubmit() {
    this.element.classList.add('contents-loading');
  }

  submitSuccess({ detail: [, , xhr] }) {
    this.element.classList.remove('contents-loading');
    const ga4UserData = xhr.response;
    this.trackRegistration(ga4UserData);
    this.onSuccess(xhr.getResponseHeader('Location'));
  }

  trackRegistration(userJson) {
    try {
      const userProvidedData = JSON.parse(userJson);

      trackGa4Event('registration', {
        user_provided_data: {
          ...userProvidedData,
        },
      });
    } catch (exception) {
      console.error(exception);
    }
  }

  submitError({ detail: [response, , xhr] }) {
    this.element.classList.remove('contents-loading');

    let errors;
    switch (xhr.status) {
      case 422:
        errors = response.errors.user;
        break;
    }

    if (Object.entries(errors).length === 0 && errors.constructor === Object) {
      return;
    }

    this.errorContainerTarget.classList.remove('util-hidden');
    this.errorTarget.innerHTML = Object.keys(errors).reduce(
      (string, key) => `
      ${string}
      <div>${key[0].toUpperCase()}${key.slice(1).replace('_', ' ')} ${
        errors[key][0]
      }</div>
    `,
      '',
    );

    [].forEach.call(this.inputTargets, (input) => {
      if (errors[input.dataset.field]) {
        input.classList.add('has-error');
      } else {
        input.classList.remove('has-error');
      }
    });
  }

  @bound
  onSuccess(url) {
    Rails.fire(this.element, 'howler:user-setup.success', [url]);
  }
}
