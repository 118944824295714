import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  focus() {
    this.element.classList.add('is-focused');
  }

  blur() {
    this.element.classList.remove('is-focused');
  }
}