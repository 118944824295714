import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import autocomplete from 'autocomplete.js';
export default class extends ApplicationController {
  static targets = ['teamInput', 'selectedTeamName', 'selectedTeamSection', 'carousel', 'joinTeamForm', 'teamMemberIdsField', 'selectedTeamIdField', 'selectedTeamCreator', 'cancelSelection'];

  @bound
  connect() {
    this.teamInputSelector = '[data-target="team-search.teamInput"]';
    this.autoCompleteList = null;
    this.createAutoCompleteList();
    this.eventSlug = this.data.get('eventSlug');
  }

  createAutoCompleteList() {
    if (this.autoCompleteList === null) {
      this.autoCompleteList = autocomplete(
        this.teamInputSelector,
        { openOnFocus: true, debug: false, hint: false, minLength: 2 },
        [
          {
            source: this.search,
            displayKey: (suggestion) => suggestion.name,
            debounce: 200,
            templates: {
              empty: `<div class="no-results-found">No results found</div>`,
            },
          },
        ]
      );
    }
  }
  destroyAutoCompleteList() {
    if (this.autoCompleteList !== null) {
      this.autoCompleteList.autocomplete.destroy();
      this.autoCompleteList = null;
    }
  }

  onAutoCompleteSelected(event) {
    const suggestion = event._args[0];

    this.showSelectedTeam(suggestion);

    try {
      const teamSelectedEvent = new CustomEvent("teamSelected", {
        detail: {
          timeslotId: suggestion.timeslot_id
        },
      });

      this.element.dispatchEvent(teamSelectedEvent);
    } catch (error) {
      console.error('Could not dispatch event');
    }
  }

  showSelectedTeam(suggestion) {
    this.selectedTeamNameTarget.innerHTML = suggestion.name;
    this.selectedTeamSectionTarget.classList.remove('util-hidden');
    this.carouselTarget.classList.remove('util-hidden');
    this.joinTeamFormTarget.classList.add('util-hidden');
    this.selectedTeamIdFieldTarget.value = suggestion.id;
    this.selectedTeamCreatorTarget.innerHTML = `Created by ${suggestion.team_leader_full_name}`;
  }

  @bound
  search(query, callback) {
    const urlSafeQuery = encodeURI(query);
    this.apiRequest(
      `/events/${this.eventSlug}/teams/search?q=${urlSafeQuery}`,
      (data) => callback(data)
    );
  }

  onCancelButtonClicked() {
    this.cancelTeamSelection();
  }

  onTeamOptionChanged(event) {
    if (event.detail.selectedOption !== 'join_team') {
      this.cancelTeamSelection(false);
    }
  }

  cancelTeamSelection(hideCarousel = true) {
    this.teamInputTarget.value = '';
    this.selectedTeamSectionTarget.classList.add('util-hidden');
    this.joinTeamFormTarget.classList.remove('util-hidden');

    if (hideCarousel) {
      this.carouselTarget.classList.add('util-hidden');
    }

    try {
      this.element.dispatchEvent(new CustomEvent("teamSelectionCancelled"));
    } catch (error) {
      console.error('Could not dispatch event');
    }
  }

  apiRequest(url, callback) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => callback(data));
  }
}
