import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['continueButton', 'form'];

  continueButtonClicked() {
    this.window.addEventListener('pageshow', this.removeElementContentLoading);
    this.element.classList.add('contents-loading');
    this.formTarget.submit();
  }

  @bound
  removeElementContentLoading() {
    this.element.classList.remove('contents-loading');
  }
}
