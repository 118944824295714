import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['input', 'incrementButton', 'decrementButton'];

  connect() {
    this.showHideButtons();
  }

  incrementButtonClicked(e) {
    e.preventDefault();
    this.incrementQuantity();
    this.showHideButtons();
  }

  decrementButtonClicked(e) {
    e.preventDefault();
    this.decrementQuantity();
    this.showHideButtons();
  }

  incrementQuantity() {
    this.inputTarget.value = this.quantity + 1;
  }

  decrementQuantity() {
    this.inputTarget.value = this.quantity - 1;
  }

  showHideButtons() {
    const inputValue = parseInt(this.inputTarget.value);
    if (inputValue === 0) {
      this.decrementButtonTarget.classList.add('hidden');
    } else {
      this.decrementButtonTarget.classList.remove('hidden');
    }
  }

  get quantity() {
    return parseInt(this.inputTarget.value);
  }
}
