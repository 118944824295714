import PayAtController from "javascript/controllers/consumer_portal/pay_at_controller";

export default class extends PayAtController {
  static targets = [
    'container', 
    'form',
    'installments', 
    'startingDate',
    'textFirstPayment', 
    'textInstallments', 
    'textMonths', 
    'emptyDateError', 
    'invalidDateError', 
    'breakdown',
    'breakdownTBody',
    'submit'
  ]

  connect(){
    this.calculate();
  }

  calculate() {
    const installments = this.installmentsTarget.value
    this.textMonthsTarget.innerText = installments - 1 // -1 because we don't count the initial payment

    if (this.validateDate(false)) {
      this.populateTable();
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.breakdownTBodyTarget.innerHTML = '';
      this.breakdownTarget.classList.add('hidden');
      this.submitTarget.setAttribute('disabled', 'disabled');
    }
    this.textInstallmentsTarget.innerText = JSON.parse(this.formTarget.dataset.installments)[installments][2]
  }

  validateDate(testEmptyDate=true) {
    const date = this.startingDateTarget.value
    const minDate = new Date(this.startingDateTarget.min);
    const maxDate = new Date(this.startingDateTarget.max);
    if (date === '') {
      if (testEmptyDate){
        this.emptyDateErrorTarget.classList.remove('hidden');
      }
      this.invalidDateErrorTarget.classList.add('hidden');
      return false
    } else if (isNaN(Date.parse(date))){
      this.emptyDateErrorTarget.classList.add('hidden');
      this.invalidDateErrorTarget.classList.remove('hidden');
      return false
    } else if (new Date(date) >= minDate && new Date(date) <= maxDate) {
      this.emptyDateErrorTarget.classList.add('hidden');
      this.invalidDateErrorTarget.classList.add('hidden');
      return true;
    }
  }
  
  populateTable() {
    this.breakdownTBodyTarget.innerHTML = '';

    for (let i = 1; i <= this.installmentsTarget.value; i++) {
      const row = document.createElement('tr');

      // Number
      const numberCell = document.createElement('td');
      numberCell.textContent = this.ordinalize(i);
      row.appendChild(numberCell);

      // Date
      const dateCell = document.createElement('td');
      if (i === 1) {
        dateCell.textContent = 'Initial Installment'
      } else {
        // i - 1 because we want to start with the date of the first repayment, not the initial payment
        const futureDate = this.adjustDateFunction(this.startingDateTarget.value, Math.max(i - 2, 0)); 
        dateCell.textContent = `${futureDate.getDate()} ${futureDate.toLocaleString('default', { month: 'long' })} ${futureDate.getFullYear()}`;
      }
      row.appendChild(dateCell);

      // Amount
      const amountCell = document.createElement('td');
      amountCell.classList.add('repayment_amount');
      amountCell.textContent = JSON.parse(this.formTarget.dataset.installments)[this.installmentsTarget.value][i]
      row.appendChild(amountCell);
      
      this.breakdownTBodyTarget.appendChild(row);
    }

    this.breakdownTarget.classList.remove('hidden');
  }

  ordinalize(number) {
    if (typeof number !== 'number') {
      throw new Error('Input must be a number');
    }

    if (number % 100 >= 11 && number % 100 <= 13) {
      return number + 'th';
    }

    switch (number % 10) {
      case 1:
        return number + 'st';
      case 2:
        return number + 'nd';
      case 3:
        return number + 'rd';
      default:
        return number + 'th';
    }
  }

  adjustDateFunction(inputDate, monthIncrement) {
    const [yearStr, monthStr, dayStr] = inputDate.split("-");
    let year = parseInt(yearStr);
    const month = parseInt(monthStr); // Month is 0-based in JavaScript
    let day = parseInt(dayStr);
    let newMonth = (month + monthIncrement).toString().padStart(2, '0');
    if (monthIncrement >= 1) {
      while (parseInt(newMonth) > 12) {
        year = year + 1;
        newMonth = (parseInt(newMonth) - 12).toString().padStart(2, '0');
      }
      
      let valid = false;
      while (!valid) {
        let adjustedDate = new Date(year + '-' + newMonth + '-' + day);
        if ((adjustedDate.getMonth() + 1).toString().padStart(2, '0') !== newMonth) {
          day = day - 1;
        } else {
          valid = true
        }
      }
    }
    return new Date(year + '-' + newMonth + '-' + day);
  }

  before(event) {
    this.containerTarget.classList.add('contents-loading');

    if (this.validateDate() == false || this.installmentsTarget.value == '') {
      event.preventDefault();
    }

    this.containerTarget.classList.remove('contents-loading');
  }
}
