import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    "errorContainer",
    "errorText"
  ];

  get clientSecret() {
    return this.data.get('clientSecret');
  }

  get successPath() {
    return this.data.get('successPath');
  }

  get failurePath() {
    return this.data.get('failurePath');
  }

  get publishableKey() {
    return this.data.get('publishableKey');
  }

  connect() {
    this.createStripeElements(() => {
      this.hideButtonLoader(this.element);
    });
  }

  createStripeElements(callback) {
    this.showButtonLoader(this.element);

    this.stripe = Stripe(this.publishableKey);
    this.elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400'
        }
      ]
    })

    this.style = {
      base: {
        color: "#3C4852",
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif",
        lineHeight: "32px"
      }
    };

    this.cardNumber = this.elements.create('cardNumber', {
      style: this.style,
    });

    this.cardExpiry = this.elements.create('cardExpiry', {
      style: this.style,
    });

    this.cardCvc = this.elements.create('cardCvc', {
      style: this.style,
    });

    this.cardZIP = this.elements.create('postalCode', {
      style: this.style,
    });

    this.cardCvc.mount('#card-cvc');
    this.cardExpiry.mount('#card-expiry');
    this.cardZIP.mount('#card-zip-code');
    this.cardNumber.mount('#card-number');

    this.cardNumber.on('ready', callback);
  }

  onSubmit(event) {
    event.preventDefault();

    this.showButtonLoader(this.element);

    this.stripe.confirmCardPayment(this.clientSecret, {
      payment_method: { card: this.cardNumber }
    }).then((result) => {
      if (result.error && result.error.code === 'payment_intent_authentication_failure') {
        this.hideErrorContainer()
        window.location.replace(this.failurePath);
      } else if (result.error) {
        this.hideButtonLoader(this.element);
        this.errorContainerTarget.classList.remove("hide");
        this.errorTextTarget.textContent = result.error.message;
      } else {
        this.hideErrorContainer()
        window.location.replace(this.successPath);
      }
    });
  }

  hideErrorContainer() {
    if (!this.errorContainerTarget.classList.contains("hide")) {
      this.errorContainerTarget.classList.add("hide");
    }
  }

  disconnect() {
    this.hideButtonLoader(this.element);
  }

  showButtonLoader(element) {
    element.classList.add('contents-loading');
  }

  hideButtonLoader(element) {
    element.classList.remove('contents-loading');
  }
}
