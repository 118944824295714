import ApplicationController from 'javascript/controllers/application_controller';
import bound from 'bound-decorator';
import { trackGa4Event } from 'javascript/utils/utils';

export default class extends ApplicationController {
  static targets = [
    'departureLocation',
    'departureDateLabel',
    'departureDateInput',
    'departureTimeInput',
    'arrivalLocation',
    'returnDateInput',
    'returnDateLabel',
    'returnTimeInput',
    'totalLabel',
    'submitButton',
    'tripTypeInput'
  ];

  @bound
  connect() {
    document.addEventListener('dateTimeSubmitted', this.dateChanged);
    this.tripType = this.tripTypeInputTargets.find(input => input.checked).value
    this.sources = {};
    this.initialDateLabel = this.departureDateLabelTarget.innerText;
    this.ticketQuantity = 1;
    this.updateTotal();
  }

  get currencyFormatter() {
    return (this._currencyFormatter ||= new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: this.currency,
    }));
  }

  get currency() {
    return this.data.get('currency');
  }

  get ecommerceData() {
    return {
      currency: this.currency,
      value: this.total,
      items: [
        {
          item_id: this.id,
          item_name: this.name,
          affiliation: this.organiser,
          item_category: 'TICKET',
          item_category2: 'transportation',
          item_category3: this.tripType,
          price: this.sourcesToPrice(),
          quantity: this.ticketQuantity,
        },
      ],
    };
  }

  get name() {
    this.data.get('name');
  }

  get organiser() {
    this.data.get('organiser');
  }

  get id() {
    this.data.get('id');
  }

  get total() {
    return this._total;
  }

  set total(value) {
    this._total = value;
  }

  @bound
  dateChanged(e) {
    const { date, time, source } = e.detail;
    console.log('date changed');
    console.log({ date, time, source });

    this.updateSources(date.value, time, source);
    this.updateDateLabels(date.label, time.time, source);
    this.updateInputs(date.value, time.time, source);
    this.updateTotal();

    if (this.tripType === 'one_way_trip' || this.source === 'return') {
      trackGa4Event('view_item', this.ecommerceData);
    }
  }

  onDepartureLocationChanged(e) {
    this.onLocationChanged({
      departureLocation: this.departureLocationTarget.value,
    });
  }

  onArrivalLocationChanged(e) {
    this.onLocationChanged({ returnLocation: this.returnLocationTarget.value });
  }

  onLocationChanged(_detail) {
    this.resetInputsAndLabels();
    this.sources = {};
  }

  formatDate(date, time) {
    return time + ' ' + date;
  }

  submitButtonClicked() {
    trackGa4Event('add_to_cart', this.ecommerceData)
  }

  @bound
  updateDateLabels(date, time, source) {
    switch (source) {
      case 'departure':
        this.departureDateLabelTarget.innerText = this.formatDate(date, time);
        break;
      case 'return':
        this.returnDateLabelTarget.innerText = this.formatDate(date, time);
        break;
    }
  }

  @bound
  updateInputs(date, time, source) {
    switch (source) {
      case 'departure':
        this.departureDateInputTarget.value = date;
        this.departureTimeInputTarget.value = time;
        break;
      case 'return':
        this.returnDateInputTarget.value = date;
        this.returnTimeInputTarget.value = time;
        break;
    }
  }

  updateSources(date, time, source) {
    this.sources[source] = { date: date, ...time };
  }

  updateTotal() {
    if (this.allSourcesSet()) {
      this.total = this.sourcesToPrice() * this.ticketQuantity;
      this.totalLabelTarget.innerText = this.currencyFormatter.format(
        this.total
      );
      this.submitButtonTarget.removeAttribute('disabled');
    } else {
      this.totalLabelTarget.innerText = '';
      this.submitButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  sourcesToPrice() {
    return Object.values(this.sources).reduce(
      (price, source) => price + Number(source.cost) + Number(source.fee),
      0
    );
  }

  allSourcesSet() {
    if (this.tripType === 'one_way_trip') {
      return !!this.sources.departure;
    } else {
      return !!this.sources.departure && !!this.sources.return;
    }
  }

  onSubmit() {
    this.element.classList.add('contents-loading');
  }

  onTripTypeChanged(e) {
    this.dimensions = {};
    this.resetInputsAndLabels();
    this.tripType = e.target.value;
  }

  resetInputsAndLabels() {
    this.totalLabelTarget.innerText = '';
    this.returnDateLabelTarget.innerText = this.initialDateLabel;
    this.departureDateLabelTarget.innerText = this.initialDateLabel;
    this.departureDateInputTarget.value = '';
    this.departureTimeInputTarget.value = '';
    this.returnDateInputTarget.value = '';
    this.returnTimeInputTarget.value = '';
    this.submitButtonTarget.setAttribute('disabled', 'disabled');
  }

  ticketQuantityChanged(e) {
    this.ticketQuantity = e.detail.value;
    this.updateTotal();
  }
}
