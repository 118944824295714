import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  get currentTab() {
    return this.data.get('current');
  }

  set currentTab(name) {
    this.hideCurrentTab();

    this.data.set('current', name);
    this.showTab(name);

    Rails.fire(this.element, 'howler:tabs.change', [name]);
  }

  change({ currentTarget }) {
    this.currentTab = currentTarget.dataset.tabTarget;
  }

  hideCurrentTab() {
    if (this.currentTab) {
      this.hideTab(this.currentTab);
    }
  }

  showTab(name) {
    const tab = this.getTab(name);
    const tabButton = this.getTabButton(name);

    [].forEach.call(tab, item => item.classList.remove('util-hidden'));
    tabButton.classList.add('is-active');
  }

  hideTab(name) {
    const tab = this.getTab(name);
    const tabButton = this.getTabButton(name);

    [].forEach.call(tab, item => item.classList.add('util-hidden'));
    tabButton.classList.remove('is-active');
  }

  getTab(name) {
    return this.element.querySelectorAll(`[data-tab="${name}"]`);
  }

  getTabButton(name) {
    return this.element.querySelector(`[data-tab-target="${name}"]`);
  }

  connect() {
    if (this.currentTab) {
      this.showTab(this.currentTab);
    }
  }
}
