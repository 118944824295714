import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

import fullScreenSpinner from 'javascript/utils/full-screen-spinner';

export default class extends ApplicationController {
  static targets = ['orderID', 'submit'];

  get amount() {
    return this.data.get('amount');
  }

  get formMethod() {
    return this.data.get('formMethod');
  }

  connect() {
    // wait for paypal script to finish runing before trying to make buttons
    setTimeout(() => {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'paypal',
        },
        createOrder: this.createOrder,
        onApprove: this.onApproveOrder,
        onCancel: this.onCancelOrder,
      }).render('#paypal-button-container');
    }, 500);
  }

  @bound
  createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: this.amount
        }
      }]
    });
  }

  @bound
  onApproveOrder(data) {
    fullScreenSpinner.show();
    this.orderIDTarget.value = data.orderID;
    this.submitTarget.click();
  }

  @bound
  onCancelOrder() {
    fullScreenSpinner.hide();
  }
}
