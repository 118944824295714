import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'bankAccountDetails',
    'countrySelected',
    'bankNameSelected',
    'bankBranchCodeSelected',
  ];

  addEmptyOption(selectDOMNode) {
    let opt = this.window.document.createElement("option");
    opt.value = '';
    opt.innerHTML = '';
    selectDOMNode.appendChild(opt);
  }

  onCountryChange() {
    const selectedCountryName = this.countrySelectedTarget.options[this.countrySelectedTarget.selectedIndex].text;
    this.bankNameSelectedTarget.innerHTML = "";
    let opt;

    if (selectedCountryName) {
      if (Object.keys(this.bankDetails[selectedCountryName]).length > 0) {
        this.addEmptyOption(this.bankNameSelectedTarget);
      }

      for (let key in this.bankDetails[selectedCountryName]) {
        opt = this.window.document.createElement("option");

        opt.value = key;
        opt.innerHTML = key;

        this.bankNameSelectedTarget.appendChild(opt);
      }
    }

    this.onBankNameChange();
  }

  onBankNameChange() {
    const selectedCountryName = this.countrySelectedTarget.options[this.countrySelectedTarget.selectedIndex].text;
    this.bankBranchCodeSelectedTarget.innerHTML = "";
    let opt;

    if (this.bankDetails[selectedCountryName]) {
      const selectedBankName = this.bankNameSelectedTarget.options[this.bankNameSelectedTarget.selectedIndex].text;
      const selectedBankNameBranches = this.bankDetails[selectedCountryName][selectedBankName];

      if (selectedBankName) {
        if (Object.keys(selectedBankNameBranches).length > 0) {
          this.addEmptyOption(this.bankBranchCodeSelectedTarget);
        }
      }

      for (let key in selectedBankNameBranches) {
        opt = this.window.document.createElement("option");

        opt.value = selectedBankNameBranches[key];
        opt.innerHTML = key;

        this.bankBranchCodeSelectedTarget.appendChild(opt);
      }
    }
  }

  connect() {
    this.bankDetails = JSON.parse(this.bankAccountDetailsTarget.innerHTML);
  }

}
