import PayAtController from "javascript/controllers/consumer_portal/pay_at_controller";

export default class extends PayAtController {
  static targets = ['container', 'input']

  validateInput(e) {
    let inputField
    if (e.target !== undefined) {
      inputField =  e.target
    } else {
      inputField = e
    }
    const errorField = inputField.parentNode.querySelector('.field__error');

    if (inputField.value.trim() === '') {
      errorField.classList.remove('hidden');
      return false
    } else {
      errorField.classList.add('hidden');
      return true
    }
  }

  before(event) {
    this.containerTarget.classList.add('contents-loading');

    let allInputsValid = true;
    this.inputTargets.forEach((inputTarget) => {
      if (!this.validateInput(inputTarget)) {
        allInputsValid = false;
      }
    });

    // Prevent form submission if any input is invalid
    if (!allInputsValid) {
      event.preventDefault();
      this.containerTarget.classList.remove('contents-loading');
    }
  }
}
