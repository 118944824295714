import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  connect() {
    if (this.element.getAttribute('href')) {
      window.setTimeout(() => {
        this.element.click();
      }, 0);
    }
  }
}
