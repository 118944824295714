import { trackGa4Event } from 'javascript/utils/utils';
import { storeObject, fetchObject } from 'javascript/utils/storage';
import { isPaymentPending } from 'javascript/utils/payment_status';
import ApplicationController from '../application_controller';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['refundOption'];

  connect() {
    const option = this.persistedOption();

    this.window.addEventListener('pageshow', () => {
      this.init();
    });

    if (option !== null) {
      setTimeout(() => this.setRefundOption(option), 0);
    } else {
      this.init();
    }
  }

  init() {
    const checkedOption = this.refundOptionTargets.find(
      (option) => option.checked === true,
    );

    if (this.isDisabled()) {
      this.disableOptionTargets();
    }

    if (checkedOption) {
      const refundProtectSelected = checkedOption.value === '1';
      setTimeout(() => {
        this.broadcastRefundOptionChangedEvent(refundProtectSelected);
      }, 0);
    }
  }

  isDisabled() {
    return this.disabled === 'true' || isPaymentPending(this.orderId);
  }

  get orderId() {
    return this.data.get('orderId');
  }

  get disabled() {
    return this.data.get('disabled');
  }

  persistOption(value) {
    const item = {
      orderId: this.orderId,
      value: value,
    };

    storeObject({
      type: 'session',
      key: 'refundProtectSelectedOption',
      object: item,
    });
  }

  persistedOption() {
    const storedObject = fetchObject({
      type: 'session',
      key: 'refundProtectSelectedOption',
    });

    if (
      this.persistOptions &&
      storedObject &&
      storedObject.orderId === this.orderId
    ) {
      return storedObject.value;
    } else {
      return null;
    }
  }

  get persistOptions() {
    return this.data.get('persistOptions') === 'true';
  }

  get fee() {
    return this.data.get('fee');
  }

  refundOptionClicked(e, persist = true) {
    const paymentOptionElement = e.target.closest('.payment-option');
    const radioInput = paymentOptionElement.querySelector('input.radio-input');
    if (radioInput) {
      radioInput.click();
    }

    if (this.isDisabled()) {
      this.disableOptionTargets();
    } else if (persist) {
      this.persistOption(radioInput.value);
    }
  }

  setRefundOption(value) {
    const refundOption = this.refundOptionTargets.find(
      (option) => option.value == value,
    );

    this.refundOptionClicked({ target: refundOption }, false);

    const refundProtectSelected = value === '1';
    this.broadcastRefundOptionChangedEvent(refundProtectSelected);
  }

  onRefundOptionChanged(e) {
    const refundProtectSelected = e.target.value === '1';
    const refundOption = refundProtectSelected ? 'comprehensive' : 'standard';

    if (this.disabled !== 'true') {
      this.broadcastRefundOptionChangedEvent(refundProtectSelected);
      trackGa4Event('select_refund_option', { refund_option: refundOption });
    }
  }

  disableOptionTargets() {
    this.refundOptionTargets.forEach((optionTarget) => {
      optionTarget.setAttribute('disabled', true);
    });
  }

  broadcastRefundOptionChangedEvent(refundProtectSelected) {
    const newValue = refundProtectSelected ? this.fee : 0;
    const oldValue = refundProtectSelected ? 0 : this.fee;
    Rails.fire(this.element, 'howler:cartUpdated', {
      newValue,
      oldValue,
      refundProtectSelected,
      source: 'refundProtect',
    });
  }
}
