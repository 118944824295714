import Modernizr from 'modernizr';

function initiateMediumSticky(elem) {
  return new Foundation.Sticky(elem, {
    stickTo: 'top',
    stickyOn: 'medium',
    marginTop: 0,
    anchor: 'page-sticky-anchor',
    container: elem.parent()
  });
}


$(document).on('turbolinks:load', () => {

  if (Modernizr.csspositionsticky) return;

  const mediumStickyBits = $('.js-sticky-fallback-medium');
  if (!mediumStickyBits.length) return;

  mediumStickyBits.each(function() {
    initiateMediumSticky($(this));
  });

  $(window).trigger('load.zf.sticky');

});
