import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  get svg() {
    if (this.element.constructor === SVGSVGElement) {
      return this.element;
    }
    return this.element.querySelector('svg');
  }

  connect() {
    const width = this.svg.clientWidth;
    const svgWidth = this.svg.width.baseVal.value;
    const aspect = svgWidth / width;
    this.svg.style.height = `${this.svg.height.baseVal.value / aspect}px`;
  }
}