import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  onKeyPress(e) {
    const input = e.target;
    const key = e.key;
    const pattern = input.dataset.pattern;

    const regexPattern = new RegExp(pattern);
    const result = regexPattern.test(key);

    if (!result) {
      e.preventDefault();
      return false;
    }

    return true;
  }
}
