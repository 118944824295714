import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'error'
  ];

  touch() {
    // TODO: find a non-jquery way to do this
    $(this.errorTarget).slideUp();
  }
}