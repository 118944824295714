import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {

  static targets = [
    'categoryItem',
    'selectionCount',
    'submitButton',
    'resetButton',
  ];

  @bound
  updateCategorySelectionCounters() {
    this.categoryItemTargets.forEach((categoryItem, categoryItemIndex) => {
      const totalCheckedItems = [].slice.call(categoryItem.querySelectorAll('input[type="checkbox"]')).reduce((total, checkbox) => {
        return checkbox.checked ? total + 1 : total;
      }, 0);
      this.selectionCountTargets[categoryItemIndex].innerHTML = totalCheckedItems != 0 ? totalCheckedItems : '';
    });
  }

  @bound
  onCategorySelectionChanged() {
    this.updateCategorySelectionCounters();
  }

  @bound
  onResetButtonClick() {
    this.element.querySelectorAll('input[type="radio"], input[type="checkbox"]').forEach((input) => {
      input.removeAttribute('checked');
    });
    this.updateCategorySelectionCounters();
    this.submitButtonTarget.click();
  }

  @bound
  onFormSubmit() {
    this.element.classList.add('is-submitting');
  }

  connect() {
    this.element.addEventListener('submit', this.onFormSubmit);
    this.resetButtonTargets.forEach((button) => {
      button.addEventListener('click', this.onResetButtonClick);
    });
    this.categoryItemTargets.forEach((categoryItem) => {
      categoryItem.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
        checkbox.addEventListener('change', this.onCategorySelectionChanged);
      });
    });
  }

  disconnect() {
    this.element.removeEventListener('submit', this.onFormSubmit);
    this.resetButtonTargets.forEach((button) => {
      button.removeEventListener('click', this.onResetButtonClick);
    });
  }

}
