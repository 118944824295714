import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'input',
    'thing'
  ];

  get inputValue() {
    switch (this.inputTarget.type) {
      case 'checkbox':
        return this.inputTarget.checked ? 'true' : 'false';
      case 'radio':
        const checkedItem = this.inputTargets.find((item) => {
          return item.checked;
        });
        return checkedItem ? checkedItem.value : '';
      default:
        return this.inputTarget.value;
    }
  }

  connect() {
    this.onInputChange();
  }

  onInputChange() {
    this.thingTargets.forEach(thing => {
      if (this.shouldShow(thing)) {
        this.showThing(thing);
      } else {
        this.hideThing(thing);
      }
    });
  }

  showThing(thing) {
    thing.classList.remove('util-hidden');

    if (this.data.get('unsetNestedFormFields') === 'true') {
      [].forEach.call(thing.querySelectorAll('[name]'), (formFieldDOMNode) => {
        switch (formFieldDOMNode.type) {
          case 'checkbox':
            formFieldDOMNode.checked = formFieldDOMNode.getAttribute('data-show-hide-value');
            formFieldDOMNode.removeAttribute('data-show-hide-value');
            break;
          case 'radio':
            formFieldDOMNode.checked = formFieldDOMNode.getAttribute('data-show-hide-value');
            formFieldDOMNode.removeAttribute('data-show-hide-value');
            break;
          default:
            formFieldDOMNode.value = formFieldDOMNode.getAttribute('data-show-hide-value');
            formFieldDOMNode.removeAttribute('data-show-hide-value');
        }
      });
    }

    if (this.data.get('disable') === 'true') {
      thing.removeAttribute('disabled');
    }
  }

  hideThing(thing) {
    thing.classList.add('util-hidden');

    if (this.data.get('unsetNestedFormFields') === 'true') {
      [].forEach.call(thing.querySelectorAll('[name]'), (formFieldDOMNode) => {
        switch (formFieldDOMNode.type) {
          case 'checkbox':
            formFieldDOMNode.setAttribute('data-show-hide-value', formFieldDOMNode.checked);
            formFieldDOMNode.checked = false;
            break;
          case 'radio':
            formFieldDOMNode.setAttribute('data-show-hide-value', formFieldDOMNode.checked);
            formFieldDOMNode.checked = false;
            break;
          default:
            formFieldDOMNode.setAttribute('data-show-hide-value', formFieldDOMNode.value);
            formFieldDOMNode.value = '';
        }
      });
    }

    if (this.data.get('disable') === 'true') {
      thing.setAttribute('disabled', 'disabled');
    }
  }

  shouldShow(thing) {
    if (thing.dataset.showValue !== undefined) {
      return thing.dataset.showValue === this.inputValue;
    }
    if (thing.dataset.hideValue !== undefined) {
      return thing.dataset.hideValue !== this.inputValue;
    }
  }
}
