import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

const FLASH_DURATION = 3500;
const SCROLL_AWAY_LENGTH = 100;

export default class extends ApplicationController {
  static targets = ['text'];

  get isOpen() {
    return this.data.get('open') == 'true' || false;
  }

  set isOpen(value) {
    this.data.set('open', value ? 'true' : 'false');
  }

  get isVisiblyOpen() {
    return this.element.style.display !== 'none';
  }

  connect() {
    this.closeTimeout = null;

    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-flash-open')) {
        this.openOrClose();
      }
    });

    this.observer.observe(this.element, { attributes: true });
    this.openOrClose();
  }

  disconnect() {
    this.observer.disconnect();
  }

  openOrClose() {
    if (this.isOpen) {
      this.animateOpen();
    } else {
      this.animateClosed();
    }
  }

  animateOpen() {
    this.element.style.display = 'flex';

    if (Foundation.MediaQuery.is('small only')) {
      this.initialScrollPosition = this.window.scrollY;
      this.window.addEventListener('scroll', this.onWindowScroll, false);
    }

    this.transformOpen();

    this.window.setTimeout(() => {
      this.transformClose();

      this.closeTimeout = this.window.setTimeout(() => {
        this.closeTimeout = null;
        this.close();
      }, FLASH_DURATION);
    }, 0);
  }

  animateClosed() {
    let transitionEndHandler = () => {
      this.element.style.display = 'none';
      this.element.removeEventListener("transitionend", transitionEndHandler, false);
    }

    if (this.closeTimeout !== null) {
      this.window.clearTimeout(this.closeTimeout);
    }

    if (this.isVisiblyOpen) {
      this.transformOpen();

      this.element.addEventListener('transitionend', transitionEndHandler);
      this.window.removeEventListener('scroll', this.onWindowScroll);
    }
  }

  close() {
    if (this.isOpen) {
      this.isOpen = false;
    }
  }

  @bound
  onWindowScroll() {
    if (Math.abs((this.initialScrollPosition - this.window.scrollY)) > SCROLL_AWAY_LENGTH) {
      this.close();
    }
  }

  transformOpenOrClose(shouldOpen) {
    const windowWidth = this.window.matchMedia("(max-width: 720px)");
    let transform = '';

    if (shouldOpen) {
      transform = windowWidth.matches ? 'translateY(100%)' : 'translateX(100%)';
    } else {
      transform = windowWidth.matches ? 'translateY(0px)' : 'translateX(0px)';
    }

    this.element.style.transform = transform;
  }

  transformOpen() {
    this.transformOpenOrClose(true);
  }

  transformClose() {
    this.transformOpenOrClose(false);
  }
}