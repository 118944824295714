import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["linkAnchor", "category", "linkName", "queryParam"];
  connect() {
    this.baseUrl = this.data.get("baseUrl");
    this.queryParamKey = this.data.get("queryParamKey");
    this.shouldSetCopyButtonValue = this.data.get("setCopyButtonValue") === "true";
    this.copyButtonSelector = this.data.get("copyButtonSelector") || ".clipboard-copy-button";
    this.copyButtonDataAttribute = this.data.get("copyButtonDataAttribute") || "data-clipboard-copy-button-value";
  }

  onCheckboxChanged(event) {
    const changedCheckbox = event.target;
    const changedCheckboxCategoryId = changedCheckbox.dataset.categoryId;
    this.setCategoryCheckboxState(changedCheckboxCategoryId);

    this.buildLink();
  }

  setCategoryCheckboxState(categoryId) {
    const categoryCheckbox = this.categoryTargets
      .find(target => target.dataset.categoryId === categoryId);

    if (this.entireCategorySelected(categoryId)) {
      categoryCheckbox.checked = true;
      categoryCheckbox.indeterminate = false;
    }
    else if (this.entireCategoryDeselected(categoryId)) {
      categoryCheckbox.checked = false;
      categoryCheckbox.indeterminate = false;
    }
    else {
      categoryCheckbox.indeterminate = true;
    }
  }

  entireCategorySelected(categoryId) {
    return this.categoryCheckboxes(categoryId).every(checkbox => checkbox.checked);
  }
  entireCategoryDeselected(categoryId) {
    return !this.categoryCheckboxes(categoryId).some(checkbox => checkbox.checked);
  }

  categoryCheckboxes(categoryId) {
    return this.queryParamTargets
      .filter(target => target.dataset.categoryId === categoryId);
  }

  onCategoryCheckboxChanged(event) {
    const categoryId = event.target.dataset.categoryId;
    const categoryChecked = event.target.checked;

    this.queryParamTargets
      .filter(target => target.dataset.categoryId === categoryId)
      .forEach(target => target.checked = categoryChecked);

    this.buildLink();
  }

  buildQueryParamArray() {
    const params = [];
    this.queryParamTargets.forEach(target => {
      if (target.checked) {
        params.push(target.dataset.id);
      }
    });

    return params;
  }

  setCopyButtonLink(link) {
    this.element.querySelector(this.copyButtonSelector).setAttribute(this.copyButtonDataAttribute, link);
  }

  buildLink() {
    const url = new URL(this.baseUrl);

    this.buildQueryParamArray().forEach(paramValue => {
      url.searchParams.append(this.queryParamKey, paramValue);
    });

    this.linkAnchorTarget.href = url.href;

    if (this.shouldSetCopyButtonValue) {
      this.setCopyButtonLink(url.href);
    }
  }
}
