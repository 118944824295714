import ApplicationController from 'javascript/controllers/application_controller';

import { loginWithFacebook, loginWithGoogle } from 'javascript/utils/oauth';
import { flashNotice } from 'javascript/utils/flash-messages';

export default class extends ApplicationController {
  static targets = [
    'facebookRemoval',
    'facebookLinking',
    'googleRemoval',
    'googleLinking'
  ];

  facebook() {
    loginWithFacebook((_, message) => {
      flashNotice(message);
      this.facebookRemovalTarget.classList.remove('util-hidden');
      this.facebookLinkingTarget.classList.add('util-hidden');
    });
  }

  google() {
    loginWithGoogle((_, message) => {
      flashNotice(message);
      this.googleRemovalTarget.classList.remove('util-hidden');
      this.googleLinkingTarget.classList.add('util-hidden');
    });
  }
}