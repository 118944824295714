import ApplicationController from 'javascript/controllers/application_controller';
import bound from 'bound-decorator';
export default class extends ApplicationController {
  static targets = [
    'input',
    'accountSelect',
    'form',
    'recipientType',
    'accountSelectContainer',
    'countryCityContainer'
  ];

  @bound
  search(query, callback) {
    const urlSafeQuery = encodeURI(query);
    this.apiRequest(`/managed_accounts/profiles/${urlSafeQuery}`, (data) =>
      callback(data)
    );
  }

  connect() {
    this.showHideForm();
  }

  onRecipientTypeChanged(e) {
    this.showHideForm();
    if (this.checkedRecipientType === 'existing_recipient' && this.accountSelectTarget.value !== '') {
      const event = new Event('change', { bubbles: true });
      this.accountSelectTarget.dispatchEvent(event);
    }
  }

  showForm() {
    this.formTarget.classList.remove('hidden');
  }

  hideForm() {
    this.formTarget.classList.add('hidden');
  }

  showHideForm() {
    if (this.checkedRecipientType === 'new_recipient') {
      this.clearFormFields();
      this.accountSelectTarget.removeAttribute('required', '');
      this.accountSelectContainerTarget.classList.add('hidden');
      this.showForm();
    } else {
      if (this.accountSelectTarget.value === '') {
        this.hideForm();
      }
      else {
        this.showForm();
      }
      this.accountSelectTarget.setAttribute('required', '');
      this.accountSelectContainerTarget.classList.remove('hidden');
    }
  }

  get checkedRecipientType() {
    const checkedType = this.recipientTypeTargets.find(
      (target) => target.checked
    );

    return checkedType.value;
  }

  onManagedAccountSelected() {
    if (this.checkedRecipientType === 'new_recipient') { return; }

    const selectedUserId = this.accountSelectTarget.value;

    this.search(selectedUserId, this.setFormValues);
    this.showHideForm();
  }

  @bound
  setFormValues(userInfo) {
    this.clearFormFields();

    this.inputTargets.forEach((input) => {
      const inputField = input.dataset.field;
      const inputValue = userInfo[inputField];

      if (inputValue !== undefined && inputValue !== null) {
        input.value = inputValue;
      }
    });

    this.setDateOfBirthFields(userInfo.date_of_birth);
    this.setCitizenship();
    this.setCountryCitySelect();
  }

  clearFormFields() {
    this.inputTargets.forEach((input) => (input.value = input.dataset.default || ''));

    this.clearFormDateFields();
    this.setCitizenship();
  }

  clearFormDateFields() {
    if (this.hasDateOfBirth()) {
      const dateField = this.inputTargets.find(
        (input) => input.dataset.field === 'date_of_birth'
      );

      const yearInputSelector = dateField.dataset.yearField;
      const monthInputSelector = dateField.dataset.monthField;
      const dayInputSelector = dateField.dataset.dayField;
      
      this.element.querySelector(yearInputSelector).value = '1990';
      this.element.querySelector(monthInputSelector).value = 1;
      this.element.querySelector(dayInputSelector).value = 1;
    }
  }

  setCountryCitySelect() {
    if (!this.hasCountryCityContainerTarget) {
      return;
    }
    const countryCityController = this.application.getControllerForElementAndIdentifier(this.countryCityContainerTarget, 'country-city-select');

    if (!countryCityController) {
      return;
    }

    countryCityController.onCountrySelected(false);
  }

  setDateOfBirthFields(dateOfBirth) {
    if (!this.hasDateOfBirth() || !dateOfBirth) {
      return;
    }

    const dateParts = dateOfBirth.split('-');

    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const day = parseInt(dateParts[2]);

    const dateField = this.inputTargets.find(
      (input) => input.dataset.field === 'date_of_birth'
    );

    const yearInputSelector = dateField.dataset.yearField;
    const monthInputSelector = dateField.dataset.monthField;
    const dayInputSelector = dateField.dataset.dayField;

    this.element.querySelector(yearInputSelector).value = year;
    this.element.querySelector(monthInputSelector).value = month;
    this.element.querySelector(dayInputSelector).value = day;
  }

  setCitizenship() {
    const isCitizen = this.inputTargets.find(
      (input) => input.dataset.field === 'is_rsa_citizen'
    );

    if (isCitizen) {
      const event = new Event('change', { bubbles: true });
      isCitizen.dispatchEvent(event);
    }
  }

  apiRequest(url, callback) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => callback(data));

  }

  hasDateOfBirth() {
    return this.inputTargets.some((input) => (input.dataset.field === 'date_of_birth'));
  }
}
