import ApplicationController from 'javascript/controllers/application_controller';
import { trackGa4Event } from 'javascript/utils/utils';
export default class extends ApplicationController {
  connect() {
    if (this.fireOnConnect) {
      const eventName = this.element.dataset.eventName;
      const eventParams = JSON.parse(this.element.dataset.eventParams);
      trackGa4Event(eventName, eventParams);
    }
  }

  get fireOnConnect() {
    return this.data.get('fireOnConnect') === 'true';
  }

  sendEvent(event) {
    const element = event.target.closest('[data-event-name]');
    const eventName = element.dataset.eventName;
    const eventParams = JSON.parse(element.dataset.eventParams);

    trackGa4Event(eventName, eventParams);
  }
}

