import ApplicationController from "javascript/controllers/application_controller";
import replaceContent from "javascript/utils/replace_content";
import { trackPageView } from 'javascript/utils/utils';

function isJavascript(xhr) {
  return getContentType(xhr).indexOf('javascript') !== -1;
}

function getContentType(xhr) {
  return xhr.getResponseHeader('Content-Type');
}

export default class extends ApplicationController {
  static targets = [
    'fullModalSpinner',
  ];

  connect() {
    this.scrollToTop();
  }

  scrollToTop() {
    const modalInstance = this.application.getControllerForElementAndIdentifier(document.querySelector('.modal'), 'modal');
    if (modalInstance) {
      modalInstance.scrollToTop();
    }
  }

  setLoading() {
    this.element.classList.add('contents-loading');
  }

  setCurrentButtonLoading(e) {
    e.target.classList.add('contents-loading');
  }

  showLoadingOverlay() {
    this.fullModalSpinnerTarget.classList.remove('util-hidden');
  }

  hideLoadingOverlay() {
    this.fullModalSpinnerTarget.classList.add('util-hidden');
  }

  replaceBody({ detail: [response, , xhr] }) {
    if (!isJavascript(xhr)) {
      trackPageView(xhr);
      replaceContent(this.element.parentElement, response.body.childNodes, true);
    }
  }
}
