import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  get loaderElement() {
    return document.getElementById('full-screen-loader');
  }

  get htmlElement() {
    return document.firstElementChild;
  }

  show() {
    this.loaderElement.classList.add('is-open');
  }

  hide() {
    this.loaderElement.classList.remove('is-open');
  }
}