import ApplicationController from "javascript/controllers/application_controller";
import confetti from 'canvas-confetti';

export default class extends ApplicationController {
  connect() {
    this.confetti = this.initConfetti();

    if (this.fireOnConnect) {
      this.fireConfetti();
    }
  }

  get defaultConfettiOptions() {
    return [
      {
        angle: 135,
        spread: 95,
        particleCount: 100,
        ticks: 300,
        gravity: 0.7,
        origin: { y: 0.6 }
      },
      {
        angle: 90,
        spread: 95,
        particleCount: 100,
        ticks: 300,
        gravity: 0.7,
        origin: { y: 0.6 }
      },
      {
        angle: 45,
        spread: 95,
        particleCount: 100,
        ticks: 300,
        gravity: 0.7,
        origin: { y: 0.6 }
      }
    ];
  }

  get confettiOptions() {
    if(this.data.has('confettiOptions')) {
      return JSON.parse(this.data.get('confettiOptions'))
    }

    return this.defaultConfettiOptions;
  }

  fireConfetti() {
    setTimeout(() => {
      this.confettiOptions.forEach(option => {
        this.confetti({ disableForReducedMotion: true, ...option });
      });
    }, this.delay);
  }

  confettiCanvas() {
    let canvas = document.querySelector('canvas#confetti');
    if (!canvas) {
      canvas = document.createElement('canvas');
      canvas.id = 'confetti';
      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.style['pointer-events'] = 'none';
      document.body.appendChild(canvas);
    }

    return canvas;
  }

  initConfetti() {
    const canvas = this.confettiCanvas();

    return confetti.create(canvas, {
      useWorker: true,
      resize: true,
    });
  }

  get delay() {
    const delayValue = this.data.get('delay') || '0';
    return parseInt(delayValue);
  }

  get fireOnConnect() {
    return this.data.get('fireOnConnect') === 'true';
  }
}
