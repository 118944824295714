import ApplicationController from 'javascript/controllers/application_controller';
import bound from 'bound-decorator';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'friendForm',
    'friendSection',
    'friendSelectionSection',
    'formSection',
    'card',
    'formSubmitButton',
    'meQuestionsSection',
    'friendQuestionsSection',
  ];

  @bound
  connect() {
    document.addEventListener('popupItemClicked', this.assignmentTypeChanged);
    this.element.addEventListener('friendSelectionChanged', this.friendSelectionChanged);
    this.element.parentElement.addEventListener(
      'howler:topup-selection.change',
      this.topupSelected
    );
    if (this.unassigned) {
      this.disableInputsInside(this.element);
    }
  }

  @bound
  disconnect() {
    document.removeEventListener('popupItemClicked', this.assignmentTypeChanged);
    this.element.removeEventListener(
      'friendSelectionChanged',
      this.friendSelectionChanged
    );
  }

  get assignedToMe() {
    return this.currentAssignmentType === 'me';
  }

  get assignedToFriend() {
    return this.currentAssignmentType === 'friend';
  }
  get unassigned() {
    return this.currentAssignmentType === 'unassigned';
  }

  get currentAssignmentType() {
    return this.data.get('currentAssignmentType');
  }

  get id() {
    return this.data.get('id');
  }

  get unansweredFriendQuestions() {
    return this.data.get('unansweredFriendQuestions') === 'true';
  }

  get unansweredMeQuestions() {
    return this.data.get('unansweredMeQuestions') === 'true';
  }

  set currentAssignmentType(assignmentType) {
    this.data.set('currentAssignmentType', assignmentType);
  }

  @bound
  assignmentTypeChanged(e) {
    const { assignmentType, id, itemIndex } = e.detail.event.target.closest('a').dataset;

    if (this.id !== id) {
      return;
    }

    this.handleAssignmentChange(assignmentType, itemIndex);
  }

  cancelAssignment(e) {
    e.preventDefault();
    this.currentAssignmentType = this.oldAssignmentType;

    this.cardTarget.classList.remove('hidden');
    this.formSectionTarget.classList.add('hidden');

    Rails.fire(this.element, 'unlockAssignments');
  }

  @bound
  friendSelectionChanged(e) {
    const { action, friend } = e.detail;

    if (action === 'selected') {
      this.friendFormTarget.classList.add('hidden');
      this.setFriendform(friend);
    } else {
      this.clearFriendForm();
      this.friendFormTarget.classList.remove('hidden');
    }
  }

  setFriendform(friend) {
    friend.email_confirmation = friend.email;

    this.friendFormTarget.querySelectorAll('input').forEach((input) => {
      for (const attribute in friend) {
        const inputPropertyName = input.name.split(/\[(.*?)\]/)[1];

        if (attribute === inputPropertyName) {
          input.value = friend[attribute];
        }
      }
    });
  }

  clearFriendForm() {
    this.setFriendform({
      first_name: '',
      last_name: '',
      email: '',
      email_confirmation: '',
    });
  }

  disableInputsInside(element) {
    element.querySelectorAll('input').forEach((input) => (input.disabled = true));
  }

  enableInputsInside(element) {
    element
      .querySelectorAll('input')
      .forEach((input) => input.removeAttribute('disabled'));

    element
      .querySelectorAll('select')
      .forEach((select) => select.removeAttribute('disabled'));
  }

  clearInputsInside(element, selector) {
    element.querySelectorAll(selector).forEach((input) => (input.value = ''));
  }

  enableErrorFieldsInside(element) {
    element
      .querySelectorAll('.field__error')
      .forEach((field) => (field.dataset.enabled = 'true'));
  }

  disableErrorFieldsInside(element) {
    element
      .querySelectorAll('.field__error')
      .forEach((field) => field.removeAttribute('enabled'));
  }

  toggleQuestionsFor(type, state) {
    const operation = state === 'hide' ? 'add' : 'remove';
    let element = null;
    if (type === 'me') {
      if (this.hasMeQuestionsSectionTarget) {
        element = this.meQuestionsSectionTarget;
      } else if (this.hasFriendQestionsSectionTarget) {
        element = this.friendQuestionsSectionTarget;
      }
    }

    if (element) {
      element.classList[operation]('hidden');
    }
  }

  @bound
  topupSelected(e) {
    const { itemIndex } = e.detail;

    document.querySelector('#ticket_order_item_item_index').value = itemIndex;
    document.querySelector('#ticket_order_item_form_type').value =
      this.currentAssignmentType;

    if (this.currentAssignmentType == 'friend') {
      this.enableInputsInside(this.friendSectionTarget);
      if (this.hasFriendQuestionsSectionTarget) {
        this.enableInputsInside(this.friendQuestionsSectionTarget);
      }
    } else if (this.currentAssignmentType == 'me') {
      if (this.hasMeQuestionsSectionTarget) {
        this.enableInputsInside(this.meQuestionsSectionTarget);
      }
    }

    this.formSubmitButtonTarget.click();
  }

  @bound
  handleAssignmentChange(newAssignmentType, itemIndex) {
    this.oldAssignmentType = this.currentAssignmentType;

    // TODO: Pass this down
    document.querySelector('#ticket_order_item_item_index').value = itemIndex;
    document.querySelector('#ticket_order_item_form_type').value = newAssignmentType;
    document
      .querySelectorAll('input[name="ticket_order_item[topup]"]')
      .forEach((input) => input.setAttribute('disabled', 'disabled'));
    document
      .querySelectorAll('[data-group="data_capture"]')
      .forEach((input) => input.setAttribute('disabled', 'disabled'));

    switch (newAssignmentType) {
      case 'decide_later':
        if (this.unansweredFriendQuestions) {
          this.cardTarget.classList.add('hidden');
          this.friendSectionTarget.classList.remove('hidden');
          this.friendSelectionSectionTarget.classList.add('hidden');
          this.friendFormTarget.classList.add('hidden');
          this.formSectionTarget.classList.remove('hidden');
          this.toggleQuestionsFor('me', 'hide');
          this.disableInputsInside(this.formSectionTarget);
          this.disableErrorFieldsInside(this.formSectionTarget);
          this.enableInputsInside(this.friendSectionTarget);
          this.enableErrorFieldsInside(this.friendSectionTarget);
          Rails.fire(this.element, 'lockAssignments', { activeAssignmentId: this.id });
        } else {
          this.formSubmitButtonTarget.click();
        }
        break;
      case 'me':
        if (this.unansweredMeQuestions) {
          this.toggleQuestionsFor('me', 'show');
          this.friendSectionTarget.classList.add('hidden');
          this.enableInputsInside(this.formSectionTarget);
          if (this.hasMeQuestionsSectionTarget) {
            this.enableInputsInside(this.meQuestionsSectionTarget);
          }
          this.enableErrorFieldsInside(this.formSectionTarget);
          this.disableInputsInside(this.friendSectionTarget);
          this.cardTarget.classList.add('hidden');
          this.formSectionTarget.classList.remove('hidden');

          Rails.fire(this.element, 'lockAssignments', { activeAssignmentId: this.id });
        } else {
          this.formSubmitButtonTarget.click();
        }
        break;
      case 'friend':
        this.cardTarget.classList.add('hidden');
        this.friendSectionTarget.classList.remove('hidden');
        this.friendSelectionSectionTarget.classList.remove('hidden');
        this.friendFormTarget.classList.remove('hidden');
        this.formSectionTarget.classList.remove('hidden');
        this.toggleQuestionsFor('me', 'hide');
        this.disableInputsInside(this.formSectionTarget);
        this.disableErrorFieldsInside(this.formSectionTarget);
        this.enableInputsInside(this.friendSectionTarget);
        if (this.hasFriendQuestionsSectionTarget) {
          this.enableInputsInside(this.friendQuestionsSectionTarget);
        }
        this.clearInputsInside(this.friendSectionTarget, '[data-group="friend_assign]');
        this.enableErrorFieldsInside(this.friendSectionTarget);

        Rails.fire(this.element, 'lockAssignments', { activeAssignmentId: this.id });
        break;
    }
  }
}

