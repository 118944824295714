import { storeObject, fetchObject } from 'javascript/utils/storage';

export function setPaymentPending(
  orderId,
  expiresAt,
  paymentMethod,
  isPaymentFeeChargeable,
) {
  const item = {
    orderId,
    expiresAt: new Date(expiresAt).toISOString(),
    value: 'true',
    paymentMethod,
    isPaymentFeeChargeable,
  };

  storeObject({
    type: 'local',
    key: 'paymentPending',
    object: item,
  });
}

export function pendingPaymentMethod(orderId) {
  const storedObject = fetchObject({
    type: 'local',
    key: 'paymentPending',
  });

  if (
    storedObject &&
    storedObject.orderId === orderId &&
    storedObject.value === 'true'
  ) {
    return storedObject.paymentMethod;
  }
}

export function isPendingPaymentFeeChargeable(orderId) {
  const storedObject = fetchObject({
    type: 'local',
    key: 'paymentPending',
  });

  if (
    storedObject &&
    storedObject.orderId === orderId &&
    storedObject.value === 'true'
  ) {
    return storedObject.isPaymentFeeChargeable === 'true';
  }

  return false;
}

export function isPaymentPending(orderId) {
  const storedObject = fetchObject({
    type: 'local',
    key: 'paymentPending',
  });

  if (storedObject && storedObject.orderId === orderId) {
    return storedObject.value === 'true';
  }

  return false;
}

export function anyPaymentPending() {
  const storedObject = fetchObject({
    type: 'local',
    key: 'paymentPending',
  });

  return (
    storedObject &&
    storedObject.expiresAt >= new Date().toISOString() &&
    storedObject.value === 'true'
  );
}
