import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['container', 'loadMore'];

  beforeSubmit() {
    this.element.classList.add('contents-loading');
  }

  onError() {
    this.element.classList.remove('contents-loading');
    alert('something went wrong');
  }

  onSuccess({ detail: [response, , xhr] }) {
    this.element.classList.remove('contents-loading');
    const items = [].slice.call(response.body.childNodes, 0);

    items.forEach((domNode) => {
      this.containerTarget.appendChild(domNode);
    });

    let nextPagePath = xhr.getResponseHeader('x-next-page-path');
    if (nextPagePath) {
      this.loadMoreTarget.href = nextPagePath;
    } else {
      this.loadMoreTarget.classList.add('util-hidden');
    }
  }
}
