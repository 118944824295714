import ApplicationController from "javascript/controllers/application_controller";
import { getCookie, setCookie } from 'javascript/utils/cookies';
import { trackEvent } from 'javascript/utils/utils';

export default class extends ApplicationController {
  static targets = [
    'headerImage',
    'overlay',
    'headerStream',
    'previewHTML',
    'previewEndHTML'
  ];

  get previewAndPayExpiryTimeInSeconds() {
    return this.data.get("expiryTimeInSeconds");
  }

  get previewAndPayEventSlug() {
    return this.data.get("eventSlug");
  }

  connect() {
    this.previewCookieName = "stream_preview_viewed_" + this.previewAndPayEventSlug;

    if (getCookie(this.previewCookieName) == "true") {
      this.showPreviewEndedHTML();
      this.removeIframeDOMElement();
    }
  }

  beginPreview() {
    this.startTimer();
  }

  startTimer() {
    const expiryTimeInMilliseconds = this.previewAndPayExpiryTimeInSeconds * 1000
    const sevenDayCookieExpiryInMilliseconds = 604800000

    if (getCookie(this.previewCookieName) == "true") {
      this.previewHTMLTarget.classList.add('hide');
      this.previewEndHTMLTarget.classList.remove('hide');
    } else {
      this.showStreamPreviewHTML();

      setTimeout(() => {
        this.showPreviewEndedHTML();
        this.removeIframeDOMElement();

        setCookie(this.previewCookieName, "true", new Date(Date.now() + sevenDayCookieExpiryInMilliseconds))
      }, expiryTimeInMilliseconds)
    }
  }

  showStreamPreviewHTML() {
    this.overlayTarget.classList.add('hide');
    this.headerStreamTarget.classList.remove('hide');
    trackEvent('Preview & Pay', 'Click preview');
  }

  showPreviewEndedHTML() {
    this.overlayTarget.classList.add('hide');
    this.previewHTMLTarget.classList.add('hide');
    this.headerStreamTarget.classList.add('hide');
    this.previewEndHTMLTarget.classList.remove('hide');
  }

  removeIframeDOMElement() {
    this.headerStreamTarget.querySelector("iframe").remove();
  }
}
