import PayAtController from "javascript/controllers/consumer_portal/pay_at_controller";

export default class extends PayAtController {
  static targets = ['numberFieldError'];

  before(event) {
    if (!document.querySelector('[data-target="phone-input.hiddenInput"]').value) {
      event.preventDefault();
      this.numberFieldErrorTarget.classList.remove('hidden')
    } else {
      this.numberFieldErrorTarget.classList.add('hidden')
      super.before();
    }
  }
}
