import ApplicationController from 'javascript/controllers/application_controller';
export default class extends ApplicationController {
  static targets = ['teamOption', 'selectedOption', 'subscriber'];

  connect() {
    this.optionClass = this.data.get('optionClass');

    this.teamOptionTargets.forEach(option => {
      if (option.dataset.disabled === 'true') {
        option.classList.add(`${this.optionClass}--disabled`);
        option.querySelector('input.radio-input').disabled = true;
      }
    });
  }

  teamOptionChanged(event) {
    const element = event.currentTarget;
    this.updateButtons(element);
    this.selectedOptionTarget.value = element.value;

    if (this.hasSubscriberTarget) {
      this.subscriberTarget.dispatchEvent(new CustomEvent("teamOptionChanged", { detail: { selectedOption: element.value } }));
    }
  }

  teamOptionClicked(event) {
    const element = event.currentTarget;
    const radioInput = element.querySelector('input.radio-input');
    radioInput.click();
  }

  updateButtons(selectedRadioButton) {
    const selectedOptionClass = `${this.optionClass}--selected`;

    this.teamOptionTargets.forEach(option => option.classList.remove(selectedOptionClass));
    selectedRadioButton.parentElement.classList.add(selectedOptionClass);
  }
}
