import QuestionController from './question_controller';

export default class extends QuestionController {
  get value() {
    return this.inputTarget.checked ? '1' : '0';
  }

  clear() {
    this.inputTarget.checked = false;
  }
}