import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['tip'];

  connect() {
    this.showing = false;
  }

  disconnect() {
    window.removeEventListener('touchstart', this.hide);
  }

  show(e) {
    if (this.showing) return;
    this.tipTarget.style.display = 'block';
    setTimeout(() => {
      window.addEventListener('touchstart', this.hide, false);
    }, 0);
    this.showing = true;
  }

  @bound
  hide(e) {
    if (!this.showing) return;
    this.tipTarget.style.display = 'none';
    window.removeEventListener('touchstart', this.hide);
    this.showing = false;
  }
}