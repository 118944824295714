import QuestionController from './question_controller';

export default class extends QuestionController {
  static targets = [
    ...QuestionController.targets,
    'output'
  ];

  get value() {
    return this.outputTarget.value;
  }

  clear() {
    
  }
}