import Rails from '@rails/ujs';

window.visacheckoutOnReadyCallbacks = [];
window.visaCheckoutIsReady = false;

window.onVisaCheckoutReady = function onVisaCheckoutReady() {
  $(document.body).trigger('vco:ready');

  window.visacheckoutOnReadyCallbacks.forEach(cb => cb());
  window.visacheckoutOnReadyCallbacks = [];
  window.visaCheckoutIsReady = true;

  V.on("payment.success", function (payment) {
    Rails.fire(document.body, 'vco:payment.success', [payment])
  });
  V.on("payment.cancel", function (payment) {
    Rails.fire(document.body, 'vco:payment.cancel', [payment]);
  });
  V.on("payment.error", function (payment, error) {
    Rails.fire(document.body, 'vco:payment.error', [payment, error]);
  });
}

export function performOnVisaCheckoutReady(cb) {
  if (window.visaCheckoutIsReady) {
    cb();
  } else {
    window.visacheckoutOnReadyCallbacks.push(cb);
  }
}