import ApplicationController from "javascript/controllers/application_controller";
import Turbolinks from 'turbolinks';

export default class extends ApplicationController {
  showRepSales({ target: { value } }) {
    let urlParams = new URLSearchParams(this.window.location.search);
    let eventSlugString = urlParams.get('event_slug');

    if (urlParams.has('event_slug')) {
      Turbolinks.visit(`?event_slug=${eventSlugString}&rep_id=${value}`);
    } else {
      Turbolinks.visit(`?rep_id=${value}`);
    }
  };
}