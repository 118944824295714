import ApplicationController from 'javascript/controllers/application_controller';

import { formatNumberToCurrency } from './../../utils/utils';

export default class extends ApplicationController {
  static targets = ['newAmount', 'totalAmount', 'output'];

  get initialAmount() {
    return parseFloat(this.data.get('initialAmount') || '0');
  }

  get deposit() {
    return parseFloat(this.data.get('deposit') || '0');
  }

  get topupAmount() {
    return parseFloat(this.outputTarget.value || '0');
  }

  set topupAmount(amount) {
    this.outputTarget.value = amount;
  }

  get newAmount() {
    return this.initialAmount + this.topupAmount;
  }

  get totalAmount() {
    return this.topupAmount + this.deposit;
  }

  get currencySymbol() {
    return this.data.get('currencySymbol');
  }

  connect() {
    this.update();
  }

  onTopupSelectionChange({ detail: [value] }) {
    this.topupAmount = value;
    this.update();
  }

  update() {
    this.newAmountTarget.textContent = formatNumberToCurrency(this.newAmount, this.currencySymbol);
    this.totalAmountTarget.textContent = formatNumberToCurrency(this.totalAmount, this.currencySymbol);
  }
}
