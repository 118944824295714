import ApplicationController from "javascript/controllers/application_controller";
import { variableReplace } from 'javascript/utils/utils';
export default class extends ApplicationController {
  static targets = ["blob", "template"];

  connect() {
    let speakerList = [];

    try {
      speakerList = JSON.parse(this.blobTarget.innerHTML);
    } catch (error) {
      console.error(error);
    }

    if (!Array.isArray(speakerList)) {
      console.log("Json is not an array");
      return;
    }

    speakerList.forEach((speaker) => {
      speaker.id = this.generateElementId();
      const templateInstance = this.templateTarget.content.cloneNode(true);
      this.element.appendChild(templateInstance);
      const templateInstanceNode = this.element.childNodes[this.element.childNodes.length - 1];

      templateInstanceNode.innerHTML = variableReplace(templateInstanceNode.innerHTML, speaker);
    });
  }

  generateElementId(length = 8) {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
