import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = [
    'scroller',
  ];

  @bound
  updateClassName() {
    if (this.scrollerTarget.scrollWidth > this.scrollerTarget.offsetWidth) {
      this.element.classList.add('overflow-status--x-enabled');
    } else {
      this.element.classList.remove('overflow-status--x-enabled');
    }
    if (this.scrollerTarget.scrollLeft + this.scrollerTarget.offsetWidth >= this.scrollerTarget.scrollWidth - this.xEndPadding) {
      this.element.classList.add('overflow-status--reached-x-end');
    } else {
      this.element.classList.remove('overflow-status--reached-x-end');
    }
    if (this.scrollerTarget.scrollLeft <= this.xStartPadding) {
      this.element.classList.add('overflow-status--reached-x-start');
    } else {
      this.element.classList.remove('overflow-status--reached-x-start');
    }

    if (this.scrollerTarget.scrollHeight > this.scrollerTarget.offsetHeight) {
      this.element.classList.add('overflow-status--y-enabled');
    } else {
      this.element.classList.remove('overflow-status--y-enabled');
    }
    if (this.scrollerTarget.scrollTop + this.scrollerTarget.offsetHeight >= this.scrollerTarget.scrollHeight - this.yEndPadding) {
      this.element.classList.add('overflow-status--reached-y-end');
    } else {
      this.element.classList.remove('overflow-status--reached-y-end');
    }
    if (this.scrollerTarget.scrollTop <= this.yStartPadding) {
      this.element.classList.add('overflow-status--reached-y-start');
    } else {
      this.element.classList.remove('overflow-status--reached-y-start');
    }
  }

  connect() {
    this.xStartPadding = 0;
    this.xEndPadding = 0;
    this.yStartPadding = 0;
    this.yEndPadding = 0;

    if (this.data.has('xStartPadding')) {
      this.xStartPadding = parseInt(this.data.get('xStartPadding'), 10);
    }
    if (this.data.has('xEndPadding')) {
      this.xEndPadding = parseInt(this.data.get('xEndPadding'), 10);
    }

    if (this.data.has('yStartPadding')) {
      this.yStartPadding = parseInt(this.data.get('yStartPadding'), 10);
    }
    if (this.data.has('yEndPadding')) {
      this.yEndPadding = parseInt(this.data.get('yEndPadding'), 10);
    }

    this.window.addEventListener('load', this.updateClassName);
    this.window.addEventListener('resize', this.updateClassName);
    this.scrollerTarget.addEventListener('scroll', this.updateClassName);

    this.updateClassName();
  }

  disconnect() {
    this.window.removeEventListener('load', this.updateClassName);
    this.window.removeEventListener('resize', this.updateClassName);
    this.scrollerTarget.removeEventListener('scroll', this.updateClassName);
  }
}
