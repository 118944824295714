import ApplicationController from 'javascript/controllers/application_controller';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'input',
    'error'
  ];

  get value() {
    return this.inputTarget.value;
  }

  get id() {
    return parseInt(this.element.dataset.questionInstanceId, 10);
  }

  get error() {
    return this.element.dataset.questionError;
  }

  get isHidden() {
    return this.element.dataset.questionHidden === 'true' || false;
  }

  connect() {
    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-question-error')) {
        this.onChangeError();
      }
      if (mutationList.find(x => x.attributeName === 'data-question-hidden')) {
        this.onChangeHidden();
      }
    });

    this.observer.observe(this.element, { attributes: true });

    setTimeout(() => {
      this.change();
    }, 0);
  }

  disconnect() {
    this.observer.disconnect();
  }

  onChangeError() {
    if (this.error) {
      this.setError();
    } else {
      this.clearError();
    }
  }

  onChangeHidden() {
    if (this.isHidden) {
      this.element.classList.add('question-container--hidden');
      $(this.element).slideUp();
    } else {
      this.element.classList.remove('question-container--hidden');
      $(this.element).slideDown();
    }
    this.change();
  }

  change() {
    const eventValue = this.isHidden ? null : this.value;
    Rails.fire(this.element, 'howler:data-capture--question.change', [eventValue, this.id]);
  }

  beforeSubmit() {
    if (this.isHidden) {
      this.clear();
    }
  }

  clear() {
    [].forEach.call(this.inputTargets, t => {
      t.value = '';
    });
  }

  setError() {
    this.element.classList.add('has-error');
    [].forEach.call(this.inputTargets, t => {
      t.classList.add('has-error');
    });
    this.errorTarget.textContent = this.error;
    $(this.errorTarget).slideDown('fast');
  }

  clearError() {
    this.element.classList.remove('has-error');
    [].forEach.call(this.inputTargets, t => {
      t.classList.remove('has-error');
    });
    $(this.errorTarget).slideUp('fast');
  }
}
