import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['days', 'hours', 'minutes', 'seconds'];

  connect() {
    this.stopTime = this.toJSDateString(this.element.dataset.stopTime);
    this.updateTimeLeft();
    this.interval = setInterval(() => this.updateTimeLeft(), 1000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateTimeLeft() {
    const { days, hours, minutes, seconds, timeDelta } = this.calcTimeLeft();
    if (timeDelta <= 0) {
      this.element.classList.add('countdown-timer--hidden');
      return clearInterval(this.interval);
    }

    if (this.daysTarget.innerText !== `${days}`)
      this.daysTarget.innerText = days;
    if (this.hoursTarget.innerText !== `${hours}`)
      this.hoursTarget.innerText = hours;
    if (this.minutesTarget.innerText !== `${minutes}`)
      this.minutesTarget.innerText = minutes;
    if (this.secondsTarget.innerText !== `${seconds}`)
      this.secondsTarget.innerText = seconds;
  }

  calcTimeLeft() {
    const timeDelta = (this.stopTime - Date.now()) / 1000;

    return {
      days: Math.floor(timeDelta / 86400),
      hours: Math.floor((timeDelta / 3600) % 24),
      minutes: Math.floor((timeDelta / 60) % 60),
      seconds: Math.floor(timeDelta % 60),
      timeDelta,
    };
  }

  toJSDateString(stopTime) {
    return new Date(stopTime.replace(' ', 'T').replace(' +', '+'));
  }
}
