import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['friend'];

  broadcastSelectionEvent(friend, selectionType) {
    const { friendFirstName, friendLastName, friendEmail } = friend.dataset;

    const friendSelectionChangedEvent = new CustomEvent('friendSelectionChanged', {
      bubbles: true,
      detail: {
        action: selectionType,
        friend: {
          first_name: friendFirstName,
          last_name: friendLastName,
          email: friendEmail,
        },
        id: this.id,
      },
    });
    this.element.dispatchEvent(friendSelectionChangedEvent);
  }

  get selectedFriends() {
    return this.friendTargets.filter((friend) => this.isSelected(friend));
  }

  get id() {
    return this.data.get('id');
  }

  isSelected(friend) {
    return friend.hasAttribute('selected');
  }

  onFriendClicked(event) {
    const element = event.currentTarget;

    this.toggleFriendSelection(element);
  }

  selectFriend(friend) {
    friend.setAttribute('selected', 'true');
    friend
      .querySelector('.team-member-carousel__item-image--selected')
      .classList.remove('util-hidden');
    friend
      .querySelector('.team-member-carousel__item-image')
      .classList.add('util-hidden');
  }

  deselectFriend(friend) {
    friend.removeAttribute('selected');
    friend
      .querySelector('.team-member-carousel__item-image')
      .classList.remove('util-hidden');
    friend
      .querySelector('.team-member-carousel__item-image--selected')
      .classList.add('util-hidden');
  }

  deselectAllFriends() {
    this.selectedFriends.forEach((friend) => this.deselectFriend(friend));
  }

  toggleFriendSelection(friend) {
    if (this.isSelected(friend)) {
      this.deselectFriend(friend);
      this.broadcastSelectionEvent(friend, 'deselected');
    } else {
      this.deselectAllFriends();
      this.selectFriend(friend);
      this.broadcastSelectionEvent(friend, 'selected');
    }
  }
}

