import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'countrySelected',
    'countryFieldsData',
    'bankName'
  ];

  get formUrl() {
    return this.data.get('form-url')
  }
  @bound
  onFetchCountryFieldsDataSuccess(response) {
    this.countryFieldsDataTarget.innerHTML = response.activeElement.innerHTML;
    if (this.hasbankNameTarget) {
      Rails.fire(this.countrySelectedTarget, "howler:bank_details.reload");
    }
  }

  @bound
  onFetchCountryFieldsDataError(response) {
    this.countryFieldsDataTarget.innerHTML = response;
  }

  onCountryChange() {
    this.countryFieldsDataTarget.innerHTML = 'loading..';
    Rails.ajax({
      url: `${this.formUrl}&country_code=${this.countrySelectedTarget.value}&type=TransferwiseBankAccount`,
      type: 'GET',
      success: this.onFetchCountryFieldsDataSuccess,
      error: this.onFetchCountryFieldsDataError,
    });
  }

  connect() {
    this.onCountryChange();
  }
}
