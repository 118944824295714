import ApplicationController from "javascript/controllers/application_controller";
import Rails from '@rails/ujs';

import { trackGa4Event } from 'javascript/utils/utils';

export default class extends ApplicationController {
  static targets = [
    'quantity',
    'display',
    'minus',
    'plus'
  ];

  get minimum() {
    return parseInt(this.data.get('minimum'), 10);
  }

  get maximum() {
    return parseInt(this.data.get('maximum'), 10);
  }

  get step() {
    return parseInt(this.data.get('step'), 10);
  }

  get cost() {
    return parseFloat(this.data.get('cost'));
  }

  get fees() {
    return parseFloat(this.data.get('fees'));
  }

  get maxOrderLimitReached() {
    return this.data.get('maxOrderLimitReached') === 'true' || false;
  }

  get quantity() {
    return parseInt(this.quantityTarget.value || '0', 10);
  }

  set quantity(value) {
    this.quantityTarget.value = String(value);
    this.displayTarget.textContent = value;

    this.refresh();
  }

  connect() {
    if (this.quantity > 0) {
      this.element.classList.add('is-selected');
    }

    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-ticket-selector-max-order-limit-reached')) {
        this.enableOrDisablePlusButton();
      }
    });
    this.observer.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  increment() {
    let newQuantity = this.quantity + this.step;
    if (newQuantity > this.maximum || this.maxOrderLimitReached) return;
    if (newQuantity < this.minimum) newQuantity = this.minimum;
    this.quantity = newQuantity;

    const ecommerceData = JSON.parse(this.element.getAttribute('ecommerce-data'));
    trackGa4Event('add_to_cart', ecommerceData);
  }

  decrement() {
    let newQuantity = this.quantity - this.step;
    if (newQuantity < this.minimum) {
      newQuantity = 0;
    }
    this.quantity = newQuantity;

    const ecommerceData = JSON.parse(this.element.getAttribute('ecommerce-data'));
    trackGa4Event('remove_from_cart', ecommerceData);
  }

  refresh() {
    if (this.quantity > 0) {
      this.element.classList.add('is-selected');
    } else {
      this.element.classList.remove('is-selected');
    }

    const totalTicketCost = this.cost * this.quantity;
    const totalFees = this.fees * this.quantity;
    const totalCost = totalTicketCost + totalFees;
    this.data.set('quantity', this.quantity);
    this.data.set('totalTicketCost', totalTicketCost);
    this.data.set('totalFees', totalFees);
    this.data.set('totalCost', totalCost);

    Rails.fire(this.element, 'howler:ticket-selector.change');

    this.enableOrDisablePlusButton();
  }

  enableOrDisablePlusButton() {
    this.plusTarget.disabled = (this.quantity >= this.maximum || this.maxOrderLimitReached);
  }
}

