import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['input', 'incrementButton', 'decrementButton'];

  connect() {
    this.init();
  }

  init() {
    this.inputTarget.value = this.initial;
    this.showHideButtons();
  }

  broadcastChange() {
    const event = new CustomEvent('stepperInputChanged', {
      bubbles: true,
      detail: {
        value: this.inputTarget.value,
      },
    });
    this.element.dispatchEvent(event);
  }

  get max() {
    return parseInt(this.data.get('max'));
  }

  get min() {
    return parseInt(this.data.get('min'));
  }

  get initial() {
    return parseInt(this.data.get('initial'));
  }

  incrementButtonClicked(e) {
    e.preventDefault();
    this.incrementQuantity();
    this.valueChanged();
  }
  
  decrementButtonClicked(e) {
    e.preventDefault();
    this.decrementQuantity();
    this.valueChanged();
  }
  
  valueChanged() {
    this.showHideButtons();
    this.broadcastChange();
    this.inputTarget.dispatchEvent(new Event('change'));
  }

  incrementQuantity() {
    this.inputTarget.value = this.quantity + 1;
  }

  decrementQuantity() {
    this.inputTarget.value = this.quantity - 1;
  }

  showHideButtons() {
    const inputValue = parseInt(this.inputTarget.value);
    if (inputValue <= this.min) {
      this.disableButton(this.decrementButtonTarget);
    } else {
      this.enableButton(this.decrementButtonTarget);
    }
    if (inputValue >= this.max) {
      this.disableButton(this.incrementButtonTarget);
    } else {
      this.enableButton(this.incrementButtonTarget);
    }
  }

  disableButton(button) {
    button.setAttribute('disabled', true);
  }

  enableButton(button) {
    button.removeAttribute('disabled');
  }

  get quantity() {
    return parseInt(this.inputTarget.value);
  }
}
