import bound from 'bound-decorator';
import ApplicationController from "../application_controller";

export default class extends ApplicationController {

  connect() {
    this.targetHeight;
    this.targetDOMNode = this.window.document.querySelector(this.data.get('target'));

    this.window.addEventListener('resize', this.sendPostMessage);
    this.window.document.addEventListener('turbolinks:load', this.sendPostMessage);
  }

  @bound
  sendPostMessage() {
    if (this.targetHeight !== this.targetDOMNode.offsetHeight) {
      this.targetHeight = this.targetDOMNode.offsetHeight;
      this.window.parent.postMessage({
        frameHeight: this.targetHeight,
      }, '*');
    }
  }

  disconnect() {
    this.window.removeEventListener('resize', this.sendPostMessage);
    this.window.document.removeEventListener('turbolinks:load', this.sendPostMessage);
  }
}
