import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import autocomplete from 'autocomplete.js';
export default class extends ApplicationController {
  static targets = ['cityInput', 'countrySelect', 'provinceSelect'];

  @bound
  connect() {
    this.cityInputSelector = `#${this.cityInputTarget.id}[data-target="${this.cityInputTarget.dataset.target}"]`;
    if (this.hasProvinceSelectTarget) {
      this.provinceSelectSelector = `[data-target="${this.provinceSelectTarget.dataset.target}"]`;
    }
    this.onCountrySelected(false);
    this.autoCompleteList = null;
    this.validateCity = false;
  }

  showOrHideAutoCompleteList() {
    this.countryHasCities((data) => {
      this.validateCity = data.count > 0;
      if (this.validateCity) {
        this.createAutoCompleteList();
      } else {
        this.destroyAutoCompleteList();
      }
    });
  }

  createAutoCompleteList() {
    if (this.autoCompleteList === null) {
      this.autoCompleteList = autocomplete(
        this.cityInputSelector,
        { openOnFocus: true, debug: false, hint: false, minLength: 2 },
        [
          {
            source: this.search,
            displayKey: (suggestion) => suggestion,
            debounce: 200,
            templates: {
              empty: `<div class="no-results-found">No results found</div>`,
            },
          },
        ]
      );
    }
  }
  destroyAutoCompleteList() {
    if (this.autoCompleteList !== null) {
      this.autoCompleteList.autocomplete.destroy();
      this.autoCompleteList = null
    }
  }

  @bound
  onCountrySelected(clearInputs = true) {
    this.selectedCountry = this.countrySelectTarget.value;
    this.urlSafeCountry = encodeURI(this.selectedCountry);

    if (this.hasProvinceSelectTarget && clearInputs) {
      this.setProvinceOptions(this.urlSafeCountry);
    } else {
      if (clearInputs) {
        this.element.querySelector(this.provinceSelector).value = '';
        this.element.querySelector(this.cityInputSelector).value = '';
        this.element.querySelector(this.cityInputSelector).classList.remove('has-error');
      }
      if (this.selectedCountry) {
        this.showOrHideAutoCompleteList();
      }
    }
  }

  @bound
  onProvinceSelected(clearInputs = true) {
    this.selectedProvince = this.provinceSelectTarget.value;
    this.urlSafeProvince = encodeURI(this.selectedProvince);

    if (clearInputs) {
      this.element.querySelector(this.cityInputSelector).value = '';
      this.element.querySelector(this.cityInputSelector).classList.remove('has-error');
    }
    if (this.selectedProvince) {
      this.showOrHideAutoCompleteList();
    }
  }

  @bound
  search(query, callback) {
    const urlSafeCity = encodeURI(query);
    this.apiRequest(
      `/cities?country=${this.urlSafeCountry}${this.urlSafeProvinceQueryString()}&start_with=${urlSafeCity}`,
      (data) => callback(data)
    );
  }

  countryHasCities(callback) {
    this.apiRequest(
      `/cities?country=${this.urlSafeCountry}${this.urlSafeProvinceQueryString()}&count=true`,
      (data) => callback(data)
    );
  }

  onInputBlur() {
    this.validateSelectedOption();
  }

  apiRequest(url, callback) {
    fetch(url)
      .then((response) => response.json())
      .then((data) => callback(data));
  }

  validateSelectedOption() {
    if (!this.validateCity) {
      return;
    }
    const cityInputValue = this.element.querySelector(this.cityInputSelector).value.trim().toLowerCase();
    this.element.querySelector(this.cityInputSelector).classList.remove('has-error');

    this.apiRequest(
      `/cities?country=${this.urlSafeCountry}${this.urlSafeProvinceQueryString()}&eql=${cityInputValue}`,
      (data) => {
        const correctCity = data[0] || null;

        if (correctCity !== null && correctCity.toLowerCase() === cityInputValue) {
          this.cityInputTarget.value = correctCity;
        } else {
          this.element.querySelector(this.cityInputSelector).classList.add('has-error');
        }
      }
    );
  }

  @bound
  setProvinceOptions(selectedCountry) {
    const provinceSelector = this.element.querySelector(this.provinceSelectSelector)
    provinceSelector.querySelectorAll(`${this.provinceSelectSelector} option`).forEach((option) => {
      option.remove();
    });

    this.apiRequest(
      `/provinces?country=${selectedCountry}`,
      (data) => {
        data.forEach((province) => {
          const option = document.createElement('option');
          option.textContent = province;
          option.value = province;
          provinceSelector.appendChild(option);
        });

        this.onProvinceSelected(true);
      }
    );
  }

  @bound
  urlSafeProvinceQueryString() {
    return this.urlSafeProvince ? `&province=${this.urlSafeProvince}` : '';
  }
}
