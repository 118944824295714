import ApplicationController from 'javascript/controllers/application_controller';
import Rails from '@rails/ujs';
import { trackGa4Event } from 'javascript/utils/utils';

export default class extends ApplicationController {
  static targets = ['total', 'variant', 'addOn', 'quantityInput'];

  connect() {
    this.quantity = 1;
    this.calculateTotal();
    trackGa4Event('view_item', this.ecommerceData);
  }

  get currency() {
    return this.data.get('currency');
  }

  get productName() {
    return this.data.get('name');
  }

  get productType() {
    return this.data.get('type');
  }

  get organiser() {
    return this.data.get('organiser');
  }

  get currencyFormatter() {
    return (this._currencyFormatter ||= new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: this.currency,
    }));
  }

  get variantEcommerceData() {
    const { name, value } = this.selectedVariantDetails;
    return {
      item_id: value,
      item_name: this.productName,
      affiliation: this.organiser,
      item_category: 'TICKET',
      item_category2: this.productType,
      item_variant: name,
      price: this.variantPrice,
      quantity: this.quantity,
    };
  }

  get addonsEcommerceData() {
    return this.addOnTargets.map((addon) => ({
      item_id: addon.dataset.id,
      item_name: addon.dataset.name,
      affiliation: this.organiser,
      item_category: 'TICKET',
      item_category2: 'addon',
      price: parseInt(addon.dataset.price),
      quantity: parseInt(addon.value),
    }));
  }

  get ecommerceData() {
    return {
      currency: this.currency,
      value: this.productTotal,
      items: [this.variantEcommerceData, ...this.addonsEcommerceData],
    };
  }

  get variantPrice() {
    let price = 0;
    this.variantTargets.forEach((variant) => {
      price += parseFloat(variant.selectedOptions[0].dataset.price);
    });

    return price;
  }

  get variantTotal() {
    return this.variantPrice * this.quantity;
  }

  get productTotal() {
    return this.addOnsCost + this.variantTotal;
  }

  get addOnsCost() {
    let total = 0;
    this.addOnTargets.forEach((addOn) => {
      const addOnCost = parseFloat(addOn.dataset.price) * parseInt(addOn.value);
      total += addOnCost;
    });

    return total;
  }

  submitButtonClicked() {
    trackGa4Event('add_to_cart', this.ecommerceData);
  }

  quantityChanged(e) {
    this.quantity = e.detail.value;
    this.calculateTotal();
  }

  variantChanged() {
    this.updateQuantityInput();
    this.calculateTotal();
    trackGa4Event('view_item', this.ecommerceData);
  }

  get selectedVariantDetails() {
    const selectedVariantOption = this.variantTarget.selectedOptions[0];
    return {
      name: selectedVariantOption.dataset.name,
      value: selectedVariantOption.value,
      price: selectedVariantOption.dataset.price,
      quantity: selectedVariantOption.dataset.quantity,
    };
  }

  onSubmit() {
    this.element.classList.add('contents-loading');
  }

  updateQuantityInput() {
    if (this.hasQuantityInputTarget) {
      this.quantityInputTarget.dataset.stepperInputMax =
        this.selectedVariantDetails.quantity;
      Rails.fire(this.quantityInputTarget, 'stepperInput:refresh');
      this.quantity = 1;
    }
  }

  addOnChanged() {
    this.calculateTotal();
  }

  calculateTotal() {
    this.totalTarget.innerText = this.currencyFormatter.format(
      this.productTotal
    );
  }
}

