import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import EmblaCarousel from 'embla-carousel';

export default class extends ApplicationController {
  static targets = ['container', 'buttonsContainer', 'nextButton', 'prevButton'];

  connect() {
    this.carousel = this.initCarousel();
    this.carouselChanged();

    this.carousel
      .on('select', this.carouselChanged)
      .on('init', this.carouselChanged)
      .on('reInit', this.carouselChanged);
  }

  get carouselContainer() {
    return this.hasContainerTarget ? this.containerTarget : this.element;
  }

  get carouselOptions() {
    const customOptions = this.data.get('options');
    if (customOptions) {
      return Object.assign(this.defaultCarouselOptions, JSON.parse(customOptions));
    } else {
      return this.defaultCarouselOptions;
    }
  }

  get defaultCarouselOptions() {
    return { loop: false, dragFree: true, slidesToScroll: 1, align: 'start' };
  }

  initCarousel() {
    return EmblaCarousel(this.carouselContainer, this.carouselOptions);
  }

  nextButtonClicked(e) {
    e.preventDefault()
    this.carousel.scrollNext();
  }

  prevButtonClicked(e) {
    e.preventDefault()
    this.carousel.scrollPrev();
  }

  @bound
  carouselChanged() {
    if (this.hasButtonsContainerTarget) {
      this.showHideNavigationButtons();
      this.toggleButtonState(this.prevButtonTarget, this.carousel.canScrollPrev());
      this.toggleButtonState(this.nextButtonTarget, this.carousel.canScrollNext());
    }
  }

  toggleButtonState(button, enabled) {
    if (enabled) {
      button.removeAttribute('disabled');
    } else {
      button.setAttribute('disabled', 'true');
    }
  }

  showHideNavigationButtons() {
    if (!(this.carousel.canScrollNext() || this.carousel.canScrollPrev())) {
      this.buttonsContainerTarget.classList.add('hidden');
    } else {
      this.buttonsContainerTarget.classList.remove('hidden');
    }
  }
}
