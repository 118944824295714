import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import { isPaymentPending } from 'javascript/utils/payment_status';

export default class extends ApplicationController {
  connect() {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', this.action);

    const ticketInput = document.createElement('input');
    ticketInput.setAttribute('type', 'hidden');
    ticketInput.setAttribute('name', 'cart[ticket_type]');
    ticketInput.setAttribute('value', this.ticketType);
    ticketInput.setAttribute('id', 'cart_ticket');

    const methodInput = document.createElement('input');
    methodInput.setAttribute('type', 'hidden');
    methodInput.setAttribute('name', '_method');
    methodInput.setAttribute('value', 'put');

    const redirectPathInput = document.createElement('input');
    redirectPathInput.setAttribute('type', 'hidden');
    redirectPathInput.setAttribute('name', 'cart[redirect_path]');
    redirectPathInput.setAttribute('value', this.redirectPath);
    redirectPathInput.setAttribute('id', 'cart_redirect_path');

    const authenticityToken = document.querySelector(
      'meta[name="csrf-token"',
    ).content;

    const authenticityTokenInput = document.createElement('input');
    authenticityTokenInput.setAttribute('type', 'hidden');
    authenticityTokenInput.setAttribute('name', 'authenticity_token');
    authenticityTokenInput.setAttribute('value', authenticityToken);

    const buttonInput = document.createElement('button');
    buttonInput.setAttribute('type', 'submit');
    buttonInput.innerHTML = this.buttonIcon;

    form.appendChild(ticketInput);
    form.appendChild(redirectPathInput);
    form.appendChild(buttonInput);
    form.appendChild(methodInput);
    form.appendChild(authenticityTokenInput);

    this.element.appendChild(form);

    window.addEventListener('pageshow', this.disableElement);
  }

  get action() {
    return this.data.get('action');
  }
  get ticketType() {
    return this.data.get('ticketType');
  }
  get redirectPath() {
    return this.data.get('redirectPath');
  }

  get buttonIcon() {
    return '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><title>close</title><path d="M17.414 16l4.643-4.643c.39-.39.39-1.024 0-1.414-.39-.39-1.023-.39-1.414 0L16 14.586l-4.643-4.643c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.023 0 1.414L14.586 16l-4.643 4.643c-.39.39-.39 1.024 0 1.414.39.39 1.023.39 1.414 0L16 17.414l4.643 4.643c.39.39 1.024.39 1.414 0 .39-.39.39-1.023 0-1.414L17.414 16z"></path></svg>';
  }

  @bound
  disableElement() {
    if (this.isDisabled()) {
      this.element.remove();
    }
  }

  isDisabled() {
    return isPaymentPending(this.orderId);
  }

  get orderId() {
    return this.data.get('orderId');
  }
}
