import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import { trackGa4Event } from 'javascript/utils/utils';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'continueForm',
    'orderForm',
    'cart',
    'cartDrawer',
    'cartTicketCostDisplay',
    'cartCountDisplay',
    'cartCountWordsDisplay',
    'cartFeeDisplay',
    'cartTotalDisplay',
    'maxErrorMessage',
    'ticketType',
    'seatingInfo',
    'checkoutButton',
  ];

  get maxOrderLimit() {
    if (this.data.has('maxOrderLimit')) {
      return parseInt(this.data.get('maxOrderLimit'), 10);
    } else {
      return null;
    }
  }

  get eventProtectPercentage() {
    if (this.data.has('eventProtectPercentage')) {
      return parseFloat(this.data.get('eventProtectPercentage'));
    } else {
      return 0;
    }
  }

  get totalTicketFees() {
    return this.sumTicketSelectorAttribute('TotalFees');
  }

  get totalTicketCost() {
    return this.sumTicketSelectorAttribute('TotalTicketCost');
  }

  get totalCost() {
    return this.sumTicketSelectorAttribute('TotalCost');
  }

  get totalQuantity() {
    return this.sumTicketSelectorAttribute('Quantity');
  }

  get totalFees() {
    return this.totalTicketFees + this.totalEventProtectFees;
  }

  get grandTotal() {
    return this.totalTicketCost + this.totalFees;
  }

  get totalEventProtectFees() {
    return this.totalCost * (this.eventProtectPercentage / 100.0);
  }

  get maxOrderLimitReached() {
    if (this.maxOrderLimit === null) return false;
    return this.totalQuantity >= this.maxOrderLimit;
  }

  get eventName() {
    return this.data.get('eventName');
  }

  get eventSlug() {
    return this.data.get('eventSlug');
  }

  get alreadyHasTickets() {
    return this.data.get('alreadyHasTickets') === 'true';
  }

  connect() {
    this.document.addEventListener(
      'turbolinks:before-cache',
      this.removeHtmlClasses,
      false,
    );
    this.window.addEventListener(
      'DOMContentLoaded',
      this.removeElementContentLoading,
    );
    this.currencySymbol = this.data.get('event-currency-symbol');
    this.ticketCounterTranslationsHash = JSON.parse(
      this.data.get('ticket-counter-translations'),
    );

    this.updateCart();
    this.trackViewItemListEvent();

    setTimeout(() => {
      this.ticketSelectorChange();
    }, 0);
  }

  disconnect() {
    this.removeHtmlClasses();
    this.document.removeEventListener(
      'turbolinks:before-cache',
      this.removeHtmlClasses,
    );
  }

  ticketSelectorChange() {
    this.updateCart();
    this.hideSeatingInfo();

    Rails.fire(this.element, 'howler:ticket-quantity-changed', {
      totalQuantity: this.totalQuantity,
      alreadyHasTickets: this.alreadyHasTickets,
      ecommerceData: this.cartEcommerceData,
    });
  }

  updateCart() {
    this.updateCartDisplay();
    this.enableOrDisableBuyButton();
    this.showOrHideOrderLimitError();
  }

  updateCartDisplay() {
    const totalQuantity = this.totalQuantity;
    [].forEach.call(
      this.cartCountDisplayTargets,
      (t) => (t.textContent = `${totalQuantity}`),
    );
    [].forEach.call(this.cartCountWordsDisplayTargets, (t) => {
      t.textContent =
        totalQuantity > 1
          ? this.ticketCounterTranslationsHash.other.replace(
              '%{count}',
              totalQuantity,
            )
          : this.ticketCounterTranslationsHash.one.replace(
              '%{count}',
              totalQuantity,
            );
    });
    [].forEach.call(
      this.cartTicketCostDisplayTargets,
      (t) =>
        (t.textContent = `${this.currencySymbol} ${this.totalTicketCost.toFixed(
          2,
        )}`),
    );
    [].forEach.call(
      this.cartFeeDisplayTargets,
      (t) =>
        (t.textContent = `${this.currencySymbol} ${this.totalFees.toFixed(2)}`),
    );
    [].forEach.call(
      this.cartTotalDisplayTargets,
      (t) =>
        (t.textContent = `${this.currencySymbol} ${this.grandTotal.toFixed(
          2,
        )}`),
    );
  }

  enableOrDisableBuyButton() {
    if (this.totalQuantity <= 0 && !this.alreadyHasTickets) {
      [].forEach.call(this.cartTargets, (t) => t.classList.add('is-empty'));
      this.document.documentElement.classList.remove('ticket-cart-is-open');
      this.checkoutButtonTarget.disabled = true;
    } else {
      [].forEach.call(this.cartTargets, (t) => t.classList.remove('is-empty'));
      this.document.documentElement.classList.add('ticket-cart-is-open');
      this.checkoutButtonTarget.disabled = false;
    }
  }

  showOrHideOrderLimitError() {
    if (this.maxOrderLimitReached) {
      [].forEach.call(this.maxErrorMessageTargets, (t) =>
        t.classList.remove('util-hidden'),
      );
      [].forEach.call(
        this.ticketTypeTargets,
        (tt) => (tt.dataset.ticketSelectorMaxOrderLimitReached = 'true'),
      );
      this.document.documentElement.classList.add('ticket-cart-is-maxed');
    } else {
      [].forEach.call(this.maxErrorMessageTargets, (t) =>
        t.classList.add('util-hidden'),
      );
      [].forEach.call(
        this.ticketTypeTargets,
        (tt) => (tt.dataset.ticketSelectorMaxOrderLimitReached = 'false'),
      );
      this.document.documentElement.classList.remove('ticket-cart-is-maxed');
    }
  }

  hideSeatingInfo() {
    [].forEach.call(this.seatingInfoTargets, (t) => {
      t.classList.add('util-hidden');
    });
    this.checkoutButtonTarget.classList.remove('util-hidden');
  }

  get ticketsInCart() {
    return this.ticketTypeTargets.filter(
      (ticketType) => ticketType.dataset.ticketSelectorQuantity > 0,
    );
  }

  get cartEcommerceData() {
    const ecommerceData = {
      currency: this.ticketTypeTarget.dataset.ticketSelectorCurrency,
      value: this.grandTotal,
    };
    const ecommerceItems = this.ticketsInCart.reduce((items, ticketType) => {
      const ecommerceData = JSON.parse(
        ticketType.getAttribute('ecommerce-data'),
      );
      ecommerceData.items[0].quantity = parseInt(
        ticketType.dataset.ticketSelectorQuantity,
      );

      items.push(...ecommerceData.items);

      return items;
    }, []);

    ecommerceData.items = ecommerceItems;

    return ecommerceData;
  }

  trackCartEvent(eventName) {
    try {
      const ecommerceData = this.cartEcommerceData;
      trackGa4Event(eventName, ecommerceData);
    } catch (exception) {
      console.error(exception);
    }
  }

  trackViewItemListEvent() {
    const items = [];
    this.ticketTypeTargets.forEach((ticketType) => {
      const ecommerceData = JSON.parse(
        ticketType.getAttribute('ecommerce-data'),
      );
      items.push(...ecommerceData.items);
    });

    const listItemID = `${this.eventSlug}_tickets`;
    const listItemName = `${this.eventName} - tickets`;

    trackGa4Event('view_item_list', {
      item_list_id: listItemID,
      item_list_name: listItemName,
      items: items,
    });
  }

  checkout() {
    this.element.classList.add('contents-loading');
    this.trackCartEvent('begin_checkout');

    if (this.totalQuantity <= 0 && this.alreadyHasTickets) {
      this.continueFormTarget.submit();
    } else {
      this.orderFormTarget.submit();
    }
  }

  checkoutSeated() {
    this.element.classList.add('contents-loading');
  }

  toggleCartDrawer() {
    this.cartDrawerTarget.classList.toggle('is-open');
    this.document.documentElement.classList.toggle('ticket-cart-is-expanded');

    if (this.cartDrawerTarget.classList.contains('is-open')) {
      this.trackCartEvent('view_cart');
    }
  }

  @bound
  removeHtmlClasses() {
    this.document.documentElement.classList.remove('ticket-cart-is-expanded');
    this.document.documentElement.classList.remove('ticket-cart-is-open');
    this.document.documentElement.classList.remove('ticket-cart-is-maxed');
  }

  @bound
  removeElementContentLoading() {
    this.element.classList.remove('contents-loading');
  }

  sumTicketSelectorAttribute(attr) {
    return [].reduce.call(
      this.ticketTypeTargets,
      (total, tt) => {
        return total + parseFloat(tt.dataset[`ticketSelector${attr}`] || '0');
      },
      0,
    );
  }
}
