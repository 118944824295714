import ApplicationController from "javascript/controllers/application_controller";
import bound from 'bound-decorator';
import * as algoliasearch from 'algoliasearch';
import autocomplete from 'autocomplete.js'
import {
  getMonthNameFromDate,
  stripDomainFromURL,
  datesAreOnSameDay,
} from './../../utils/utils';

export default class extends ApplicationController {

  static targets = [
    'input',
    'viewAllResultsLink',
  ];

  connect() {
    this.initAlgoliaSearch();

    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-should-show-site-search-bar')) {
        this.onShouldShowSiteSearchBarChanged();
      }
    });

    this.observer.observe(window.document.body, { attributes: true });
    this.onShouldShowSiteSearchBarChanged();
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.onWindowResize);
  }


  initAlgoliaSearch() {
    const client = algoliasearch(window.HowlerConfig.algolia.applicationId, window.HowlerConfig.algolia.apiKey);

    this.translationsHash = JSON.parse(this.element.getAttribute('data-site-search-bar-translations'));
    const onlyShowActiveEvents = this.data.get('onlyShowActiveEventsValue');
    const indexNames = JSON.parse(this.data.get('indicesValue'));
    const indexClients = indexNames.map((indexName) => {
      return client.initIndex(indexName);
    });
    const indexObjects = indexNames.map((indexName, i) => {
      let eventFilters = '';

      if (onlyShowActiveEvents === 'true') {
        eventFilters = 'primary_category_name:Active AND';
      }

      if (indexName === 'Event') {
        return {
          name: indexName.toLowerCase(),
          source: autocomplete.sources.hits(indexClients[i], { hitsPerPage: 3, filters: `${eventFilters} not_closed_or_international_cashouts_open = 1` }), // New predicate includes end_time > today
          displayKey: 'name',
          templates: {
            header: `<div class="aa-dataset-header">${this.translate(indexName.toLowerCase() + '_title')}</div>`,
            suggestion: (suggestion) => {
              const startDate = suggestion.start_time ? new Date(suggestion.start_time) : null;
              const endDate = suggestion.end_time ? new Date(suggestion.end_time) : null;

              let startDateForDisplay = startDate ? `${startDate.getDate()} ${getMonthNameFromDate(startDate, { format: 'short' })} ${startDate.getFullYear()}` : null;

              if (suggestion.online_streaming && startDate) {
                startDateForDisplay = `${startDate.getDate()} ${getMonthNameFromDate(startDate, { format: 'short' })} ${startDate.getFullYear()} @ ${String(startDate.getHours()).padStart(2, '0') + ':' + String(startDate.getMinutes()).padStart(2, '0')}`;
                if (datesAreOnSameDay(startDate, endDate)) {
                  startDateForDisplay += ` - ${String(endDate.getHours()).padStart(2, '0') + ':' + String(endDate.getMinutes()).padStart(2, '0')}`;
                }
              }

              const dateSegment = startDateForDisplay ? `<div class="search-result__info-icon">
                  <div class="line-icon line-icon--small"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" d="M6.75 3.75h10.5a3 3 0 0 1 3 3v10.5a3 3 0 0 1-3 3H6.75a3 3 0 0 1-3-3V6.75a3 3 0 0 1 3-3zm-3 6h16.5M7.5 2.25v3m9-3v3"></path></svg></div>
                  </div>
                  <div class="search-result__info-item search-result__info-item--shrunk">${startDateForDisplay}</div>
                  <span class="search-result__info-separator">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" version="1" viewBox="0 0 24 24"><circle cx="12" cy="12" r="9" fill-rule="evenodd"/></svg>
                  </span>` : '';

              const venueSegment = suggestion.venue ?
                (suggestion.venue.name ?
                  `<div class="search-result__info-icon">
                      <div class="line-icon line-icon--small line-icon--color-body-lighter">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" fill-rule="evenodd" d="M12 21.82c.3 0 7.9-6.13 7.9-11.7a7.9 7.9 0 1 0-15.8 0c0 5.57 7.6 11.7 7.9 11.7zm0-7.32a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z"></path></svg>
                        </div>
                      </div>
                    <div class="search-result__info-item">${suggestion.venue.name}</div>` : ''
                ) : '';

              const infoContent = `
                  ${dateSegment}
                  ${venueSegment}
                `;
              return this.searchResultTemplate(suggestion, infoContent);
            },
          },
        };
      } else {
        return {
          name: indexName.toLowerCase(),
          source: autocomplete.sources.hits(indexClients[i], { hitsPerPage: 1, filters: `number_of_upcoming_events > 0` }),
          displayKey: 'name',
          templates: {
            header: `<div class="aa-dataset-header">${this.translate(indexName.toLowerCase() + '_title')}</div>`,
            suggestion: (suggestion) => this.searchResultTemplate(suggestion)
          },
        };
      }
    });

    autocomplete(this.element.querySelector('input'),
      {
        hint: false,
        openOnFocus: true,
        debug: false,
        minLength: 3,
        templates: {
          header: `<div class="aa-dropdown-menu-header">
            <a href="#" data-target="site-search-bar.viewAllResultsLink"></a>
          </div>`,
          empty: `<div class="no-results-found">${this.translate('no_results_found')}</div>`,
        }
      },
      indexObjects
    )
      .on('autocomplete:selected', (e, suggestion) => {
        Turbolinks.visit(`${this.getSuggestionLink(suggestion)}`);
      })
      .on('autocomplete:shown', () => {
        this.element.classList.add('site-search-bar--suggestions-is-open');
      })
      .on('autocomplete:closed', () => {
        this.element.classList.remove('site-search-bar--suggestions-is-open');
      });
  }


  clearInputValue() {
    const inputEvent = document.createEvent('Event');
    inputEvent.initEvent('input', true, true);

    this.inputTarget.value = '';
    this.inputTarget.dispatchEvent(inputEvent);
  }


  close() {
    this.element.querySelector('input').blur();
    window.document.body.removeAttribute('data-should-show-site-search-bar');
  }


  clearInputValueAndClose() {
    this.clearInputValue();
    this.close();
  }


  onShouldShowSiteSearchBarChanged() {
    if (window.document.body.getAttribute('data-should-show-site-search-bar') === 'true') {
      this.element.querySelector('input').focus();
    }
  }


  getSuggestionLink(suggestion) {
    if (suggestion.is_redirect_url) {
      return suggestion.og_url;
    }

    return stripDomainFromURL(suggestion.og_url);
  }


  onFocus() {
    this.element.classList.add('site-search-bar--input-has-focus');
  }


  onBlur() {
    this.element.classList.remove('site-search-bar--input-has-focus');
  }


  onInput(e) {
    const searchInputValue = e.target.value;
    let viewAllResultsHref = this.element.getAttribute('data-site-search-bar-view-all-results-path') + searchInputValue;
    let viewAllResultsText = this.translate('view_all_results', { query: searchInputValue });

    if (searchInputValue === '') {
      this.element.classList.remove('site-search-bar--suggestions-is-open');
      viewAllResultsHref = '';
      viewAllResultsText = '';
    }

    this.viewAllResultsLinkTarget.setAttribute('href', viewAllResultsHref);
    this.viewAllResultsLinkTarget.innerHTML = viewAllResultsText;
  }


  translate(path, data, type) {
    switch (type) {
      case 'counter':
        return data == 1 ?
          this.translationsHash[path].one.replace('%{count}', data) :
          this.translationsHash[path].other.replace('%{count}', data);
      default:
        let result = this.translationsHash[path],
          prop;
        for (prop in data) {
          result = result.split(`%{${prop}}`).join(data[prop]);
        }
        return result;
    }
  }


  searchResultTemplate(suggestion, infoContent = null) {
    const thumbnailStyle = suggestion.header_image ? `style="background-image: url('${suggestion.header_image}');"` : '';
    let classList = ['search-result', (suggestion.header_image ? 'search-result--has-header-image' : '')];
    const _infoContent = infoContent || `<div class="search-result__info-item search-result__info-item--shrunk">${this.translate('upcoming_event_count', suggestion.number_of_upcoming_events, 'counter')}</div>`;

    return `
      <a class="${classList.join(' ')}" href="${this.getSuggestionLink(suggestion)}?utm_campaign=Instant%20Search&utm_medium=Web&utm_source=Howler%20Search">
        <div class="search-result__thumbnail" ${thumbnailStyle}></div>
        <div class="search-result__details">
          <div class="search-result__title t--bold">
            <span class="text">${suggestion.name}</span>
            ${suggestion.online_streaming ? `<span class="badge online-streaming-event-badge">${this.translate('online_streaming_event_badge_label')}</span>` : ``}
          </div>
          <div class="search-result__info color-body-lighter">
            ${_infoContent}
          </div>
        </div>
      </a>`;
  }


  @bound
  onWindowResize(e) {
    if (e.target.innerWidth !== this.windowWidth) {
      this.windowWidth = e.target.innerWidth;
      if (window.document.body.getAttribute('data-should-show-site-search-bar') === 'true') {
        this.close();
      }
    }
  }


  disconnect() {
    this.observer.disconnect();
    window.removeEventListener('resize', this.onWindowResize);
  }

}
