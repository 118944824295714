import ApplicationController from "javascript/controllers/application_controller";
import '../../vendor/slick_carousel';

export default class extends ApplicationController {
  static targets = ['carousel'];

  get slide() {
    if (location.hash) {
      return parseInt(location.hash.substr(1))
    } else {
      return 0;
    }
  }

  connect() {
    let slickPrevHtml = $('.js-slick-prev').html();
    let slickNextHtml = $('.js-slick-next').html();

    let EVENT_CAROUSEL_SETTINGS = {
      mobileFirst: true,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      slide: '.ticket-card',
      initialSlide: this.slide,
      dots: false,
      centerMode: true,
      centerPadding: '0',
      prevArrow: slickPrevHtml,
      nextArrow: slickNextHtml
    };

    let slickCarousel = $('.js-slide-class');

    slickCarousel.slick(EVENT_CAROUSEL_SETTINGS);
  }
}