import Turbolinks from 'turbolinks';
import COUNTRIES from './countries.json';

export async function countryDialingCodeData(whitelist = null) {
  if (whitelist === null) {
    return COUNTRIES;
  }
  return COUNTRIES.filter(country => {
    return whitelist.includes(country.id) || whitelist.includes(country.cc);
  });
}

export function closestTarget(controller, target, element) {
  return element.closest(`[data-target="${controller.identifier}.${target}"]`)
}

export function childTarget(controller, target, element) {
  return element.querySelector(`[data-target*="${controller.identifier}.${target}"]`)
}

export async function getFormattedPhoneNumberValue(phoneNumber, dialingCode) {
  if (!phoneNumber) {
    return '';
  }

  phoneNumber = phoneNumber.replace(/(-|\s)/g, '');

  if (!dialingCode) {
    return phoneNumber;
  }

  phoneNumber = phoneNumber.replace(/^0/, '');
  phoneNumber = phoneNumber.replace(new RegExp(`^\\+${dialingCode}`), '');

  return `+${dialingCode}${phoneNumber}`;
}


export async function getDialingCodeFromPhoneNumber(phoneNumber) {
  const countries = await countryDialingCodeData();

  let result;
  for (let i = 0; i < 6; i++) {
    result = countries.find(country => {
      return `+${country.cc}` === phoneNumber.substr(0, 6 - i);
    });

    if (result) {
      return result.cc;
    }
  }

  return '';
}

export async function stripDialingCodeFromPhoneNumber(phoneNumber) {
  const dialingCode = await getDialingCodeFromPhoneNumber(phoneNumber);
  return phoneNumber.replace(new RegExp(`^\\+?${dialingCode}`), '');
}

export function pxToInt(s) {
  return parseInt(s.replace(/px$/, ''), 10);
}

export function unsetTransition(element) {
  element.style.transitionProperty = '';
  element.style.transitionDuration = '';
  element.style.transitionTimingFunction = '';
}

export function setTransition(element, { transitionDuration, transitionTimingFunction }) {
  element.style.transitionProperty = 'height';
  element.style.transitionDuration = transitionDuration;
  element.style.transitionTimingFunction = transitionTimingFunction;
}

export function raf(cb) {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(cb);
  } else {
    setTimeout(cb, 0);
  }
}

export function getElementsWindow(element) {
  return element.ownerDocument.defaultView;
}

export function wrapInDiv(element, wrapperAttribute) {
  if (element.getAttribute(wrapperAttribute) === 'true') {
    return element.parentElement;
  }
  let div = element.ownerDocument.createElement('div');
  const parent = element.parentElement;
  div = parent.insertBefore(div, element);
  parent.removeChild(element);
  div.appendChild(element);
  element.setAttribute(wrapperAttribute, 'true');
  return div;
}

export function getTotalHeight(element) {
  const style = getElementsWindow(element).getComputedStyle(element);
  return element.offsetHeight + pxToInt(style.marginTop) + pxToInt(style.marginBottom);
}

export function getTransition(element) {
  const style = getElementsWindow(element).getComputedStyle(element);
  return {
    transitionDuration: style.transitionDuration,
    transitionTimingFunction: style.transitionTimingFunction
  };
}

export function canCopyToClipboard() {
  return document.queryCommandSupported('copy');
}

export function copyToClipboard(text) {
  let successful = false;
  let textArea = document.createElement('textarea');

  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  let range = document.createRange();
  range.selectNodeContents(textArea);

  let s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  textArea.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  try {
    successful = document.execCommand('copy');
  } catch (err) {
    successful = false;
  }

  document.body.removeChild(textArea);

  return successful;
}

export function scrollWindowToTop(scrollDuration) {
  const cosParameter = window.scrollY / 2;
  let scrollCount = 0;
  let oldTimestamp = performance.now();

  function step(newTimestamp) {
    scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
    if (scrollCount >= Math.PI) {
      window.scrollTo(0, 0)
    };
    if (window.scrollY === 0) {
      return
    };
    window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }

  window.requestAnimationFrame(step);
}

export function getMonthNameFromDate(date, options) {
  const monthListFullNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const monthListShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  options = options || {
    format: 'long',
  };

  if (!date) {
    return '';
  }

  if (typeof date.getMonth !== 'function') {
    return '';
  }

  return options.format === 'short' ? monthListShortNames[date.getMonth()] : monthListFullNames[date.getMonth()];
}

export function formatNumberToCurrency(amount, currencySymbol = 'R', thousandsSeparator = ' ') {
  let parsedAmount = parseFloat(amount, 10);
  parsedAmount.toFixed(2);
  parsedAmount = String(parsedAmount);

  const amountParts = parsedAmount.split('.');
  let integerPart = amountParts[0];
  let decimalPart = "00";

  if (amountParts.length > 1) {
    decimalPart = amountParts[1];
  }

  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(integerPart)) {
    integerPart = integerPart.replace(rgx, '$1' + thousandsSeparator + '$2');
  }

  if (parseInt(decimalPart) == 0) {
    decimalPart = "00";
  } else if (decimalPart.length == 1) {
    decimalPart = decimalPart + "0";
  }

  return currencySymbol + integerPart + "." + decimalPart;
}

export function reloadWithTurbolinks(w = window, d = w.document) {
  let scrollPosition

  function reload() {
    scrollPosition = [w.scrollX, w.scrollY];
    Turbolinks.visit(w.location.toString(), { action: 'replace' });
  }

  function afterLoad() {
    if (scrollPosition) {
      w.scrollTo.apply(w, scrollPosition);
      scrollPosition = null;
    }
    d.removeEventListener('turbolinks:load', afterLoad);
  }

  d.addEventListener('turbolinks:load', afterLoad, false);

  return reload;
}

export function abbreviateLargeNumberForDisplay(number, decimals = 0) {
  var SI_POSTFIXES = ['', 'k', 'm', 'b', 't'];
  var tier = Math.log10(Math.abs(number)) / 3 | 0;
  var formatted = number === 0 ? String(number) : String(number.toFixed(decimals));
  var postfix = SI_POSTFIXES[tier];

  if (tier > 0) {
    var scale = Math.pow(10, tier * 3);
    var scaled = number / scale;
    formatted = scaled.toFixed(decimals) + '';
    if (/\.0$/.test(formatted)) {
      formatted = formatted.substr(0, formatted.length - 2);
    }
  }

  return {
    value: formatted,
    unit: postfix,
  };
}

export function formatNumber(value, separator = ' ') {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + separator)
}

export function findColourBetween(color1, color2, positionIndex) {
  const decimalToHex = function (x) {
    x = x.toString(16);
    return (x.length == 1) ? '0' + x : x;
  };

  const colourTransformer = function (colourComponent1, colourComponent2, positionIndex) {
    const a = parseInt(colourComponent1, 16),
      b = parseInt(colourComponent2, 16);
    return Math.ceil(a + (b - a) * positionIndex);
  };

  const color1Normalised = normalizeHexColour(color1, false);
  const color2Normalised = normalizeHexColour(color2, false);

  const red = colourTransformer(color1Normalised.substring(0, 2), color2Normalised.substring(0, 2), positionIndex);
  const green = colourTransformer(color1Normalised.substring(2, 4), color2Normalised.substring(2, 4), positionIndex);
  const blue = colourTransformer(color1Normalised.substring(4, 6), color2Normalised.substring(4, 6), positionIndex);

  return '#' + String(decimalToHex(red)) + String(decimalToHex(green)) + String(decimalToHex(blue));
}

export function normalizeHexColour(colour, keepHash = true) {
  let colourNormalized = colour.split('#').join('');
  if (colourNormalized.length === 3) {
    colourNormalized = colourNormalized.split('').map((hex) => {
      return hex + hex;
    }).join('');
  }
  return (keepHash ? '#' : '') + colourNormalized;
}

export function stripDomainFromURL(url) {
  const urlWithoutProtocol = url.substr(url.indexOf('//') + ('//').length);
  return urlWithoutProtocol.substr(urlWithoutProtocol.indexOf('/'));
}

export function datesAreOnSameDay(dateA, dateB) {
  if (!dateA || !dateB) {
    return false;
  }
  return dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate();
}

export function addScriptTag(url, callback) {
  const head = window.document.querySelector('head');
  const tagElement = window.document.createElement('script');
  tagElement.src = url;
  tagElement.type = 'text/javascript';
  tagElement.onload = callback;
  head.appendChild(tagElement);
  return tagElement;
}

export function addLinkTag(url) {
  const head = window.document.querySelector('head');
  const linkElement = window.document.createElement('link');
  linkElement.href = url;
  linkElement.type = "text/css";
  linkElement.rel = "stylesheet";
  head.appendChild(linkElement);
  return linkElement;
}

export function doesMimeTypeExistInHTMLAcceptAttribute(mimeType, acceptAttribute) {
  if (!mimeType || !acceptAttribute) {
    return false;
  }
  const acceptValues = acceptAttribute.split(' ').join('').split(',');
  return acceptValues.filter(function (acceptValue) {
      return acceptValue.toLowerCase() === mimeType.toLowerCase();
    }).length > 0;
}

export function trackPageView(xhrOrPath) {
  let path = '';

  if (typeof xhrOrPath === 'string') {
    path = xhrOrPath;
  }

  if (xhrOrPath && (xhrOrPath.responseURL)) {
    path = xhrOrPath.responseURL.replace(window.location.origin, '');
  }

  if (path && window.ga) {
    window.ga('send', 'pageview', path);
    return true;
  }
}

export function trackEvent(...args) {
  if (args && args.length > 0 && args[0] && window.ga) {
    window.ga('send', 'event', ...args);
    return true;
  }
}

export function trackGa4Event(eventName, eventParams = {}) {
  if (eventName && window.gtag) {
    window.gtag('event', eventName,  eventParams);
    return true;
  }
}

export function variableReplace(string, replacement) {
  var regexp = /\${([^{]+)}/g;
  return string.replace(regexp, function (ignore, key) {
    return (key = replacement[key]) == null ? '' : key;
  });
}

