import ApplicationController from "javascript/controllers/application_controller";

function smoothScrollEnabled() {
  return 'scrollBehavior' in document.documentElement.style;
}

export default class extends ApplicationController {
  static targets = ["scroll"];

  get behavior() {
    return this.data.get('behavior') || 'auto';
  }

  get block() {
    return this.data.get('block') || 'center';
  }

  get inline() {
    return this.data.get('inline') || 'nearest';
  }

  get scrollElement() {
    if (this.hasScrollTarget) {
      return this.scrollTarget;
    }
    return this.element;
  }

  connect() {
    this.performScroll();
  }

  performScroll(shouldDelay = true) {
    if (shouldDelay && this.data.has('delay')) {
      setTimeout(() => this.performScroll(false), parseInt(this.data.get('delay'), 10));
    } else {
      if (!this.scrollElement.scrollIntoView) {
        return;
      }
      if (smoothScrollEnabled()) {
        this.scrollElement.scrollIntoView({ behavior: this.behavior, block: this.block, inline: this.inline });
      } else {
        this.scrollElement.scrollIntoView(this.block === 'start' || this.block === 'center');
        if (this.scrollElement.scrollIntoViewIfNeeded) {
          this.scrollElement.scrollIntoViewIfNeeded(this.inline === 'center');
        }
      }
    }
  }
}