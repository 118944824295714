function initiateAccordion(elem) {
  return new Foundation.Accordion(elem, {
    allowAllClosed: true,
    multiExpand: true
  });
}

function destroyAccordion(accordion) {
  if (!accordion) return;
  accordion.destroy();
  accordion = null;
}

$(document).on('turbolinks:load', () => {
  const accordionElem = $('.js-ticket-selection-accordion');

  if (!accordionElem.length) return;

  let accordion = initiateAccordion(accordionElem);

  accordionElem.find('[data-tab-content]').each(function() {
    let $target = $(this);
    if (typeof $target.data('collapsedByDefault') === 'undefined') {
      accordionElem.foundation('down', $target);
    }
  });

  $(document).on('turbolinks:before-cache', () => {
    destroyAccordion(accordion);
  });
});
