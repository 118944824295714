import ApplicationController from 'javascript/controllers/application_controller';
import { canCopyToClipboard, copyToClipboard } from 'javascript/utils/utils';

export default class extends ApplicationController {

  delayedClassRemoval(className) {
    window.setTimeout(() => {
      this.element.classList.remove(className);
    }, 1500);
  }

  onClick(e) {
    e.preventDefault();
    const isCopySuccessful = copyToClipboard(this.data.get('value'));

    if (isCopySuccessful) {
      this.element.classList.add('clipboard-copy-button--success');
      this.delayedClassRemoval('clipboard-copy-button--success');
    } else {
      this.element.classList.add('clipboard-copy-button--failure');
      this.delayedClassRemoval('clipboard-copy-button--failure');
    }
  }

  connect() {
    if (canCopyToClipboard()) {
      this.element.classList.add('clipboard-copy-button--is-supported');
    }
  }

}
