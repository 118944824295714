import QuestionController from './question_controller';

function leftpad(s) {
  return s.length >= 2 ? s : `0${s}`;
}

export default class extends QuestionController {
  // NOTE: cannot set select-specific data attributes
  get daySelect() {
    return this.element.querySelector('.js-question-input-day');
  }

  get monthSelect() {
    return this.element.querySelector('.js-question-input-month');
  }

  get yearSelect() {
    return this.element.querySelector('.js-question-input-year');
  }

  get value() {
    const dayValue = this.daySelect.value;
    const monthValue = this.monthSelect.value;
    const yearValue = this.yearSelect.value;

    if (!dayValue || !monthValue || !yearValue) return '';
    return `${yearValue}-${leftpad(monthValue)}-${leftpad(dayValue)}`;
  }

  clear() {
    this.daySelect.value = '';
    this.monthSelect.value = '';
    this.yearSelect.value = '';
  }
}