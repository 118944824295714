import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  submitError({ detail: [response, error, xhr] }) {
    if (xhr.status !== 422) {
      console.error(`Something went wrong: ${error}`);
      return
    }

    if (!this.errorResponseObject(response)) {
      this.clearErrors();
      return;
    }

    [].forEach.call(this.errorTargets, t => {
      const hasError = t.dataset.errorFor in this.errorResponseObject(response);
      if (!hasError) {
        this.hideError(t);
      }
    });

    Object.keys(this.errorResponseObject(response)).forEach(ownerInput => {
      const errorText = this.errorResponseObject(response)[ownerInput][0];
      const errorElement = [].find.call(this.errorTargets, t => t.dataset.errorFor === ownerInput && t.dataset.enabled !== 'false');
      errorElement.textContent = errorText;
      if (errorElement.style.display === 'none') {
        this.showError(errorElement);
      }
    });
  }

  errorResponseObject(_response) {
    throw new Error('Implement in subclass');
  }

  clearErrors() {
    [].forEach.call(this.errorTargets, this.hideError);
  }

  showError(errorElement) {
    if (errorElement.dataset.slide === 'no') {
      $(errorElement).show();
    } else {
      $(errorElement).slideDown();
    }
  }

  hideError(errorElement) {
    if (errorElement.dataset.slide === 'no') {
      $(errorElement).hide();
    } else {
      $(errorElement).slideUp();
    }
  }
}
