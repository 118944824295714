import ApplicationController from 'javascript/controllers/application_controller';
import { variableReplace } from 'javascript/utils/utils';
export default class extends ApplicationController {
  static targets = [
    'blob',
    'detailsTemplate',
    'shopLinksTemplate',
    'socialLinksTemplate',
  ];

  connect() {
    let blob = {};

    try {
      blob = JSON.parse(this.blobTarget.innerHTML);
      if (Array.isArray(blob)) throw 'JSON should be an object, not an array';
    } catch (error) {
      console.log('Connect & Shop Controller FAILED');
      console.error(error);
    }

    const {
      detailsTemplateHTML,
      shopLinksTemplateHTML,
      socialLinksTemplateHTML,
    } = this.getHtmlTemplates();

    const detailsHTML = this.generateDetailsHtml(
      detailsTemplateHTML,
      blob.details
    );
    const shopLinksHTML = this.generateShopLinksHtml(
      shopLinksTemplateHTML,
      blob.shopLinks
    );
    const socialLinksHTML = this.generateSocialLinks(
      socialLinksTemplateHTML,
      blob.socialLinks
    );

    this.element.innerHTML = this.generateOutputHtml(
      detailsHTML,
      shopLinksHTML,
      socialLinksHTML
    );
  }

  generateOutputHtml(detailsHTML, shopLinksHTML, socialLinksHTML) {
    return `<div class="connect-shop__details">${detailsHTML}</div>
<div class="connect-shop__shop-links">${shopLinksHTML}</div>
<div class="connect-shop__social-links">${socialLinksHTML}</div>`;
  }

  getHtmlTemplates() {
    const DEFAULT_TEMPLATES = {
      details:
        '<div><strong>${label} </strong><a href="${href}" target="_blank">${value}</a></div>',
      shopLinks:
        '<a href="${url}" class="btn btn--stretch" target="_blank">${label}</a>',
      socialLinks:
        '<a href="${url}" target="_blank"> <img src="${icon_url}"> <span>${name}</span> </a>',
    };
    const detailsTemplateHTML = this.hasDetailsTemplateTarget
      ? this.detailsTemplateTarget.innerHTML
      : DEFAULT_TEMPLATES.details;
    const shopLinksTemplateHTML = this.hasShopLinksTemplateTarget
      ? this.shopLinksTemplateTarget.innerHTML
      : DEFAULT_TEMPLATES.shopLinks;
    const socialLinksTemplateHTML = this.hasSocialLinksTemplateTarget
      ? this.socialLinksTemplateTarget.innerHTML
      : DEFAULT_TEMPLATES.socialLinks;

    return {
      detailsTemplateHTML,
      shopLinksTemplateHTML,
      socialLinksTemplateHTML,
    };
  }

  generateDetailsHtml(detailsTemplateHTML, details) {
    return details
      ? details.reduce((accumulator, item) => {
          let href = item.value;
          switch (item.type) {
            case 'email':
              href = `mailto:${item.value}`;
              break;
            case 'phone':
              href = `tel:${item.value}`;
              break;
          }
          const outputHtml = detailsTemplateHTML
            .replace(/\${label}/g, item.label)
            .replace(/\${href}/g, href)
            .replace(/\${value}/g, item.value);

          return `
      ${accumulator}
      ${outputHtml}
      `;
        }, '')
      : '';
  }

  generateShopLinksHtml(shopLinksTemplateHTML, shopLinks) {
    return shopLinks
      ? shopLinks.reduce((accumulator, item) => {
          return `
          ${accumulator}
          ${variableReplace(shopLinksTemplateHTML, item)}
        `;
        }, '')
      : '';
  }

  generateSocialLinks(socialLinksTemplateHTML, socialLinks) {
    const SOCIAL_MEDIA_TYPES = {
      FACEBOOK: {
        label: 'Facebook',
        iconURL:
          'https://howler-marketing.s3-eu-west-1.amazonaws.com/event-stream-icons/Logos/facebook-fill.svg',
      },
      INSTAGRAM: {
        label: 'Instagram',
        iconURL:
          'https://howler-marketing.s3-eu-west-1.amazonaws.com/event-stream-icons/Logos/instagram-fill.svg',
      },
      TWITTER: {
        label: 'Twitter',
        iconURL:
          'https://howler-marketing.s3-eu-west-1.amazonaws.com/event-stream-icons/Logos/twitter-fill.svg',
      },
    };
    return socialLinks
      ? socialLinks.reduce((accumulator, item) => {
          const name = SOCIAL_MEDIA_TYPES[item.type].label || '';
          const iconURL = SOCIAL_MEDIA_TYPES[item.type].iconURL || '';

          const outputHtml = socialLinksTemplateHTML
            .replace(/\${url}/g, item.url)
            .replace(/\${icon_url}/g, iconURL)
            .replace(/\${name}/g, name);

          return `
      ${accumulator}
     ${outputHtml}
      `;
        }, '')
      : '';
  }
}
