import ApplicationController from "../application_controller";
import bound from 'bound-decorator';

export default class extends ApplicationController {
  static targets = [
    'formElement',
    'container',
    'paymentForm',
    'refundProtect',
    'token'
  ];

  get publicKey() {
    return this.data.get('publicKey');
  }

  get checkoutLogoPath() {
    return this.data.get('checkoutLogoPath');
  }

  get isRefundProtectSelected() {
    const input = document.querySelector('[name="add_ticket_insurance"]');
    return input ? input.checked : false;
  }

  connect() {
    this.containerTarget.classList.add('contents-loading');
    this.refundProtectTarget.value = this.isRefundProtectSelected ? '1' : '0';

    Frames.init({
      publicKey: this.publicKey,
      style: {
        base: {
          fontFamily: '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          fontSize: "20px",
          letterSpacing: "normal",
          fontWeight: "400",
        },
        placeholder: {
          base: {
            color: "#818a91",
          },
        },
      },
      localization: {
        cardNumberPlaceholder: "",
        expiryMonthPlaceholder: "MM",
        expiryYearPlaceholder: "YY",
        cvvPlaceholder: "",
      }
    });

    Frames.addEventHandler(
      Frames.Events.FRAME_ACTIVATED,
      this.containerTarget.classList.remove('contents-loading')
    );

    Frames.addEventHandler(
      Frames.Events.FRAME_VALIDATION_CHANGED,
      this.onValidationChanged
    );

    Frames.addEventHandler(
      Frames.Events.CARD_VALIDATION_CHANGED,
      this.cardValidationChanged
    );

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZATION_FAILED,
      this.onCardTokenizationFailed
    );

    Frames.addEventHandler(
      Frames.Events.CARD_TOKENIZED,
      this.createPayment
    );

    this.logos = {
      "card-number": {
        src: "card",
        alt: "card number logo",
      },
      "expiry-date": {
        src: "exp-date",
        alt: "expiry date logo",
      },
      "cvv": {
        src: "cvv",
        alt: "cvv logo",
      },
    };
  }

  @bound
  onValidationChanged(event) {
    const element = event.element;

    if (event.isValid || event.isEmpty) {
      this.clearErrorMessage(element);
    } else {
      this.setErrorMessage(element);
    }
  }

  @bound
  clearErrorMessage(element) {
    const selector = ".error-message__" + element;
    let message = document.querySelector(selector);

    message.style.display = "none"
  }

  @bound
  setErrorMessage(element) {
    const selector = ".error-message__" + element;
    let message = document.querySelector(selector);

    message.style.display = "inline"
  }

  @bound
  cardValidationChanged() {
    let payButton = document.getElementById("pay-button");

    payButton.disabled = !Frames.isCardValid();
  }

  @bound
  onCardTokenizationFailed(error) {
    Frames.enableSubmitForm();
  }

  @bound
  createPayment(event) {
    this.tokenTarget.value = event.token
    this.paymentFormTarget.submit()
  };

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.containerTarget.classList.add('contents-loading');
    Frames.submitCard();
  };
}
