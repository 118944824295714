import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';

function getBodyScrollTop() {
  return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
}

function getElementOffset(element) {
  const rect = element.getBoundingClientRect(),
    currentScrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    currentScrollTop = getBodyScrollTop();
  return {
    top: rect.top + currentScrollTop,
    left: rect.left + currentScrollLeft,
  }
}

export default class extends ApplicationController {

  @bound
  onShouldShowSiteSearchBarChanged() {
    if (window.document.body.getAttribute('data-should-show-site-search-bar') !== 'true') {
      this.updateHeaderPosition();
    }
  }

  @bound
  updateHeaderPosition() {
    this.siteHeaderHeight = this.siteHeaderDOMNode.offsetHeight;
    this.headerDOMNode.style.top = -(this.headerDOMNode.offsetHeight - this.tabsContainerHeight - this.siteHeaderHeight) + 'px';
  }

  @bound
  updateActiveTab() {
    const currentScrollTop = getBodyScrollTop();
    const totalLinkableElements = this.linkableElements.length;
    const subtractiveOffsetHeight = (this.siteHeaderHeight + this.tabsContainerHeight) * 1.5;

    Array.prototype.forEach.call(this.linkableElements, (targetElement, index) => {
      let nextTargetElement = (index < totalLinkableElements - 1) ? this.linkableElements[index + 1] : null;

      const targetElementOffsetTop = getElementOffset(targetElement).top;
      const nextTargetElementOffsetTop = nextTargetElement ? getElementOffset(nextTargetElement).top : this.document.body.scrollHeight;

      if (currentScrollTop > (targetElementOffsetTop - subtractiveOffsetHeight) && currentScrollTop < (nextTargetElementOffsetTop - subtractiveOffsetHeight)) {
        this.onTabSelect(index);
      } else {
        this.onTabDeselect(index);
      }
    });
  }

  @bound
  onTabSelect(index) {
    const tabDOMNode = this.linkElements[index];
    if (typeof tabDOMNode !== 'undefined') {
      const newScrollLeft = tabDOMNode.offsetLeft - 24;

      tabDOMNode.classList.add('is-active');

      if (this.tabsContainerDOMNode.scrollLeft !== newScrollLeft) {
        this.tabsContainerDOMNode.scrollTo({
          top: 0,
          left: newScrollLeft,
          behavior: 'smooth',
        });
      }
    }
  }

  @bound
  onTabDeselect(index) {
    const tabDOMNode = this.linkElements[index];
    if (typeof tabDOMNode !== 'undefined') {
      tabDOMNode.classList.remove('is-active');
    }
  }

  connect() {
    this.siteHeaderDOMNode = document.querySelector('.page-wrapper .header');
    this.headerDOMNode = document.querySelector('.landing-page__header');

    this.tabsContainerDOMNode = document.querySelector('.landing-page__header__tabs-container');
    this.tabsContainerWidth = this.tabsContainerDOMNode.offsetWidth;
    this.tabsContainerHeight = this.tabsContainerDOMNode.offsetHeight;

    this.linkElements = document.querySelectorAll('.landing-page__header__tabs-link');
    this.linkableElements = document.querySelectorAll('.landing-page__header__tabs-linkable');

    this.window.addEventListener('load', this.updateHeaderPosition, false);
    this.window.addEventListener('resize', this.updateHeaderPosition, false);
    this.window.addEventListener('scroll', this.updateActiveTab);

    this.observer = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-should-show-site-search-bar')) {
        this.onShouldShowSiteSearchBarChanged();
      }
    });
    this.observer.observe(window.document.body, { attributes: true });

    this.updateHeaderPosition();
    this.updateActiveTab();
  }

  disconnect() {
    this.window.removeEventListener('load', this.updateHeaderPosition);
    this.window.removeEventListener('resize', this.updateHeaderPosition);
    this.window.removeEventListener('scroll', this.updateActiveTab);
    this.observer.disconnect();
  }
}
