export function openFlashMessage(flashElement, text) {
  flashElement.querySelector('[data-target="flash.text"]').textContent = text;
  flashElement.setAttribute('data-flash-open', 'true'); 
}

export function flashNotice(text) {
  openFlashMessage(document.querySelector('[data-flash-type="notice"]'), text);
}
export function flashAlert(text) {
  openFlashMessage(document.querySelector('[data-flash-type="alert"]'), text);
}

export default {
  openFlashMessage,
  flashNotice,
  flashAlert
};