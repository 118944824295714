import ApplicationController from 'javascript/controllers/application_controller';
import Turbolinks from 'turbolinks';

export default class extends ApplicationController {
  success({ detail: [url] }) {
    Turbolinks.visit(url);
  }

  success_no_turbolinks({ detail: [url] }) {
    window.location = url;
  }
}