export default function replaceContent(element, nodes, copyContentClassName) {
  const fragment = (element && element.ownerDocument) ? element.ownerDocument.createDocumentFragment() : null;
  if (fragment) {

    const scripts = [];
    [].forEach.call(nodes, node => {
      if (typeof node.querySelectorAll !== 'function') {
        return;
      }

      [].forEach.call(node.querySelectorAll('script'), script => {
        scripts.push(script.innerText);
        script.parentElement.removeChild(script);
      });
      fragment.appendChild(node);
    });

    element.innerHTML = '';
    element.appendChild(fragment);

    if (copyContentClassName && element.childNodes[0]) {
      element.className += ' ' + element.childNodes[0].className;
    }

    scripts.forEach(script => {
      const scriptElement = document.createElement('script');
      scriptElement.innerText = script;
      document.head.appendChild(scriptElement);
    });
  }
}
