import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { addScriptTag, addLinkTag } from 'javascript/utils/utils';

export default class extends ApplicationController {
  static targets = [
    'form',
    'formErrors',
  ];

  connect() {
    this.groupId = this.data.get('groupId');
    window.chat_appid = this.data.get('appId');
    window.chat_auth = this.data.get('authKey');
    window.chat_id = this.data.get('userId');
    window.chat_avatar = this.data.get('userAvatar');
    window.chat_position = 'right';
  }

  @bound
  showLoadingIndicator() {
    this.element.classList.add('contents-loading');
  }

  @bound
  hideLoadingIndicator() {
    this.element.classList.remove('contents-loading');
  }

  @bound
  onBeforeFormSubmit() {
    this.hideFormErrors();
    this.showLoadingIndicator();
  }

  @bound
  onFormSubmitSuccess({ detail: [response, , xhr] }) {
    this.hideFormErrors();
    this.element.removeChild(this.formTarget);
    window.chat_name = response.nickname;
    window.chat_avatar = response.avatar;
    this.addDockedLayout(this.addEmbeddedLayout);
  }

  @bound
  onFormSubmitError({ detail: [response, , xhr] }) {
    this.hideLoadingIndicator();
    this.showFormErrors();
  }

  @bound
  showFormErrors() {
    this.formErrorsTarget.innerHTML = 'Nickname already taken';
  }

  @bound
  hideFormErrors() {
    this.formErrorsTarget.innerHTML = '';
  }

  @bound
  addDockedLayout(callback) {
    addScriptTag('//fast.cometondemand.net/' + window.chat_appid + 'x_xchat.js', () => {
      window.jqcc.cometchat.subscribe({
        gotProfileInfo: [
          () => {
            callback ? callback() : null;
          }
        ]
      });
    });

    addLinkTag('//fast.cometondemand.net/' + window.chat_appid + 'x_xchat.css');
  }

  @bound
  addEmbeddedLayout() {
    if (window.fcWidget) {
      window.fcWidget.hide();
    }

    addScriptTag('//fast.cometondemand.net/' + window.chat_appid + 'x_xchatx_xcorex_xembedcode.js', () => {
      var chatEmbedIframe = {};
      chatEmbedIframe.module = "synergy";
      chatEmbedIframe.src = 'https://' + window.chat_appid + '.cometondemand.net/cometchat_embedded.php?guid=' + this.groupId;
      if (typeof (addEmbedIframe) == "function") {
        addEmbedIframe(chatEmbedIframe);
      }
      this.hideLoadingIndicator();
    });
  }
}
