import bound from 'bound-decorator';
import {
  isPaymentPending,
  isPendingPaymentFeeChargeable,
  pendingPaymentMethod,
  setPaymentPending,
} from 'javascript/utils/payment_status';
import ApplicationController from 'javascript/controllers/application_controller';
import Rails from '@rails/ujs';

import fullScreenSpinner from 'javascript/utils/full-screen-spinner';
import visaCheckoutInit from 'javascript/payment/visa-checkout-init';
import { trackGa4Event } from '../../utils/utils';

const CALLPAY_ORIGIN_RGX = /^https:\/\/([^\.\/]*)\.callpay\.com(\/|$)/;

export default class extends ApplicationController {
  static targets = [
    'callpayButton',
    'vcoCallId',
    'vcoForm',
    'paymentOption',
    'paymentOptionInput',
    'paymentMethodField',
    'paymentPathField',
    'form',
    'formMethodField',
    'submitButton',
    'submitButtonText',
    'creditCardIdField',
    'ecommerceData',
    'pendingWarning',
  ];

  get abortPath() {
    return this.data.get('abortPath') || null;
  }

  set abortPath(p) {
    if (p) {
      this.data.set('abortPath', p);
    } else {
      this.data.delete('abortPath');
    }
  }

  get total() {
    return parseFloat(this.data.get('total'));
  }

  get currency() {
    return this.data.get('currency');
  }

  connect() {
    this.window.addEventListener('message', this.callpayClosed, false);
    this.document.body.addEventListener(
      'vco:payment.success',
      this.vcoPaymentSuccess,
      false,
    );
    this.document.body.addEventListener(
      'vco:payment.error',
      this.vcoPaymentError,
      false,
    );
    this.document.body.addEventListener(
      'vco:payment.cancel',
      this.vcoPaymentError,
      false,
    );
    this.window.addEventListener('pageshow', this.setPaymentOption);

    visaCheckoutInit(this.total, this.currency);
  }

  disconnect() {
    this.window.removeEventListener('message', this.callpayClosed);
    this.document.body.removeEventListener(
      'vco:payment.success',
      this.vcoPaymentSuccess,
    );
    this.document.body.removeEventListener(
      'vco:payment.error',
      this.vcoPaymentError,
    );
    this.document.body.removeEventListener(
      'vco:payment.cancel',
      this.vcoPaymentError,
    );
  }

  paymentOptionClicked(e) {
    const paymentOptionElement = e.target.closest('.payment-option');
    const radioInput = paymentOptionElement.querySelector('input.radio-input');
    if (radioInput) {
      radioInput.click();
    }
  }

  @bound
  setPaymentOption() {
    if (this.isDisabled()) {
      this.pendingWarningTarget.classList.remove('util-hidden');
    }

    this.paymentOptionInputTargets.forEach(async (radioInput) => {
      if (
        radioInput.checked ||
        pendingPaymentMethod(this.orderId) === radioInput.value
      ) {
        radioInput.checked = false;
        await radioInput.click();
      }

      if (
        this.isDisabled() &&
        (isPendingPaymentFeeChargeable(this.orderId) ||
          radioInput.dataset.isPaymentFeeChargeable === 'true')
      ) {
        radioInput.disabled = true;
      }
    });
  }

  paymentOptionChanged(e) {
    const paymentOptionElement = e.target.closest('.payment-option');

    const paymentMethod = e.target.value;
    const paymentPath = paymentOptionElement.dataset.paymentPath;
    const formMethod = paymentOptionElement.dataset.formMethod;
    const submitButtonText = paymentOptionElement.dataset.submitButtonText;
    const creditCardId = paymentOptionElement.dataset.cardId;
    const formDataAttributes = JSON.parse(
      paymentOptionElement.getAttribute('form_data_attributes'),
    );

    this.broadcastPaymentMethodFeeChangedEvent();
    this.setFormAttributes(paymentPath, formMethod, formDataAttributes);
    this.setCreditCardIdField(creditCardId);

    this.setPaymentMethodField(paymentMethod);
    this.submitButtonTextTarget.innerHTML = submitButtonText;
    this.submitButtonTarget.removeAttribute('disabled');

    trackGa4Event('select_payment_method', { payment_method: paymentMethod });
  }

  submitButtonClicked() {
    Rails.fire(this.formTarget, 'submit');
    if (this.paymentPending === 'true') {
      setPaymentPending(
        this.orderId,
        this.expiresAt,
        this.selectedPaymentInput().value,
        this.selectedPaymentInput().dataset.isPaymentFeeChargeable,
      );
    }
    this.trackAddPaymentInfo();
  }

  selectedPaymentInput() {
    return this.paymentOptionInputTargets.find((target) => {
      return target.checked;
    });
  }

  isDisabled() {
    return this.disabled === 'true' || isPaymentPending(this.orderId);
  }

  get orderId() {
    return this.data.get('orderId');
  }

  get expiresAt() {
    return this.data.get('expiresAt');
  }

  get disabled() {
    return this.data.get('disabled');
  }

  get paymentPending() {
    return this.data.get('payment-pending');
  }

  trackAddPaymentInfo() {
    if (this.hasEcommerceDataTarget) {
      const ecommerceData = JSON.parse(this.ecommerceDataTarget.value);
      ecommerceData.payment_method = this.paymentMethodFieldTarget.value;

      trackGa4Event('add_payment_info', ecommerceData);
    }
  }

  setPaymentMethodField(paymentMethod) {
    this.paymentMethodFieldTarget.value = paymentMethod;
  }
  setCreditCardIdField(cardId) {
    this.creditCardIdFieldTarget.value = cardId;
  }

  setFormAttributes(paymentPath, formMethod, formDataAttributes) {
    this.formTarget.action = paymentPath;
    this.formMethodFieldTarget.value = formMethod
      ? formMethod
      : this.defaultFormMethodForContext(this.purchaseContext);
    Object.assign(this.formTarget.dataset, formDataAttributes);
  }

  get purchaseContext() {
    return this.data.get('context');
  }

  get defaultFormMethods() {
    return {
      donations: 'patch',
      ticketPurchase: 'post',
      topUpPurchase: 'patch',
      transfers: 'patch',
    };
  }

  defaultFormMethodForContext(context) {
    return this.defaultFormMethods[context] || 'post';
  }

  callpayBefore() {
    this.abortPath = null;
    this.setCallpayButtonsDisabled(true);
    fullScreenSpinner.show();
  }

  callpaySuccess({ target, detail: [response] }) {
    if (this.isDisabled()) {
      Rails.fire(window, 'howler:disableProgress');
      Rails.fire(window, 'howler:disableRefundProtect');

      this.setPaymentOption();
    }

    const paymentType = target.dataset.paymentType;
    this.abortPath = response.abort_path;
    this.processCallpayPayment(
      response.payment_key,
      paymentType,
      response.display_check_box,
    );
  }

  callpayError({ detail: [response, error] }) {
    alert(`${error}: ${response.error}`);
    this.setCallpayButtonsDisabled(false);
    fullScreenSpinner.hide();
    this.abortPath = null;
  }

  @bound
  callpayClosed(event) {
    if (
      CALLPAY_ORIGIN_RGX.test(event.origin) &&
      typeof event.data === 'string'
    ) {
      eval(`(function(){${event.data}})()`); // eslint-disable-line no-eval
      if (/hideFrame/.test(event.data)) {
        fullScreenSpinner.hide();
        this.setCallpayButtonsDisabled(false);
        if (this.abortPath) {
          Rails.ajax({ url: this.abortPath, type: 'POST' });
          this.abortPath = null;
        }
      }
    }
  }

  broadcastPaymentMethodFeeChangedEvent(newValue) {
    Rails.fire(this.element, 'howler:cartUpdated', { newValue });
  }

  processCallpayPayment(paymentKey, paymentType, showCheckbox) {
    eftSec.checkout.settings.serviceUrl =
      'https://howler.callpay.com/rpp-transaction/create-from-key';
    eftSec.checkout.init({
      paymentKey,
      paymentType,
      cardOptions: {
        rememberCard: showCheckbox,
        rememberCardDefaultValue: 1,
      },
    });
  }

  setCallpayButtonsDisabled(d) {
    [].forEach.call(this.callpayButtonTargets, (t) => {
      t.disabled = d;
    });
  }

  vcoButtonClick() {
    if (top != window) {
      top.location = window.location;
    }

    fullScreenSpinner.show();
  }

  vcoBefore() {
    this.setCallpayButtonsDisabled(true);
  }

  vcoError({ detail: [response, error] }) {
    alert(`${error}: ${response.error}`);
    this.setCallpayButtonsDisabled(false);
  }

  @bound
  vcoPaymentSuccess({ detail: [payment] }) {
    this.vcoCallIdTarget.value = payment.callid;
    Rails.fire(this.vcoFormTarget, 'submit');
  }

  @bound
  vcoPaymentError() {
    fullScreenSpinner.hide();
  }
}
