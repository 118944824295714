export function storeObject({ type, key, object }) {
  const storageType = type === 'local' ? localStorage : sessionStorage;

  object.timestamp ||= new Date().getTime();
  storageType.setItem(key, JSON.stringify(object));
}

export function fetchObject({ type, key, expiryLimitInMs }) {
  const storageType = type === 'local' ? localStorage : sessionStorage;
  const item = JSON.parse(storageType.getItem(key));
  if (item && withinExpiryLimit(item.timestamp, expiryLimitInMs)) {
    return item
  } else {
    return null;
  }
}

function withinExpiryLimit(timestamp = 0, expiryLimitInMs) {
  if (!expiryLimitInMs) {
    return true;
  }

  const currentTime = new Date().getTime();

  return currentTime - timestamp < expiryLimitInMs;
}

