import ApplicationController from 'javascript/controllers/application_controller';
import { trackGa4Event } from 'javascript/utils/utils';
import bound from 'bound-decorator';

export default class extends ApplicationController {
  static targets = ['cart', 'checkoutButton', 'cartDetails'];

  @bound
  connect() {
    document.addEventListener(
      'howler:ticket-quantity-changed',
      this.ticketSelectionChanged
    );
  }

  get ecommerceData() {
    return this._ecommerceData
  }

  set ecommerceData(data) {
    this._ecommerceData = data
  }

  toggleCartDrawer(e) {
    this.cartTarget.classList.toggle('is-open');
    this.document.documentElement.classList.toggle('ticket-cart-is-expanded');

    if (this.cartTarget.classList.contains('is-open')) {
      this.trackCartEvent('view_cart');
    }
    e.preventDefault();
  }

  @bound
  ticketSelectionChanged(e) {
    const { totalQuantity, alreadyHasTickets, ecommerceData } = e.detail;
    this.ecommerceData = ecommerceData;

    if (alreadyHasTickets && totalQuantity <= 0) {
      this.cartDetailsTarget.classList.add('cart-drawer__details--hidden');
    } else {
      this.cartDetailsTarget.classList.remove('cart-drawer__details--hidden');
    }

    if (totalQuantity > 0 || alreadyHasTickets) {
      this.element.classList.remove('cart-drawer--hidden');
    } else {
      this.element.classList.add('cart-drawer--hidden');
    }
  }

  trackCartEvent(eventName) {
    try {
      trackGa4Event(eventName, this.ecommerceData);
    } catch (exception) {
      console.error(exception);
    }
  }
}

