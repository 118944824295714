import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  close() {
    const element = this.element;
    element.classList.add('closable--should-close');
    element.addEventListener('transitionend', function _onElementTransitionEnd() {
      element.removeEventListener('transitionend', _onElementTransitionEnd);
      element.style.display = 'none';
    });
  }
}