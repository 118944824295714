import ApplicationController from "javascript/controllers/application_controller";
import replaceContent from "javascript/utils/replace_content";
import { trackPageView } from 'javascript/utils/utils';

export default class extends ApplicationController {
  get modalContent() {
    return document.querySelector('.modal .modal__content');
  }

  get modal() {
    return document.querySelector('.modal');
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.modal, 'modal');
  }

  loadBegin(event) {
    event.stopPropagation();
    this.modalContent.innerHTML = '<div class="modal-content-loader"><div class="loading-indicator"><span></span><span></span><span></span></div></div>';
    this.modal.setAttribute('data-modal-should-show', 'true');
    this.modal.setAttribute('data-modal-fullscreen', this.data.get('fullscreen') || 'false');
    this.modal.setAttribute('data-modal-title', this.data.get('title') || '');
  }

  loadFail() {
    this.modalContent.innerHTML = '<div class="bg-card inset--medium"><h3>Something went wrong</h3></div>';
    this.modal.setAttribute('data-modal-should-show', 'true');
  }

  loadFinish({ detail: [response, , xhr] }) {
    if (response.body.childNodes[0]) {
      let responseClassName = response.body.childNodes[0].nodeName !== 'svg' ? response.body.childNodes[0].className : '';
      this.modalContent.className = 'modal__content ' + responseClassName;
      trackPageView(xhr);
      this.updateModalAttributesFromElementAttributes();
      replaceContent(this.modalContent, response.body.childNodes);
    }
    this.modal.setAttribute('data-modal-should-show', 'true');
  }

  onWebkitMouseForceWillBegin(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  onTouchForceChange(e) {
    if (e.changedTouches[0].force > 0.17) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  updateModalAttributesFromElementAttributes() {
    const modalAllowCloseOnBackdropClick = this.data.get('allow-close-on-backdrop-click');
    if (modalAllowCloseOnBackdropClick) {
      this.modal.setAttribute('data-modal-allow-close-on-backdrop-click', modalAllowCloseOnBackdropClick);
    }
    this.modal.setAttribute('data-modal-content-source', this.data.get('selector'));
  }

  onClick() {
    this.updateModalAttributesFromElementAttributes();
  }

  connect() {
    this.onClick = this.onClick.bind(this);
    this.element.addEventListener('webkitmouseforcewillbegin', this.onWebkitMouseForceWillBegin, false); // Safari on OSX
    this.element.addEventListener('touchforcechange', this.onTouchForceChange, false); // Safari on iOS
    this.element.addEventListener('click', this.onClick);
  }

  disconnect() {
    this.element.removeEventListener('webkitmouseforcewillbegin', this.onWebkitMouseForceWillBegin);
    this.element.removeEventListener('touchforcechange', this.onTouchForceChange);
    this.element.removeEventListener('click', this.onClick);
  }
}
