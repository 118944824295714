window.HowlerConfig = {
  algolia: {
    applicationId: '81KVTK2MOG',
    apiKey: 'f3302c81ffa6299d9091c8226f37a6aa'
  },
  google: {
    apiKey: 'AIzaSyCgXEshCzVRaRgNLRWt6tScuZAFRvv-y1M'
  },
  breakpoints: {
    small: 0,
    medium: 720,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1440
  },
  environment: 'production'
};
