import QuestionController from './question_controller';

export default class extends QuestionController {
  static targets = [
    ...QuestionController.targets,
    'option'
  ];

  get value() {
    return [].reduce.call(this.optionTargets, (answers, option) => {
      if (option.checked) return [...answers, option.value]
      return answers;
    }, []);
  }

  clear() {
    [].forEach.call(this.optionTargets, option => {
      option.checked = false;
    });
  }
}