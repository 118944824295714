import { Controller } from 'stimulus';

export default class ApplicationController extends Controller {
  get document() {
    return this.element.ownerDocument;
  }

  get window() {
    return this.document.defaultView;
  }
}
