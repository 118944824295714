function getSpinner() {
  return document.getElementById('full-screen-spinner');
}

function enableHTMLScroll() {
  document.documentElement.classList.remove('full-screen-spinner-is-open');
}

function disableHTMLScroll() {
  document.documentElement.classList.add('full-screen-spinner-is-open');
}

function show() {
  getSpinner().classList.add('is-open');
  disableHTMLScroll();
}

function hide() {
  getSpinner().classList.remove('is-open');
  enableHTMLScroll();
}

export default {
  show,
  hide,
  enableHTMLScroll,
  disableHTMLScroll
}