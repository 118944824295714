import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import { formatNumberToCurrency } from './../../utils/utils';

export default class extends ApplicationController {

  static targets = [
    'sliderInput',
    'textInput',
    'valueOutput',
    'percentageOutput',
    'sellingPriceOutput',
    'sellerFeeOutput',
    'youReceiveOutput',
  ];

  connect() {
    const originalPriceAsCents = parseInt(this.data.get('original-price-as-cents'), 10);
    const minimumPriceAsCents = parseInt(this.data.get('minimum-price-as-cents'), 10);
    const maximumPriceAsCents = parseInt(this.data.get('maximum-price-as-cents'), 10);
    const sellerFeePercent = Number(this.data.get('seller-fee-as-percent'));
    const minimumSellerFeeAsCents = Number(this.data.get('minimum-seller-fee-as-cents'), 10);

    this.currencySymbol = this.data.get('currency-symbol');
    this.currencySymbol = this.currencySymbol === 'undefined' ? 'R' : this.currencySymbol;
    this.sellerFeePercent = sellerFeePercent;
    this.minimumSellerFeeAsCents = minimumSellerFeeAsCents;
    this.originalPrice = originalPriceAsCents / 100;
    this.minimumPrice = minimumPriceAsCents / 100;
    this.maximumPrice = maximumPriceAsCents / 100;

    this.textInputTarget.setAttribute('min', this.minimumPrice);
    this.textInputTarget.setAttribute('max', this.maximumPrice);
    this.textInputTarget.setAttribute('step', '0.01');

    this.percentageOutputTarget.style.width = 'auto';

    this.addEventListeners();
    this.sliderInputTarget.value = 50;
    this.onSliderInputChange();
  }

  calculatePrice(percentValue) {
    const priceValue = this.minimumPrice + ((this.maximumPrice - this.minimumPrice) * (percentValue / 100));
    return priceValue;
  }

  calculatePricePercentage(priceValue) {
    return Math.max(((parseInt(priceValue, 10) - this.minimumPrice) / (this.maximumPrice - this.minimumPrice)) * 100, 0);
  }

  addEventListeners() {
    this.sliderInputTarget.addEventListener('input', this.onSliderInputChange);
    this.textInputTarget.addEventListener('change', this.onTextInputChange);
    this.textInputTarget.addEventListener('input', this.onTextInputInput);
    this.window.addEventListener('resize', this.onWindowResize);
  }

  removeEventListeners() {
    this.sliderInputTarget.removeEventListener('input', this.onSliderInputChange);
    this.textInputTarget.removeEventListener('change', this.onTextInputChange);
    this.textInputTarget.removeEventListener('input', this.onTextInputInput);
    this.window.removeEventListener('resize', this.onWindowResize);
  }

  @bound
  onSliderInputChange() {
    this.updateTextInputValue(this.sliderInputTarget.value);
  }

  @bound
  onTextInputChange() {
    if (this.textInputTarget.value > this.maximumPrice) {
      this.updateTextInputValue(100);
    } else if (this.textInputTarget.value < this.minimumPrice) {
      this.updateTextInputValue(0);
    }
    this.textInputTarget.value = Number(this.textInputTarget.value).toFixed(2);
    this.updateSliderInputValue(this.calculatePricePercentage(this.textInputTarget.value));
  }

  @bound
  onTextInputInput() {
    if (this.textInputTarget.value > this.maximumPrice) {
      this.updateTextInputValue(100);
    }
    this.updateSliderInputValue(this.calculatePricePercentage(this.textInputTarget.value));
  }

  @bound
  updateSliderInputValue(value) {
    this.sliderInputTarget.value = value;
    this.sliderInputTarget.dispatchEvent(new this.window.Event('change', { bubbles: true }));
    this.updateOutputs();
  }

  @bound
  updateTextInputValue(sliderValue) {
    const priceValue = this.calculatePrice(sliderValue);
    this.textInputTarget.value = priceValue.toFixed(2);
    this.updateOutputs();
  }

  @bound
  updateOutputs() {
    this.updatePercentageOutput();
    this.updateCalculationOutputs();
  }

  @bound
  updatePercentageOutput() {
    const sliderValue = Number(this.sliderInputTarget.value);
    this.percentageOutputTarget.innerHTML = Math.round((this.textInputTarget.value / this.originalPrice) * 100) + '%';
    this.percentageOutputTarget.style.left = (this.percentageOutputTarget.offsetWidth + ((this.sliderInputTarget.offsetWidth - this.percentageOutputTarget.offsetWidth) * (sliderValue / 100)) - this.percentageOutputTarget.offsetWidth) + 'px';
  }

  @bound
  updateCalculationOutputs() {
    const priceValueAsCents = Math.round(Number(this.textInputTarget.value) * 100);
    const sellerFeeAsCents = Math.round(priceValueAsCents * (this.sellerFeePercent / 100));
    const determinedSellerFeeAsCents = Math.max(sellerFeeAsCents, this.minimumSellerFeeAsCents);
    this.valueOutputTarget.value = Math.round(priceValueAsCents);
    this.sellingPriceOutputTarget.innerHTML = formatNumberToCurrency((priceValueAsCents / 100), this.currencySymbol);
    this.sellerFeeOutputTarget.innerHTML = formatNumberToCurrency((determinedSellerFeeAsCents / 100), this.currencySymbol);
    this.youReceiveOutputTarget.innerHTML = formatNumberToCurrency((priceValueAsCents - determinedSellerFeeAsCents) / 100, this.currencySymbol);
  }

  @bound
  onWindowResize() {
    this.updatePercentageOutput();
  }

  disconnect() {
    this.removeEventListeners();
  }

}
