import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  get paymentStatusUrl() {
    return this.data.get('statusUrl');
  }

  get successUrl() {
    return this.data.get('successUrl');
  }

  get failureUrl() {
    return this.data.get('failureUrl');
  }

  get pollingInterval() {
    return this.data.get('pollingInterval');
  }

  get maxAttempts() {
    return this.data.get('maxAttempts');
  }

  async connect() {
    try {
      const payment = await this.pollPayment(
        this.paymentStatusUrl,
        this.pollingInterval,
        this.maxAttempts
      );

      window.location.replace(this.paymentCompletedUrl(payment));
    } catch (error) {
      this.logError(error);
    }
  }

  async pollPayment(url, interval, maxAttempts, attempts = 0) {
    let payment;
    try {
      payment = await this.apiRequest(url);
    } catch (error) {
      this.logError(error);
    }

    attempts++;

    if (this.isPaymentComplete(payment)) {
      return payment;
    } else if (attempts >= maxAttempts) {
      throw new Error('Maximum payment fetch attempts exceeded');
    } else {
      return await this.requeue(url, interval, maxAttempts, attempts);
    }
  };

  async apiRequest(url) {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('API request was unsuccessful')
    }

    const result = await response.json();
    return result;
  }

  async requeue(url, interval, maxAttempts, attempts) {
    await this.delay(interval);
    return this.pollPayment(url, interval, maxAttempts, attempts);
  }

  delay(interval) {
    return new Promise(resolve => setTimeout(resolve, interval));
  }

  logError(error) {
    console.error(`Error while awaiting payment status: ${error.message}`);
  }

  paymentCompletedUrl(payment) {
    return this.isPaymentSuccessful(payment) ? this.successUrl : this.failureUrl;
  }

  isPaymentSuccessful(payment) {
    return payment['status'] === 'successful';
  }

  isPaymentComplete(payment) {
    return payment && ['successful', 'failed'].includes(payment['status']);
  }
}
