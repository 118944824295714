import Turbolinks from 'turbolinks';

(() => {
  !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');

  
    fbq('consent','revoke');
  
  fbq('init', '171367116772447');
  fbq('init', '204043020402329');

  
    function handleConsent(hasConsent) {
      fbq("consent", hasConsent ? "grant" : "revoke");
    }
    document.addEventListener("cookieyes_consent_update", (eventData) => {
      const data = eventData.detail;
      handleConsent(data.accepted && data.accepted.includes("analytics") && data.accepted.includes("advertising"));
    });
    document.addEventListener("cookieyes_banner_load", (eventData) => {
      const data = eventData.detail;
      handleConsent(data.categories && data.categories.analytics && data.categories.advertising);
    });
  

  function trackPageView() {
    fbq('track', 'PageView');
  }

  if (Turbolinks && Turbolinks.supported) {
    document.addEventListener('turbolinks:load', function(event) {
      trackPageView();
    }, true);
  }
})();
