import { getCookie } from 'javascript/utils/cookies';

function setOauthCallback(callback) {
  window.HowlerOauth || (window.HowlerOauth = {});
  window.HowlerOauth.callback = callback || (url => window.location.href = url);
}

export function openPopup(url = '', title = '', opts = {}) {
  const options = Object.assign({
    toolbar: 'no',
    scrollbar: 'no',
    resizable: 'no',
    location: 'no',
    menubar: 'no',
    status: 'no',
    titlebar: 'no',
    width: 1024,
    height: 635
  }, opts);
  options.left = (window.screen.availWidth - options.width) / 2;
  options.top = (window.screen.availHeight - options.height) / 2;
  window.open(url, title, Object.keys(options).map(k => `${k}=${options[k]}`).join(','));
}

function getNormalisedUri() {
  let normalisedUri = decodeURIComponent(getCookie('normalised_uri') || '');

  if (normalisedUri.endsWith('/')) {
    normalisedUri = normalisedUri.substring(0, normalisedUri.length - 1)
  }

  return normalisedUri;
}

function loginWith(path, title, params, callback) {
  let normalisedUri = getNormalisedUri();

  setOauthCallback(callback);
  openPopup(`${normalisedUri}/${path}`, `Howler | ${title}`, params);
}

export function loginWithFacebook(callback) {
  loginWith('auth/facebook', 'Log in with Facebook', { width: 1024, height: 635 }, callback);
}

export function loginWithGoogle(callback) {
  loginWith('auth/google_oauth2', 'Log in with Google', { width: 600, height: 600 }, callback);
}
