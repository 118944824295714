import ApplicationController from 'javascript/controllers/application_controller';
import bound from 'bound-decorator';

export default class extends ApplicationController {
  static targets = ['assignmentSelectorButton', 'ticket'];

  @bound
  connect() {
    document.addEventListener('lockAssignments', this.lockAssignments);
    document.addEventListener('unlockAssignments', this.unlockAssignments);
  }

  @bound
  disconnect() {
    document.removeEventListener('lockAssignments', this.lockAssignments);
    document.removeEventListener('unlockAssignments', this.unlockAssignments);
  }

  assign_all_checkbox_changed() {
    this.element.closest('form').submit();
  }

  @bound
  lockAssignments(e) {
    const { activeAssignmentId } = e.detail;

    this.assignmentSelectorButtonTargets.forEach((button) =>
      button.classList.add('hidden')
    );
    this.ticketTargets.forEach((ticket) => {
      if (ticket.dataset.ticketId != activeAssignmentId) {
        ticket.classList.add('locked');
      }
    });
  }

  @bound
  unlockAssignments() {
    this.assignmentSelectorButtonTargets.forEach((button) =>
      button.classList.remove('hidden')
    );

    this.ticketTargets.forEach((ticket) => {
      ticket.classList.remove('locked');
    });
  }
}

