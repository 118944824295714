import ApplicationController from 'javascript/controllers/application_controller';
import { variableReplace } from 'javascript/utils/utils';
import bound from 'bound-decorator';

export default class extends ApplicationController {
  static targets = [
    'selectedDate',
    'selectedTime',
    'timeButton',
    'dateButton',
    'selectDatePrompt',
    'submitButton',
    'timesContainer',
    'timeButtonTemplate',
    'timesCarouselButtons'
  ];

  @bound
  connect() {
    this.dateTimes = this.dateTimeData;
    this.currency = this.data.get('currency');
    this.setLocations();
    this.toggleCarouselButtons('hide');
  }

  setLocations() {
    if ($('#transport_details_departure_location') && $('#transport_details_arrival_location')) {
      console.log($('#transport_details_departure_location'));
      if (this.source === 'departure' ) {
        this.departureLocation = $('#transport_details_departure_location').val()
        this.arrivalLocation = $('#transport_details_arrival_location').val();
      } else {
        this.departureLocation = $('#transport_details_arrival_location').val();
        this.arrivalLocation = $('#transport_details_departure_location').val()
      }
    }

    if (this.source === 'departure' ) {
      this.departureLocation ||= this.data.get('departureLocation');
      this.arrivalLocation ||= this.data.get('arrivalLocation');
    } else {
      this.departureLocation ||= this.data.get('arrivalLocation');
      this.arrivalLocation ||= this.data.get('departureLocation');
    }

    this.showDateButtons();

    if (this.date) {
      this.showTimesForDate(this.date.value);
    }
  }

  get dateTimeData() {
    return JSON.parse(this.data.get('dates'));
  }

  get selectedButtonCssClass() {
    return (
      this.data.get('selected-button-css-class') || 'date-time-selection__label--selected'
    );
  }

  get currencyFormatter() {
    return (this._currencyFormatter ||= new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency: this.currency,
    }));
  }

  get source() {
    return this.data.get('source');
  }

  get time() {
    return this._time;
  }

  set time(time) {
    this._time = time;
  }

  get price() {
    return this._price;
  }

  set price(price) {
    this._price = price;
  }

  get date() {
    return this._date;
  }

  set date(date) {
    this._date = date;
  }

  onDateChanged(e) {
    const { day, month } = e.target.dataset;
    const date = e.target.value;
    this.date = { label: `${day} ${month}`, value: date };

    this.setButtonAsSelected(date, 'date');
    this.selectedDateTarget.innerText = `${day} ${month}`;


    if (this.hasSelectDatePromptTarget) {
      this.selectDatePromptTarget.remove();
    }

    this.selectedTimeTarget.innerText = '';
    this.showTimesForDate(date);
    this.submitButtonTarget.setAttribute('disabled', true);
  }

  onSubmit(e) {
    e.preventDefault();

    const submittedEvent = new CustomEvent('dateTimeSubmitted', {
      bubbles: true,
      detail: {
        date: this.date,
        time: this.time,
        source: this.source,
      },
    });

    this.element.dispatchEvent(submittedEvent);
  }

  setButtonAsSelected(selectedValue, buttonType) {
    const buttons = buttonType === 'date' ? this.dateButtonTargets : this.timeButtonTargets;

    buttons.forEach((button) =>
      button.closest('label').classList.remove(this.selectedButtonCssClass)
    );

    buttons
      .find((dateButton) => dateButton.value === selectedValue)
      .closest('label')
      .classList.add(this.selectedButtonCssClass);
  }

  timeButtonsForDate(date) {
    return this.timeButtonTargets.filter(
      (timeButton) => timeButton.dataset.date === date
    );
  }

  showTimesForDate(date) {
    const times = this.dateTimes[date].sort((t1, t2) => {
      if (t1.time < t2.time) {
        return -1;
      }
      if (t1.time > t2.time) {
        return 1;
      }

      return 0;
    });

    this.timesContainerTarget.innerHTML = '';
    times.forEach((time) => {
      if (time.departure_location === this.departureLocation && time.arrival_location === this.arrivalLocation) {
        const timeButtonHTML = this.createTimeButtonHTML(
          date,
          time
        );
        this.timesContainerTarget.innerHTML += timeButtonHTML;
      }
    });
    this.toggleCarouselButtons('show');
  }

  toggleCarouselButtons(state) {
    const operation = state === 'hide' ? 'add' : 'remove';

    this.timesCarouselButtonsTarget.classList[operation]('hidden')
  }

  onTimeChanged(e) {
    this.time = this.dateTimes[this.date.value].find((time) => time.time === e.target.value && time.departure_location === this.departureLocation && time.arrival_location === this.arrivalLocation);

    this.selectedTimeTarget.innerText = this.time.time;
    this.setButtonAsSelected(this.time.time, 'time');
    this.submitButtonTarget.removeAttribute('disabled');
  }

  createTimeButtonHTML(date, time) {
    const template = this.timeButtonTemplateTarget;
    const html = template.content.cloneNode(true);

    const inputElement = html.querySelector('input');
    const inputName = inputElement.getAttribute('name');
    inputElement.setAttribute('id', `${inputName}_${time.time}`);

    const serializer = new XMLSerializer();
    const htmlString = serializer.serializeToString(html);
    const formattedPrice = this.currencyFormatter.format(Number(time.cost) + Number(time.fee))

    return variableReplace(htmlString, { date: date, time: time.time, formatted_price: formattedPrice });
  }

  showDateButtons() {
    this.dateButtonTargets.forEach((b) => {
      if (this.dateTimes[b.defaultValue].some((time) => time.departure_location === this.departureLocation && time.arrival_location === this.arrivalLocation)) {
        $(b).parent().show();
      } else {
        $(b).parent().hide();
      }
    });
  }
}

