import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller"
import Rails from '@rails/ujs';

export default class extends ApplicationController {

  static targets = [
    'contentContainer',
    'titleText',
    'form',
    'saveButton',
  ];

  bodyClassNameForModalIsShowing = 'modal-is-showing';

  get shouldShow() {
    return false || (this.data.get('shouldShow') === 'true');
  }

  set shouldShow(value) {
    return this.data.set('shouldShow', value);
  }

  get allowCloseOnBackdropClick() {
    return this.data.get('allowCloseOnBackdropClick') === 'false' ? false : true;
  }

  set allowCloseOnBackdropClick(value) {
    return this.data.set('allowCloseOnBackdropClick', value);
  }

  get contentSource() {
    return '' || this.data.get('contentSource');
  }

  resetAttributesToDefaults() {
    this.unsetLoading();
    this.titleTarget = '';
    this.data.set('fullscreen', false);
  }

  updateBodyDOMBasedOnCurrentState() {
    const bodyElement = this.document.body;
    const scrollYAttributeName = 'data-modal-last-scroll-y';

    if (this.shouldShow) {
      if (this.window.scrollY !== 0) {
        bodyElement.setAttribute(scrollYAttributeName, this.window.scrollY);
      }
      bodyElement.classList.add(this.bodyClassNameForModalIsShowing);
    } else {
      bodyElement.classList.remove(this.bodyClassNameForModalIsShowing);
      this.window.setTimeout(() => {
        const scrollY = bodyElement.getAttribute(scrollYAttributeName);
        if (scrollY) {
          this.document.documentElement.scrollTop = bodyElement.scrollTop = scrollY;
        }
        bodyElement.setAttribute(scrollYAttributeName, '');
      }, 0);
    }
  }

  setLoading() {
    this.element.classList.add('contents-loading');
  }

  unsetLoading() {
    this.element.classList.remove('contents-loading');
  }

  show() {
    this.shouldShow = true;
  }

  @bound
  close() {
    this.removeContent();
    this.shouldShow = false;
    this.allowCloseOnBackdropClick = true;
  }

  removeContent() {
    while (this.contentContainerTarget.firstChild) {
      this.contentContainerTarget.removeChild(this.contentContainerTarget.firstChild);
    }
  }

  updateContent() {
    const template = this.document.querySelector(this.contentSource);

    if (template) {
      const templateAllowCloseOnBackdropClick = template.getAttribute('data-modal-allow-close-on-backdrop-click');

      if (templateAllowCloseOnBackdropClick) {
        this.allowCloseOnBackdropClick = templateAllowCloseOnBackdropClick === 'false' ? false : true;
      }

      this.contentContainerTarget.innerHTML = template.innerHTML;
      this.contentContainerTarget.className = 'modal__content ' + template.className;
      this.scrollToTop();

      this.show();
    }
  }

  onBackdropClick() {
    if (this.allowCloseOnBackdropClick) {
      this.close();
    }
  }

  onFormSubmit() {
    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.disabled = true;
      this.saveButtonTarget.classList.add('button--loading');
    }
  }

  onSubmitButtonClick() {
    this.onFormSubmit();

    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit');
    }
  }

  onShouldShowChanged() {
    this.updateBodyDOMBasedOnCurrentState();

    if (this.shouldShow) {
      if (this.hasTitleTextTarget) {
        this.titleTextTarget.innerHTML = this.data.get('title');
      }
      if (this.data.get('fullscreen') === 'true') {
        this.allowCloseOnBackdropClick = false;
      }
    } else {
      this.window.setTimeout(() => {
        this.resetAttributesToDefaults();
      }, 200);
    }
  }

  @bound
  onCloseButtonClick() {
    this.close();
  }

  scrollToTop() {
    this.contentContainerTarget.scrollTop = 0
  }

  connect() {
    this.updateBodyDOMBasedOnCurrentState();

    this.attributeObserver = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-modal-content-source')) {
        this.updateContent();
      }

      if (mutationList.find(x => x.attributeName === 'data-modal-should-show')) {
        this.onShouldShowChanged();
      }
    });

    this.attributeObserver.observe(this.element, { attributes: true });
    this.window.addEventListener('orientationchange', this.close);
    this.document.addEventListener('turbolinks:before-cache', this.close);
  }

  disconnect() {
    this.attributeObserver.disconnect();
    this.window.removeEventListener('orientationchange', this.close);
    this.document.removeEventListener('turbolinks:before-cache', this.close);
  }
}
