import ApplicationController from "javascript/controllers/application_controller";
import replaceContent from "javascript/utils/replace_content";
import { trackPageView } from 'javascript/utils/utils';

function isJavascript(xhr) {
  return getContentType(xhr).indexOf('javascript') !== -1;
}

function getContentType(xhr) {
  return xhr.getResponseHeader('Content-Type');
}

export default class extends ApplicationController {
  static targets = ['container']

  before() {
    this.containerTarget.classList.add('contents-loading');
  }

  success({ detail: [response, , xhr] }) {
    if (!isJavascript(xhr)) {
      trackPageView(xhr);
      replaceContent(this.containerTarget, response.body.childNodes);
      this.containerTarget.classList.remove('contents-loading');
    }
  }

  error({ detail: [response, error] }) {
    this.window.alert(`${error}: ${response.error}`);
    this.containerTarget.classList.remove('contents-loading');
  }
}
