import ApplicationController from "../application_controller";

export default class extends ApplicationController {

  static targets = [
    'item',
    'content',
  ];

  expandItem(item, shouldAnimate) {
    const itemContent = item.querySelector(`[data-target*="${this.identifier}.content"]`);
    const itemContentHeight = itemContent.scrollHeight;
    itemContent.style.height = itemContentHeight + 'px';

    if (this.data.get('multiExpand') !== 'true') {
      Array.prototype.forEach.call(this.itemTargets, (itemTarget) => {
        if (itemTarget.getAttribute('aria-expanded') === 'true' && itemTarget !== item) {
          this.collapseItem(itemTarget, true);
        }
      });
    }

    if (shouldAnimate) {
      itemContent.addEventListener('transitionend', function _onItemContentTransitionEnd() {
        itemContent.removeEventListener('transitionend', _onItemContentTransitionEnd);
        itemContent.style.height = null;
      });
    } else {
      itemContent.style.height = null;
    }

    item.setAttribute('aria-expanded', 'true');
  }

  collapseItem(item, shouldAnimate) {
    const itemContent = item.querySelector(`[data-target*="${this.identifier}.content"]`);
    const itemContentHeight = itemContent.scrollHeight;
    const itemContentOriginalTransition = itemContent.style.transition;

    if (shouldAnimate) {
      itemContent.style.transition = '';

      requestAnimationFrame(() => {
        itemContent.style.height = itemContentHeight + 'px';
        itemContent.style.transition = itemContentOriginalTransition;

        requestAnimationFrame(() => {
          itemContent.style.height = '0px';
        });
      });
    } else {
      itemContent.style.height = '0px';
    }

    item.setAttribute('aria-expanded', 'false');
  }

  toggleItem(e) {
    const item = e.target.closest(`[data-target*="${this.identifier}.item"]`);
    if (item.getAttribute('aria-expanded') === 'true') {
      if (!this.elementsRemainOpen) {
        this.collapseItem(item, true);
      }
    } else {
      this.expandItem(item, true);
    }
  }

  get elementsRemainOpen() {
    return this.data.get('elementsRemainOpen') === 'true';
  }

  connect() {
    Array.prototype.forEach.call(this.itemTargets, (item) => {
      if (item.getAttribute('aria-expanded') === 'true') {
        this.expandItem(item, false);
      } else {
        this.collapseItem(item, false);
      }
    });
  }
}
