import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['error', 'preference', 'radioGroup'];

  @bound
  submitError({ detail: [response] }) {
    if (!response.errors.sticker) {
      this.clearErrors();
      return;
    }

    ['Email', 'SMS'].forEach(marketingType => {
      let preference = [].find.call(this.preferenceTargets, t => t.dataset.marketingType === marketingType);

      let key = `allow_${marketingType.toLowerCase()}`;
      if (key in response.errors.sticker) {
        this.showError(preference, 'is required');
      } else {
        this.hideError(preference);
      }
    });
  }

  @bound
  clearErrors() {
    [].forEach.call(this.preferenceTargets, this.hideError);
  }

  @bound
  onChangepreference({ currentTarget }) {
    let preference = currentTarget.closest(`[data-target="${this.identifier}.preference"]`);
    this.hideError(preference);
  }

  @bound
  showError(preference, errorMessage) {
    let radioGroup = this.getRadioGroup(preference);
    radioGroup.classList.add('has-error');

    let errorElement = this.getErrorElement(preference);
    errorElement.textContent = errorMessage;
    errorElement.classList.remove('util-hidden');
  }

  @bound
  hideError(preference) {
    let radioGroup = this.getRadioGroup(preference);
    radioGroup.classList.remove('has-error');

    let errorElement = this.getErrorElement(preference);
    errorElement.classList.add('util-hidden');
  }

  @bound
  getRadioGroup(preference) {
    return preference.querySelector(`[data-target="${this.identifier}.radioGroup"]`);
  }

  @bound
  getErrorElement(preference) {
    return preference.querySelector(`[data-target="${this.identifier}.error"]`);
  }
}
