import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'paymentSelection',
    'payAtInfo',
  ];

  showPayAtInfo() {
    this.payAtInfoTarget.classList.remove('util-hidden');
    this.paymentSelectionTarget.classList.add('util-hidden');
  }

  hidePayAtInfo() {
    this.payAtInfoTarget.classList.add('util-hidden');
    this.paymentSelectionTarget.classList.remove('util-hidden');
  }
}
