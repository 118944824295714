import FormErrorDisplayController from "javascript/controllers/consumer_portal/form_error_display_controller";

export default class extends FormErrorDisplayController {
  static targets = [
    'error',
    'ptNifNumberRequired',
    'ptNifNumber'
  ];

  beforeSubmit() {
    if (this.ptNifNumberRequiredTarget.value === 'false') {
      this.ptNifNumberTarget.value = null;
    }
  }

  errorResponseObject(response) {
    return response.errors.cashless_tag_pairing;
  }
}
