import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  static targets = [
    "value",
    "content"
  ];

  removeAllChildDOMNodesFrom(context) {
    while (context.firstChild) {
      context.removeChild(context.firstChild);
    }
  }

  addStepDOMNodesTo(steps, value, shouldFillActiveStepsToValue) {
    steps.map((item, i) => {
      if (shouldFillActiveStepsToValue) {
        if (i < value) {
          item = "complete";
        }
      }
      if (i === value - 1) {
        item = "active";
      }
      return item;
    }).map((item) => {
      let el = document.createElement("i");
      el.setAttribute("data-complete", item === "complete");
      el.setAttribute("data-active", item === "active");
      return this.contentTarget.appendChild(el);
    });
  }

  getAttribute(name) {
    return this.element.getAttribute(name) || this.data.get(name);
  }

  fillArray(length, value) {
    let list = [];

    for (let i = 0; i < length; i++) {
      list[i] = value;
    }

    return list;
  }

  updateContent() {
    const value = parseInt(this.getAttribute("value"), 10) || 0;
    const max = parseInt(this.getAttribute("max"), 10) || 0;
    const steps = this.fillArray(max, ' ');

    this.removeAllChildDOMNodesFrom(this.contentTarget);

    switch (this.getAttribute("type")) {
      case "step":
        this.addStepDOMNodesTo(steps, value, false);
        break;
      case "step-fill":
        this.addStepDOMNodesTo(steps, value, true);
        break;
      default:
        this.valueTarget.style = 'width:' + ((value / max) * 100) + '%';
    }

  }

  connect() {
    this.updateContent();

    this.attributeObserver = new MutationObserver(() => {
      this.updateContent();
    });

    this.attributeObserver.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.attributeObserver.disconnect();
  }

}
