import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['container', 'scroll'];

  get shouldComputeX() {
    return this.data.has('direction') && this.data.get('direction').indexOf('x') !== -1;
  }

  get shouldComputeY() {
    return this.data.has('direction') && this.data.get('direction').indexOf('y') !== -1;
  }

  connect() {
    this.recalculate();
  }

  recalculate() {
    if (this.shouldComputeX) {
      this.recalculateImpl(
        this.scrollTarget.scrollLeft,
        this.scrollTarget.scrollWidth,
        this.scrollTarget.clientWidth,
        'x'
      );
    }
    if (this.shouldComputeY) {
      this.recalculateImpl(
        this.scrollTarget.scrollTop,
        this.scrollTarget.scrollHeight,
        this.scrollTarget.clientHeight,
        'y'
      );
    }
  }

  recalculateImpl(scrollValue, scrollSize, clientSize, direction) {
    const startClass = `util-scroll-start-${direction}`;
    if (scrollValue <= 0) {
      this.containerTarget.classList.add(startClass);
    } else {
      this.containerTarget.classList.remove(startClass);
    }

    const otherScrollValue = scrollSize - scrollValue - clientSize;
    const endClass = `util-scroll-end-${direction}`;
    if (otherScrollValue <= 0) {
      this.containerTarget.classList.add(endClass);
    } else {
      this.containerTarget.classList.remove(endClass);
    }
  }
}