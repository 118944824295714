import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'bankAccountDetails',
    'countrySelected',
    'bankNameSelected',
    'bankBranchCodeSelected',
  ];

  addEmptyOption(selectDOMNode) {
    let opt = this.window.document.createElement("option");
    opt.value = '';
    opt.innerHTML = '';
    selectDOMNode.appendChild(opt);
  }

  get country() {
    return this.data.get('country');
  }

  onBankNameChange() {
    const selectedCountryName = this.country;
    this.bankBranchCodeSelectedTarget.innerHTML = "";
    let opt;

    if (this.bankDetails[selectedCountryName]) {
      const selectedBankName = this.bankNameSelectedTarget.options[this.bankNameSelectedTarget.selectedIndex].text;
      const selectedBankNameBranches = this.bankDetails[selectedCountryName][selectedBankName];

      if (selectedBankName) {
        if (Object.keys(selectedBankNameBranches).length > 0) {
          this.addEmptyOption(this.bankBranchCodeSelectedTarget);
        }
      }

      for (let key in selectedBankNameBranches) {
        opt = this.window.document.createElement("option");

        opt.value = selectedBankNameBranches[key];
        opt.innerHTML = key;

        this.bankBranchCodeSelectedTarget.appendChild(opt);
      }
    }
  }

  connect() {
    this.bankDetails = JSON.parse(this.bankAccountDetailsTarget.innerHTML);
  }
}
