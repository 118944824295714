import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";


export default class extends ApplicationController {

  @bound
  onChanged() {
    const min = parseInt(this.element.getAttribute('min'), 10) || 0;
    const max = parseInt(this.element.getAttribute('max'), 10) || 100;
    const value = parseInt(this.element.value, 10);
    const percent = Math.round(((value - min) / (max - min)) * 100);
    this.element.setAttribute('data-percent', percent);
  }

  connect() {
    this.element.addEventListener('change', this.onChanged);
    this.element.addEventListener('input', this.onChanged);
    this.onChanged();
  }

}
