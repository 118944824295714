import bound from 'bound-decorator';
import ApplicationController from 'javascript/controllers/application_controller';
import { formatNumberToCurrency } from 'javascript/utils/utils';
import { isPaymentPending } from 'javascript/utils/payment_status';
import Rails from '@rails/ujs';
import { storeObject, fetchObject } from 'javascript/utils/storage';

export default class extends ApplicationController {
  static targets = [
    'containerElement',
    'item',
    'customItem',
    'input',
    'customValue',
    'selectedAmountLabel',
  ];

  expandItem(item) {
    this.containerElement.classList.add('has-selection');
    item.classList.add('is-selected');
  }

  get containerElement() {
    if (this.hasContainerElementTarget) {
      return this.containerElementTarget;
    } else {
      return this.element;
    }
  }

  collapseItems() {
    this.containerElement.classList.remove('has-selection');
    this.itemTargets.forEach((item) => {
      item.classList.remove('is-selected');
    });
  }

  get currencySymbol() {
    return this.data.get('currency_symbol') || 'R';
  }

  get eventSlug() {
    return this.data.get('eventSlug');
  }

  get orderId() {
    return this.data.get('orderId');
  }

  get persistSelections() {
    return this.data.get('persistSelections') === 'true';
  }

  get source() {
    return this.data.get('source') || 'cashlessTopup';
  }

  get disabled() {
    return this.data.get('disabled') || 'false';
  }

  @bound
  isDisabled() {
    return this.disabled === 'true' || isPaymentPending(this.orderId);
  }

  updateSelectedValue(value, init = false) {
    const oldValue = this.data.get('value');
    this.data.set('value', value);
    this.inputTarget.value = value;

    if (this.hasSelectedAmountLabelTarget) {
      this.selectedAmountLabelTarget.innerText = formatNumberToCurrency(
        Number(value),
      );
    }

    if (this.cartTotalSelector) {
      document.querySelector(this.cartTotalSelector).innerHTML =
        formatNumberToCurrency(
          Number(this.cartTotal) + Number(value),
          this.currencySymbol,
        );
    }

    if (this.cartGrandTotalSelector) {
      document.querySelector(this.cartGrandTotalSelector).innerHTML =
        formatNumberToCurrency(
          Number(value) + Number(this.cartExistingValue),
          this.currencySymbol,
        );
    }

    setTimeout(() => {
      Rails.fire(this.element, 'howler:topup-selection.change', [value]);
      Rails.fire(this.element, 'howler:cartUpdated', {
        newValue: value,
        oldValue: oldValue,
        source: this.source,
      });
    }, 0);

    if (this.persistSelections && !init) {
      this.persistSelection(value);
    }
  }

  onPredefinedValueClick(e) {
    this.updateSelectedValue(e.currentTarget.dataset.topupAmount);
    this.expandItem(e.currentTarget);
  }

  onCustomValueClick(e) {
    const customValue = this.getSanitizedCustomValue(this.initialValue);
    this.expandItem(e.currentTarget);
    this.updateCustomValueInputField(customValue);
    this.customValueTarget.focus();
    this.updateSelectedValue(customValue);
  }

  onItemCloseClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.cancelSelection();
  }

  cancelSelection(e) {
    if (!this.isDisabled()) {
      this.updateSelectedValue('');
      this.collapseItems();
    }
  }

  getSanitizedCustomValue(value) {
    return String(value).replace(/\D/g, '').replace(/\b0+/g, ''); // Remove all non-digit characters & remove leading zeros
  }

  updateCustomValueInputField(value) {
    this.customValueTarget.value = value;
  }

  onCustomValueInput(e) {
    let value = e.currentTarget.value;
    let sanitizedValue = this.getSanitizedCustomValue(value);

    if (
      this.maximumAmount > -1 &&
      parseInt(sanitizedValue, 10) > this.maximumAmount
    ) {
      sanitizedValue = this.maximumAmount;
    }

    if (value !== sanitizedValue) {
      this.updateCustomValueInputField(sanitizedValue);
    }

    this.updateSelectedValue(sanitizedValue);
  }

  persistSelection(value) {
    const item = {
      eventSlug: this.eventSlug,
      orderId: this.orderId,
      value: value,
    };

    storeObject({
      type: 'session',
      key: 'topupSelectionSelectedValue',
      object: item,
    });
  }

  persistedSelection() {
    const storedObject = fetchObject({
      type: 'session',
      key: 'topupSelectionSelectedValue',
    });

    if (
      this.persistSelections &&
      storedObject &&
      storedObject.orderId == this.orderId
    ) {
      return storedObject.value;
    } else {
      return null;
    }
  }

  init() {
    const attributeMax = this.data.get('max');
    this.cartTotalSelector = this.data.get('cart-total-selector');
    this.cartTotal = this.cartTotalSelector
      ? parseFloat(
          document
            .querySelector(this.cartTotalSelector)
            .innerHTML.replace(/[^\d.-]/g, ''),
          10,
        )
      : 0;
    this.initialValue =
      parseInt(this.data.get('value'), 10) || this.persistedSelection();
    this.cartExistingValue =
      parseInt(this.data.get('cart-existing-value'), 10) || '';
    this.cartGrandTotalSelector = this.data.get('cart-grand-total-selector');
    this.maximumAmount = -1;

    if (attributeMax) {
      this.maximumAmount = parseInt(attributeMax, 10);
    }
  }

  connect() {
    this.init();

    if (this.initialValue) {
      const initiallySelectedElement = this.element.querySelector(
        `.topup-selection__list-item[data-topup-amount="${this.initialValue}"]`,
      );

      if (initiallySelectedElement) {
        this.expandItem(initiallySelectedElement);
      } else {
        this.expandItem(this.customItemTarget);
        this.customValueTarget.value = this.initialValue;
      }

      this.updateSelectedValue(this.initialValue, true);
    }

    window.addEventListener('pageshow', () => {
      if (this.isDisabled()) {
        this.customValueTarget.setAttribute('disabled', true);
      }
    });
  }
}
