import ApplicationController from 'javascript/controllers/application_controller';
import { formatNumberToCurrency, childTarget, closestTarget } from './../../utils/utils';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = [
    'containerElement',
    'item',
    'customItem',
    'input',
    'customValue',
    'selectedAmountLabel',
    'topupButton',
  ];

  expandItem(item) {
    this.containerElement.classList.add('has-selection');
    item.classList.add('is-selected');
  }

  get containerElement() {
    if (this.hasContainerElementTarget) {
      return this.containerElementTarget;
    } else {
      return this.element;
    }
  }

  collapseItems() {
    this.containerElement.classList.remove('has-selection');
    this.itemTargets.forEach((item) => {
      item.classList.remove('is-selected');
    });
  }

  get currencySymbol() {
    return this.data.get('currency_symbol') || 'R';
  }

  get itemIndex() {
    return this.data.get('itemIndex');
  }

  updateSelectedValue(value, fireUpdateEvent = true) {
    const oldValue = this.data.get('value');
    this.data.set('value', value);
    this.inputTarget.value = value;

    document
      .querySelectorAll('input[name="ticket_order_item[topup]"]')
      .forEach((input) => input.setAttribute('disabled', 'disabled'));
    this.inputTarget.removeAttribute('disabled');

    if (fireUpdateEvent) {
      Rails.fire(this.element, 'howler:topup-selection.change', {
        itemIndex: this.itemIndex,
      });
      setTimeout(() =>
        document
          .querySelectorAll('input[name="ticket_order_item[topup]"]')
          .forEach((input) => input.setAttribute('disabled', 'disabled'))
      );
    }
  }

  onPredefinedValueClick(e) {
    this.updateSelectedValue(e.currentTarget.dataset.topupAmount);
    const itemElement = e.target.closest('li');

    const itemContentElement = childTarget(this, 'itemContent', itemElement);
    const itemLoaderElement = childTarget(this, 'itemLoader', itemElement);

    itemContentElement.classList.add('hidden');
    itemLoaderElement.classList.remove('hidden');
  }

  onCustomValueClick(e) {
    const customValue = this.getSanitizedCustomValue(this.initialValue);
    this.expandItem(e.currentTarget);
    this.updateCustomValueInputField(customValue);
    this.customValueTarget.focus();
    this.updateSelectedValue(customValue, false);
    this.topupButtonTarget.classList.remove('hidden');
  }

  onItemCloseClick(e) {
    e.target.classList.add('hidden');

    const loaderElement = childTarget(this, 'closeLoader', e.target.parentElement);
    loaderElement.classList.remove('hidden');
    e.preventDefault();
    e.stopPropagation();

    this.updateSelectedValue('');
  }

  onTopupButtonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateSelectedValue(this.inputTarget.value);
  }

  getSanitizedCustomValue(value) {
    return String(value).replace(/\D/g, '').replace(/\b0+/g, ''); // Remove all non-digit characters & remove leading zeros
  }

  updateCustomValueInputField(value) {
    this.customValueTarget.value = value;
  }

  onCustomValueInput(e) {
    let value = e.currentTarget.value;
    let sanitizedValue = this.getSanitizedCustomValue(value);

    if (this.maximumAmount > -1 && parseInt(sanitizedValue, 10) > this.maximumAmount) {
      sanitizedValue = this.maximumAmount;
    }

    if (value !== sanitizedValue) {
      this.updateCustomValueInputField(sanitizedValue);
    }

    this.updateSelectedValue(sanitizedValue, false);
  }

  init() {
    const attributeMax = this.data.get('max');
    this.cartTotalSelector = this.data.get('cart-total-selector');
    this.cartTotal = this.cartTotalSelector
      ? parseFloat(
          document
            .querySelector(this.cartTotalSelector)
            .innerHTML.replace(/[^\d.-]/g, ''),
          10
        )
      : 0;
    this.initialValue = parseInt(this.data.get('value'), 10) || '';
    this.cartExistingValue = parseInt(this.data.get('cart-existing-value'), 10) || '';
    this.cartGrandTotalSelector = this.data.get('cart-grand-total-selector');
    this.maximumAmount = -1;

    if (attributeMax) {
      this.maximumAmount = parseInt(attributeMax, 10);
    }
  }

  connect() {
    this.init();

    if (this.initialValue) {
      const initiallySelectedElement = this.element.querySelector(
        `.topup-selection__list-item[data-topup-amount="${this.initialValue}"]`
      );

      if (initiallySelectedElement) {
        this.expandItem(initiallySelectedElement);
      } else {
        this.expandItem(this.customItemTarget);
        this.customValueTarget.value = this.initialValue;
      }

      this.updateSelectedValue(this.initialValue, false);
    }
  }
}

