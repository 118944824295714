import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'input',
    'output'
  ];

  updateOutput(value) {
    const outputValue = (this.data.get('prefix') || '') + value + (this.data.get('suffix') || '');

    this.outputDOMNodes.forEach((outputDOMNode) => outputDOMNode.innerHTML = outputValue);
  }

  @bound
  onInput(e) {
    this.updateOutput(e.target.value);
  }

  connect() {
    const initialValue = this.data.get('initialValue');
    const inputHelperTextDOMNode = this.element.querySelector('.input-helper-text');

    this.onInput = this.onInput.bind(this);

    this.outputDOMNodes = this.outputTargets.length > 0 ? this.outputTargets : [inputHelperTextDOMNode];

    this.inputTarget.addEventListener('input', this.onInput);

    if (initialValue) {
      this.updateOutput(initialValue);
    } else {
      this.updateOutput(this.inputTarget.value);
    }
  }

}
