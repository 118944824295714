import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {

  static targets = [
    'step',
  ];

  connect() {
    this.attributeObserver = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-stepped-navigation-step')) {
        this.onStepAttributeChanged();
      }
    });

    this.attributeObserver.observe(this.element, { attributes: true });
    this.onStepAttributeChanged();
  }

  @bound
  onStepAttributeChanged() {
    this.currentStep = this.normalizeStepValue(this.data.get('step'));
    if (this.hasStepTarget) {
      Array.prototype.forEach.call(this.stepTargets, (stepTarget, index) => {
        if ((index + 1) === this.currentStep) {
          stepTarget.classList.add('active');
        } else {
          stepTarget.classList.remove('active');
        }
      });
    }
  }

  @bound
  normalizeStepValue(step) {
    step = parseInt(step, 10) || 1;

    if (this.hasStepTarget && (step > this.stepTargets.length)) {
      step = this.stepTargets.length;
    }

    return step;
  }

  @bound
  goToStep(step) {
    this.data.set('step', this.normalizeStepValue(step));
  }

  @bound
  goToNextStep() {
    this.goToStep(this.currentStep + 1);
  }

  @bound
  goToPreviousStep() {
    this.goToStep(this.currentStep - 1);
  }

  disconnect() {
    this.attributeObserver.disconnect();
  }

}
