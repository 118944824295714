import ApplicationController from "javascript/controllers/application_controller";

import { copyToClipboard } from 'javascript/utils/utils';
import flashMessage from 'javascript/utils/flash-messages';

export default class extends ApplicationController {
  copyToClipboard() {
    if (copyToClipboard(this.data.get('url'))) {
      flashMessage.flashNotice('Successfully copied to your clipboard');
    } else {
      flashMessage.flashAlert('Unable to copy url. Please manually copy the url.')
    }
  }
}