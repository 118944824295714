import ApplicationController from "javascript/controllers/application_controller";
import { getFormattedPhoneNumberValue, countryDialingCodeData, getDialingCodeFromPhoneNumber, stripDialingCodeFromPhoneNumber } from 'javascript/utils/utils';
import Rails from '@rails/ujs';

function createOption(value, display, { selected, disabled }) {
  return `<option value="${value}" ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}>${display}</options>`;
}

export default class extends ApplicationController {
  static targets = ["dialingCode", "dialingCodeSelection", "numberInput", "hiddenInput"];

  get formattedValue() {
    return this.hiddenInputTarget.value;
  }

  set formattedValue(value) {
    return this.hiddenInputTarget.value = value;
  }

  get rawInputValue() {
    return this.numberInputTarget.value;
  }

  set rawInputValue(value) {
    return this.numberInputTarget.value = value;
  }

  get selectedCountryDialingCode() {
    return this.dialingCodeTarget.value;
  }

  set selectedCountryDialingCode(value) {
    return this.dialingCodeTarget.value = value;
  }

  set formattedDialingCode(value) {
    return this.dialingCodeSelectionTarget.innerText = value ? `+${value}` : '';
  }

  async connect() {
    const countryWhitelist = this.data.has('countryWhitelist') ? this.data.get('countryWhitelist').split(/\s/) : null;
    this.countries = await countryDialingCodeData(countryWhitelist);

    const dialingCode = await getDialingCodeFromPhoneNumber(this.formattedValue) || this.data.get('defaultCode');

    this.dialingCodeTarget.innerHTML = [
      createOption('', '', { selected: !dialingCode, disabled: true }),
      ...this.countries.filter(item => item.cc !== '').map(item => (
        createOption(item.cc, `${item.name} (+${item.cc})`, { selected: item.cc === dialingCode, disabled: false })
      ))
    ].join('');

    this.selectedCountryDialingCode = dialingCode;
    this.formattedDialingCode = dialingCode;

    await this.updateCurrentStateFromFormattedValue();
    await this.updateFormattedValueFromCurrentState();
  }

  onCountrySelectionChange() {
    this.updateFormattedValueFromCurrentState(true);
  }

  onPhoneNumberKeyDown(e) {
    let allowKeyEventThrough = true;

    if (/^[a-zA-Z]$/.test(e.key)) {
      allowKeyEventThrough = false;
    }

    if (!allowKeyEventThrough) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onPhoneNumberInput() {
    this.updateFormattedValueFromCurrentState(true);
  }

  async updateFormattedValueFromCurrentState(fireEvent = false) {
    this.formattedDialingCode = this.selectedCountryDialingCode;
    this.formattedValue = await getFormattedPhoneNumberValue(this.rawInputValue, this.selectedCountryDialingCode);
    if (fireEvent) {
      Rails.fire(this.element, 'howler:phone-input.change', [this.formattedValue]);
    }
  }

  async updateCurrentStateFromFormattedValue() {
    this.rawInputValue = await stripDialingCodeFromPhoneNumber(this.formattedValue);
  }
}