import ApplicationController from "javascript/controllers/application_controller";
import { trackGa4Event } from 'javascript/utils/utils';

export default class extends ApplicationController {
  initialize() {
    if(this.ecommerceData) {
      trackGa4Event(this.eventName, this.ecommerceData);
    }
  }

  get ecommerceData() {
    return JSON.parse(this.data.get('ecommerceData'));
  }

  get eventName() {
    return this.data.get('eventName') || 'purchase';
  }
}

