import ApplicationController from "javascript/controllers/application_controller";

// HACK: IE 11 and EDGE 12 do not support classList on SVG elements
//       so we need some quick and dirty alternatives
function addClass(elem, className) {
  elem.setAttribute('class', `${(elem.getAttribute('class') || '')} ${className}`);
}
function removeClass(elem, className) {
  elem.setAttribute('class', (elem.getAttribute('class') || '').replace(className, '').trim());
}

export default class extends ApplicationController {
  static targets = ['svg', 'category'];

  get categoryIds() {
    return this.categoryTargets.map(category => {
      return this.getCategoryIdFromLabel(category);
    });
  }

  get svgElement() {
    if (this.svgTarget.tagName === 'svg') {
      return this.svgTarget;
    }
    return this.svgTarget.querySelector('svg');
  }

  connect() {
    this.categoryTargets.forEach(category => {
      const categoryId = this.getCategoryIdFromLabel(category);
      const elements = [category];
      if (this.hasSvgTarget) {
        const svgElem = this.findSvgElementForCategory(categoryId);
        if (svgElem) {
          addClass(svgElem, 'hoverable');
          elements.push(svgElem);
          svgElem.addEventListener('click', () => { category.click() });
        }
      }
      elements.forEach(elem => {
        elem.addEventListener('mouseenter', () => { this.onMouseEnter(elements) });
        elem.addEventListener('mouseleave', () => { this.onMouseLeave(elements) });
      });
    });
  }

  disconnect() {

  }

  onMouseEnter(elements) {
    elements.forEach(elem => {
      addClass(elem, 'util-hover');
    });
  }

  onMouseLeave(elements) {
    elements.forEach(elem => {
      removeClass(elem, 'util-hover');
    });
  }

  findSvgElementForCategory(categoryId) {
    return this.element.querySelector(`#cat${categoryId}`);
  }

  getCategoryIdFromLabel(elem) {
    return parseInt(elem.dataset.categoryId, 10);
  }
}