import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'donationsWallAndLeaderboardContainer',
    'donationsBannerContentContainer',
  ];

  connect() {
    this.loadingClassName = 'event-donations-leaderboard-donor-wall-and-banner--loading';
    const pollingInterval = parseInt(this.data.get('pollingInterval'), 10);
    this.pollingIntervalID = pollingInterval ? this.window.setInterval(this.loadData, pollingInterval) : null;
  }

  @bound
  loadData() {
    this.onLoadStart();

    const xhr = XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');

    xhr.onreadystatechange = () => {
      if (xhr.readyState > 3) {
        if (xhr.status === 200) {
          this.onLoadSuccess(xhr.responseText);
        } else {
          this.onLoadError();
        }
      }
    };

    xhr.open('GET', this.data.get('dataUri'), true);
    xhr.send();
  }

  @bound
  onLoadStart() {
    this.element.classList.add(this.loadingClassName);
  }

  onLoadError() {
    this.element.classList.remove(this.loadingClassName);
  }

  onLoadSuccess(response) {
    const responseParsedAsJSON = JSON.parse(response);

    this.element.classList.remove(this.loadingClassName);

    if (this.hasDonationsBannerContentContainerTarget) {
      this.donationsBannerContentContainerTarget.innerHTML = responseParsedAsJSON.donation_banner;
    }

    if (this.hasDonationsWallAndLeaderboardContainerTarget) {
      this.donationsWallAndLeaderboardContainerTarget.innerHTML = responseParsedAsJSON.donor_wall_and_leaderboard;
    }
  }

  disconnect() {
    if (this.pollingIntervalID) {
      window.clearInterval(this.pollingIntervalID);
    }
  }
}
