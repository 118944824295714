import ApplicationController from 'javascript/controllers/application_controller';
import Turbolinks from 'turbolinks';
import { loginWithFacebook, loginWithGoogle } from 'javascript/utils/oauth';

export default class extends ApplicationController {
  get redirectUrl() {
    return this.data.get('redirect');
  }

  facebook() {
    loginWithFacebook((url) => this.performRedirect(url));
  }

  google() {
    loginWithGoogle((url) => this.performRedirect(url));
  }

  performRedirect(url) {
    Turbolinks.visit(this.redirectUrl || url);
  }
}
