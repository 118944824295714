import ApplicationController from "javascript/controllers/application_controller"

export default class extends ApplicationController {

  itemClicked(e) {
    console.log('popup item clicked')
    this.element.dispatchEvent(new CustomEvent('popupItemClicked', {
      bubbles: true,
      detail: {event: e}
    }))
  }

  hide() {
    this.element.setAttribute('data-should-hide', 'true');
  }

  onShouldHideChanged() {
    if (this.element.getAttribute('data-should-hide') === 'true') {
      window.document.body.setAttribute('data-popup-should-show', 'false');
      window.setTimeout(() => {
        this.element.setAttribute('data-should-hide', 'false');
        this.element.setAttribute('data-should-show', 'false');
      }, 175);
    }
  }

  connect() {
    this.attributeObserver = new MutationObserver(mutationList => {
      if (mutationList.find(x => x.attributeName === 'data-should-hide')) {
        this.onShouldHideChanged();
      }
    });

    this.attributeObserver.observe(this.element, { attributes: true });
  }

  disconnect() {
    this.attributeObserver.disconnect();
  }

}
