import ApplicationController from 'javascript/controllers/application_controller';
import bound from 'bound-decorator';

export default class extends ApplicationController {
  static targets = ['button'];

  connect() {
    window.addEventListener('load', this.update);
  }

  @bound
  update() {
    this.buttonTargets.forEach((target) => {
      this.delayedClassRemoval(
        target,
        this.data.get('class-to-remove'),
        parseInt(this.data.get('timeout')),
      );
    });
  }

  delayedClassRemoval(element, className, timeout) {
    window.setTimeout(() => {
      element.classList.remove(className);
      element.disabled = false;
    }, timeout);
  }
}
