import FormErrorDisplayController from "javascript/controllers/consumer_portal/form_error_display_controller";
import { trackGa4Event } from 'javascript/utils/utils';
export default class extends FormErrorDisplayController {
  static targets = [
    'error',
    'nextButtonText',
    'formType',
    'continueInput'
  ];

  assignAllToggle({ target: { checked } }) {
    this.nextButtonTextTarget.textContent = checked ? this.data.get('continue-button-label') : this.data.get('next-ticket-button-label');
  }

  beforeSubmit() {
    this.element.classList.add('contents-loading');
  }

  continueButtonClicked() {
    this.continueInputTarget.value = 'true'
  }

  trackAssignment() {
    const assignType = this.formTypeTarget.value
    trackGa4Event('assign_ticket', { assignment_type: assignType })
  }

  submitError({ detail: [response, error, xhr] }) {
    this.element.classList.remove('contents-loading');

    super.submitError({ detail: [response, error, xhr] });
  }

  errorResponseObject(response) {
    return response.errors.ticket_order_item;
  }
}

