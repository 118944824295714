import ApplicationController from "javascript/controllers/application_controller";

const BANK_TYPES = [
  'local',
  'payPal',
  'donation',
  'mtnMobileMoney',
  'transferWise'
];

const BANK_CLASS_2_BANK_TYPE = {
  'CallpayBankAccount': 'local',
  'PayPalBankAccount': 'payPal',
  'DonationBankAccount': 'donation',
  'MtnMobileMoneyBankAccount': 'mtnMobileMoney',
  'TransferwiseBankAccount': 'transferWise'
}

function capitalize(s) {
  return `${s[0].toUpperCase()}${s.substr(1)}`;
}

export default class extends ApplicationController {
  static targets = [
    ...BANK_TYPES.map(bank_type => `${bank_type}BankAccountFields`),
    'errors',
    'autoCashoutCheckBox',
    'agreeToDonateCheckbox',
    'submitButton',
    'bankAccountType',
  ];

  displayBank(selectedBankType) {
    this.selectedBankType = selectedBankType;

    this.updateSubmitButtonState();

    this.fieldsTargetForBank(selectedBankType).classList.remove('util-hidden');
    const filteredBankTypes = BANK_TYPES.filter(bank_type => bank_type !== selectedBankType)
    filteredBankTypes.forEach(bank_type => {
      if (this.hasFieldsTargetForBank(bank_type)) {
        this.fieldsTargetForBank(bank_type).classList.add('util-hidden');
      }
    })
  }

  updateSubmitButtonState() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.removeAttribute('disabled');

      if (this.selectedBankType === 'donation' && this.agreeToDonateCheckboxTarget.checked === false) {
        this.submitButtonTarget.setAttribute('disabled', 'disabled');
      }
    }
  }

  onChangeBankAccountAgreedToDonate() {
    this.updateSubmitButtonState();
  }

  changeType() {
    for (let bank_type of BANK_TYPES) {
      if (BANK_CLASS_2_BANK_TYPE[this.bankAccountTypeTarget.value] === bank_type) {
        this.displayBank(bank_type);
        break;
      }
    }
    if (this.hasErrorsTarget) {
      this.errorsTarget.parentElement.removeChild(this.errorsTarget);
    }
  }

  radioTargetForBank(bank) {
    return this[`${bank}BankAccountRadioTarget`];
  }

  hasRadioTargetForBank(bank) {
    return this[`has${capitalize(bank)}BankAccountRadioTarget`];
  }

  fieldsTargetForBank(bank) {
    return this[`${bank}BankAccountFieldsTarget`];
  }

  hasFieldsTargetForBank(bank) {
    return this[`has${capitalize(bank)}BankAccountFieldsTarget`];
  }
}
