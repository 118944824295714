import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'javascript/analytics/sentry';

import 'javascript/vendor/jquery'

global.$ = $;
global.jQuery = $;

import 'javascript/config/config';

import Turbolinks from 'turbolinks';
import Rails from '@rails/ujs';

import 'javascript/compatibility/turbolinks_scroll_fix';
import 'javascript/compatibility/template_tag';

import 'javascript/compatibility/polyfills';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import 'javascript/analytics/google_analytics_tracking';
import 'javascript/analytics/facebook_pixel';
import 'javascript/support_chats/freshdesk_live_chat';
import 'javascript/support_chats/fresh_chat';

import 'foundation-sites/dist/js/plugins/foundation.core';

import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard'; // for accordion
import 'foundation-sites/dist/js/plugins/foundation.util.triggers'; // for sticky

import 'foundation-sites/dist/js/plugins/foundation.accordion';
import 'foundation-sites/dist/js/plugins/foundation.sticky';

import 'javascript/config/init-foundation';

import 'javascript/components/detect-mobile';
import 'javascript/components/sessions';
import 'javascript/components/ticket-accordion';
import 'javascript/components/sticky-fallback';
import 'javascript/components/purchase-flash';

Turbolinks.start();
Rails.start();

const application = Application.start();

const consumerContext = require.context("javascript/controllers/consumer_portal", true, /.js$/);
application.load(definitionsFromContext(consumerContext));

const sharedContext = require.context("javascript/controllers/shared", true, /.js$/);
application.load(definitionsFromContext(sharedContext));

document.addEventListener('turbolinks:before-cache', function () {
  [].forEach.call(document.body.querySelectorAll('script'), (e) => {
    if (e.parentElement) e.parentElement.removeChild(e);
  });
});
