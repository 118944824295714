import ApplicationController from 'javascript/controllers/application_controller';
export default class extends ApplicationController {
  static targets = ['teamMember', 'teamMemberCount', 'teamMemberTicketIdsField', 'timeslotSelect', 'maxTeamMemberWarning', 'incompatibleTicketsWarning'];

  connect() {
    this.ticketTypes = JSON.parse(this.data.get('ticketTypes'));
    this.timeslots = this.extractTimeslots(this.ticketTypes);
  }

  extractTimeslots(ticketTypes) {
    const extractedTimeslots = new Map();
    ticketTypes.forEach(ticketType => ticketType.available_timeslots.forEach(timeslot => extractedTimeslots.set(timeslot.id, timeslot)));
    return extractedTimeslots;
  }

  onTeamMemberClicked(event) {
    const element = event.currentTarget;

    this.toggleTeamMemberSelection(element);
    const selectedTeamMembers = this.selectedTeamMembers;
    this.setTeamMemberTicketIds(selectedTeamMembers);
    this.setTeamMemberCount(selectedTeamMembers.length);
  }

  onTimeslotChanged() {
    const selectedTimeslotId = parseInt(this.timeslotSelectTarget.value);
    const selectedTimeslot = this.timeslots.get(selectedTimeslotId);

    this.updateTeamMembers(selectedTimeslot, "create_team");
  }

  onJoinTeamSelected(ev) {
    const joinTeamTimeslotId = ev.detail.timeslotId;
    const joinTeamTimeslot = this.timeslots.get(joinTeamTimeslotId);

    this.updateTeamMembers(joinTeamTimeslot, "join_team");
  }

  onJoinTeamCancelled() {
    this.showHideWarningMessages(false, false);
  }

  onTeamOptionChanged(event) {
    if (event.detail.selectedOption === 'create_team') {
      this.onTimeslotChanged();
    }
  }

  updateTeamMembers(selectedTimeslot, context) {

    this.maxTeamMembers = selectedTimeslot ? Math.min(selectedTimeslot.quantity, this.teamMemberTargets.length) : this.teamMemberTargets.length;
    let incompatibleTicketsPresent = false;

    this.teamMemberTargets.forEach(member => {
      const canJoin = this.teamMemberCanJoinTeam(member, selectedTimeslot);
      if (canJoin) {
        this.enableTeamMember(member);
      }
      else {
        this.deselectTeamMember(member);
        this.disableTeamMember(member);
        incompatibleTicketsPresent = true;
      }
    });

    const selectedTeamMembers = this.selectedTeamMembers;
    this.setTeamMemberCount(selectedTeamMembers.length);
    this.setTeamMemberTicketIds(selectedTeamMembers);

    this.showHideWarningMessages(incompatibleTicketsPresent, this.teamMemberTargets.length > this.maxTeamMembers);
  }

  toggleTeamMemberSelection(teamMember) {
    if (this.isSelected(teamMember)) {
      this.deselectTeamMember(teamMember);
    }
    else if (this.teamHasSpace() && this.isEnabled(teamMember)) {
      this.selectTeamMember(teamMember);
    }
  }

  selectTeamMember(teamMember) {
    teamMember.setAttribute('selected', 'true');
    teamMember.querySelector('.team-member-carousel__item-image--selected').classList.remove('util-hidden');
    teamMember.querySelector('.team-member-carousel__item-image').classList.add('util-hidden');
  }

  deselectTeamMember(teamMember) {
    teamMember.removeAttribute('selected');
    teamMember.querySelector('.team-member-carousel__item-image').classList.remove('util-hidden');
    teamMember.querySelector('.team-member-carousel__item-image--selected').classList.add('util-hidden');
  }

  enableTeamMember(teamMember) {
    teamMember.removeAttribute('disabled');
    teamMember.querySelector('[data-selectable-team-member="true"]').classList.remove('util-hidden');
    teamMember.querySelector('[data-selectable-team-member="false"]').classList.add('util-hidden');
    teamMember.classList.remove('team-member-carousel__item--disabled');
  }

  disableTeamMember(teamMember) {
    teamMember.setAttribute('disabled', 'true');
    teamMember.classList.add('team-member-carousel__item--disabled');
    teamMember.querySelector('[data-selectable-team-member="true"]').classList.add('util-hidden');
    teamMember.querySelector('[data-selectable-team-member="false"]').classList.remove('util-hidden');
  }

  isEnabled(teamMember) {
    return !teamMember.hasAttribute('disabled');
  }


  isSelected(teamMember) {
    return teamMember.hasAttribute('selected');
  }

  get selectedTeamMembers() {
    return this.teamMemberTargets.filter(teamMember => this.isSelected(teamMember));
  }

  setTeamMemberCount(count) {
    this.teamMemberCountTarget.innerHTML = `${count}/${this.maxTeamMembers} selected`;
  }

  setTeamMemberTicketIds(selectedTeamMembers) {
    const selectedIds = selectedTeamMembers.reduce((ids, teamMember) => ids + ' ' + teamMember.dataset.ticketId, '');
    this.teamMemberTicketIdsFieldTarget.value = selectedIds.trim();
  }

  teamHasSpace() {
    return this.selectedTeamMembers.length < this.maxTeamMembers;
  }

  teamMemberCanJoinTeam(teamMember, teamTimeslot) {
    const teamMemberTicketTypeId = parseInt(teamMember.dataset.ticketTypeId);
    const teamMemberTicketType = this.ticketTypes.find(ticketType => ticketType.id === teamMemberTicketTypeId);

    return teamTimeslot && teamMemberTicketType && !teamMemberTicketType.solo_only && teamMemberTicketType.available_timeslots.some(timeslot => timeslot.id === teamTimeslot.id);
  }

  showHideWarningMessages(incompatibleTicketsPresent, notEnoughSpaces) {
    if (notEnoughSpaces) {
      this.maxTeamMemberWarningTarget.classList.remove('util-hidden');
    }
    else {
      this.maxTeamMemberWarningTarget.classList.add('util-hidden');
    }

    if (incompatibleTicketsPresent) {
      this.incompatibleTicketsWarningTarget.classList.remove('util-hidden');
    }
    else {
      this.incompatibleTicketsWarningTarget.classList.add('util-hidden');
    }
  }
}
