import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    'tabSelectorTemplate',
    'tabContentTemplate',
    'tabSelectorContainer',
    'tabContentContainer',
    'tabSelector',
    'tabContent',
    'streamContainer',
  ];

  connect() {
    this.localData = { items: [] };
    this.hasSetInitialContent = false;

    if (window.fcWidget) {
      window.fcWidget.hide();
    }

    this.fetchDataAndUpdateContent();
    const pollingInterval = parseInt(this.data.get('pollingInterval'), 10);
    this.pollingIntervalID = pollingInterval ? this.window.setInterval(this.fetchDataAndUpdateContent, pollingInterval) : null;
  }

  selectFirstStream() {
    this.selectStreamItem(this.element.querySelectorAll('[data-action~="click->event-streams-page#onStreamListItemClick"]')[0]);
  }

  @bound
  fetchDataAndUpdateContent() {
    const url = this.data.get('dataUri') + '?cachebust=' + Math.round((Math.random() * 1000000));
    const xhr = XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');

    xhr.onreadystatechange = () => {
      if (xhr.readyState > 3) {
        if (xhr.status === 200) {
          this.onFetchDataSuccess(JSON.parse(xhr.responseText));
        }
      }
    };

    xhr.open('GET', url, true);
    xhr.send();
  }

  @bound
  onFetchDataSuccess(response) {
    const remoteData = response;
    if (this.areJSONObjectsDifferent(this.localData, remoteData)) {
      this.updateContentItems(remoteData.items);
    }
  }

  updateContentItems(remoteDataItems) {
    this.updateTabSelectorsAndContent(remoteDataItems);

    this.localData.items = remoteDataItems;

    if (!this.hasSetInitialContent) {
      this.selectFirstVisibleTab();
      this.selectFirstStream();
      this.hasSetInitialContent = true;
    }
  }

  getFirstChild(node) {
    return node.querySelector('*');
  }

  updateTabSelectorsAndContent(items) {
    this.tabSelectorTargets.forEach((tabSelectorTarget) => {
      if (tabSelectorTarget.getAttribute('data-keep') !== 'true') {
        this.tabSelectorContainerTarget.removeChild(tabSelectorTarget);
      }
    });

    this.tabContentTargets.forEach((tabContentTarget) => {
      if (tabContentTarget.getAttribute('data-keep') !== 'true') {
        this.tabContentContainerTarget.removeChild(tabContentTarget);
      }
    });

    items.slice().reverse().forEach((item, index) => {
      const id = 'event-streams-page-tab-item-' + String((items.length - 1) - index);

      const tabSelectorTemplateInstanceNode = this.tabSelectorTemplateTarget.content.cloneNode(true);
      this.tabSelectorContainerTarget.insertBefore(tabSelectorTemplateInstanceNode, this.tabSelectorContainerTarget.firstChild);
      const newTabSelectorItem = this.getFirstChild(this.tabSelectorContainerTarget);
      newTabSelectorItem.outerHTML = newTabSelectorItem.outerHTML
        .replace('{{id}}', id)
        .replace('{{name}}', item.name)
        .replace('{{icon}}', item.icon);

      const tabContentTemplateInstanceNode = this.tabContentTemplateTarget.content.cloneNode(true);
      this.tabContentContainerTarget.insertBefore(tabContentTemplateInstanceNode, this.tabContentContainerTarget.firstChild);
      const newTabContentItem = this.getFirstChild(this.tabContentContainerTarget);
      newTabContentItem.outerHTML = newTabContentItem.outerHTML
        .replace('{{id}}', id)
        .replace('{{name}}', item.name)
        .replace('{{content}}', item.content);
    });

    this.moveChatTabSelectorToSecondPosition();
    this.selectTab(this.selectedTabID);
  }

  moveChatTabSelectorToSecondPosition() {
    const chatTabSelectorDOMNode = this.tabSelectorTargets.find(tabSelector => tabSelector.getAttribute('data-id') === 'chat');
    if (chatTabSelectorDOMNode && this.tabSelectorContainerTarget.firstChild.insertAdjacentElement) {
      this.tabSelectorContainerTarget.firstChild.insertAdjacentElement('afterend', chatTabSelectorDOMNode);
    }
  }

  isTabSelectorItemHidden(tabSelectorDOMNode) {
    return tabSelectorDOMNode.style.display === 'none';
  }

  toggleTab(tabID) {
    if (tabID === this.selectedTabID) {
      this.selectTab(null);
    } else {
      this.selectTab(tabID);
    }
  }

  selectFirstVisibleTab() {
    const firstVisibleTabSelector = this.tabSelectorTargets.find(item => this.isTabSelectorItemHidden(item) === false);
    this.selectTab(firstVisibleTabSelector.getAttribute('data-id'));
  }

  selectTab(tabID) {
    this.selectedTabID = tabID;

    this.tabContentContainerTarget.scrollTop = 0;

    if (tabID) {
      this.element.className = 'event-streams-page event-streams-page--tab-selected event-streams-page--active-tab-' + tabID;
    } else {
      this.element.className = 'event-streams-page';
    }

    this.tabContentTargets.forEach((tabContent) => {
      if (tabContent.getAttribute('data-id') === tabID) {
        tabContent.classList.add('active');
      } else {
        tabContent.classList.remove('active');
      }
    });

    this.tabSelectorTargets.forEach((tabSelector) => {
      if (tabSelector.getAttribute('data-id') === tabID) {
        tabSelector.classList.add('active');
      } else {
        tabSelector.classList.remove('active');
      }
    });
  }

  onTabItemClick(e) {
    e.stopPropagation();
    this.toggleTab(e.currentTarget.parentElement.getAttribute('data-id'));
  }

  onCloseTabButtonClick(e) {
    e.stopPropagation();
    this.selectTab(null);
  }

  onStreamListItemClick(e) {
    e.stopPropagation();
    this.selectStreamItem(e.currentTarget);
  }

  selectStreamItem(item) {
    if (item) {
      this.streamContainerTarget.innerHTML = item.getAttribute('data-video-iframe');
    }
  }

  areJSONObjectsDifferent(oldJSON, newJSON) {
    return JSON.stringify(oldJSON) !== JSON.stringify(newJSON);
  }

}
