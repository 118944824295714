import ApplicationController from "javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['inviteButton', 'repSelect'];

  toggleInviteButton({ detail: [tab] }) {
    if (!this.hasInviteButtonTarget) {
      return;
    }

    if (tab === 'panel1') {
      this.inviteButtonTarget.classList.add('util-invisible');
      this.repSelectTarget.classList.remove('util-hidden');
    } else {
      this.inviteButtonTarget.classList.remove('util-invisible');
      this.repSelectTarget.classList.add('util-hidden');
    }
  }
}