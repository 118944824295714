import ApplicationController from "javascript/controllers/application_controller";
import replaceContent from "javascript/utils/replace_content";
import { trackPageView } from 'javascript/utils/utils';

function isJavascript(xhr) {
  return getContentType(xhr).indexOf('javascript') !== -1;
}

function getContentType(xhr) {
  return xhr.getResponseHeader('Content-Type');
}

export default class extends ApplicationController {
  get contentLoaderContainer() {
    return document.getElementById(this.data.get('containerId'));
  }

  get notInModal() {
    return this.data.get('not-in-modal');
  }

  replaceBody({ detail: [response, , xhr] }) {
    if (!isJavascript(xhr)) {
      trackPageView(xhr);
      replaceContent(this.contentLoaderContainer, response.body.childNodes);
      if (!this.notInModal) {
        document.querySelector('[data-controller="modal"]').setAttribute('data-modal-should-show', 'false');
      }
    }
  }
}
