import Rails from '@rails/ujs';
import { isPaymentPending } from 'javascript/utils/payment_status';
import ApplicationController from 'javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ['addDonationButton', 'amountsContainer'];

  addDonationButtonClicked(e) {
    if (!this.isDisabled()) {
      const hiddenClass = 'donation-selection__amounts--hidden';
      this.amountsContainerTarget.classList.toggle(hiddenClass);
      this.addDonationButtonTarget.classList.toggle('btn--inverse');

      if (this.amountsContainerTarget.classList.contains(hiddenClass)) {
        Rails.fire(
          this.amountsContainerTarget.childNodes[0],
          'howler:donation-selection-closed',
        );
      }
    }
  }

  openDonationSelection(e) {
    if (e.detail[0]) {
      this.amountsContainerTarget.classList.remove(
        'donation-selection__amounts--hidden',
      );
      this.addDonationButtonTarget.classList.add('btn--inverse');
    }
  }

  isDisabled() {
    return this.disabled === 'true' || isPaymentPending(this.orderId);
  }

  get orderId() {
    return this.data.get('orderId');
  }

  get disabled() {
    return this.data.get('disabled');
  }
}
