import bound from 'bound-decorator';
import {
  isPaymentPending,
  anyPaymentPending,
} from 'javascript/utils/payment_status';
import ApplicationController from 'javascript/controllers/application_controller';
import fullScreenSpinner from 'javascript/utils/full-screen-spinner';

export default class extends ApplicationController {
  static targets = ['step'];

  connect() {
    let connected = true;
    this.window.addEventListener('pageshow', () => {
      if (connected == false && !this.orderId && this.isAnyPaymentPending()) {
        fullScreenSpinner.show();
        this.window.location.reload();
      }

      if (this.isDisabled()) {
        if (!this.window.location.pathname.match(this.postAssignPaths)) {
          fullScreenSpinner.show();
          this.redirect();
        } else {
          this.disableSteps();
        }
      }
      connected = false;
    });
  }

  @bound
  disableSteps() {
    this.stepTargets.forEach((step) => {
      if (!step.classList.contains('is-current')) {
        step.classList.remove('is-complete');
        this.removeLink(step);
      }
    });
  }

  @bound
  redirect() {
    this.window.location.replace(this.summaryPath);
  }

  removeLink(element) {
    const content = element.querySelector(
      'a.purchase-progress__step-link',
    )?.innerHTML;

    if (content) {
      element.querySelector('div.purchase-progress__step-wrapper').innerHTML =
        content;
    }
  }

  @bound
  isAnyPaymentPending() {
    return anyPaymentPending();
  }

  @bound
  isDisabled() {
    return isPaymentPending(this.orderId);
  }

  get summaryPath() {
    return this.data.get('summaryPath');
  }

  get disabled() {
    return this.data.get('disabled');
  }

  get postAssignPaths() {
    return this.data.get('postAssignPaths');
  }

  get orderId() {
    return this.data.get('orderId');
  }
}
