import bound from 'bound-decorator';
import ApplicationController from "javascript/controllers/application_controller";
import Rails from "@rails/ujs";
import { loginWithFacebook, loginWithGoogle } from "javascript/utils/oauth";

export default class extends ApplicationController {
  static targets = ['errorContainer', 'error', 'input'];

  facebook() {
    loginWithFacebook(this.onSuccess);
  }

  google() {
    loginWithGoogle(this.onSuccess);
  }

  beforeSubmit() {
    this.element.classList.add('contents-loading');
  }

  submitSuccess({ detail: [, , xhr] }) {
    this.element.classList.remove('contents-loading');
    this.onSuccess(xhr.getResponseHeader('Location'));
  }

  removeError() {
    this.errorContainerTarget.innerHTML = '';
  }

  submitError({ detail: [response, , xhr] }) {
    this.element.classList.remove('contents-loading');

    let errors;
    switch (xhr.status) {
      case 422: errors = response.errors; break;
      case 401: errors = { email: ['Email or password is incorrect'] }; break
    }

    this.errorContainerTarget.classList.remove('util-hidden');
    this.errorTarget.innerHTML = Object.keys(errors).reduce((string, key) => (`
      ${string}
      <div>${errors[key][0]}</div>
    `), '');

    [].forEach.call(this.inputTargets, input => {
      if (errors[input.dataset.field]) {
        input.classList.add('has-error');
      } else {
        input.classList.remove('has-error');
      }
    });
  }

  @bound
  onSuccess(url) {
    Rails.fire(this.element, "howler:session.success", [url]);
  }
}
