import ApplicationController from "javascript/controllers/application_controller";
import PopperJs from 'popper.js';

export default class extends ApplicationController {

  static targets = [
    'selectMenu',
    'selectMenuButton',
  ];

  onSelectMenuButtonClick() {
    this.popup = document.querySelector('.popup');
    this.popupContent = document.querySelector('.popup__content');
    this.showPopup();
  }

  updatePopupContent(node) {
    if (node) {
      this.popupContent.innerHTML = node.innerHTML;
    } else {
      this.popupContent.innerHTML = '';
    }
  }

  showPopup() {
    this.updatePopupContent(this.selectMenuTarget);
    window.document.body.setAttribute('data-popup-should-show', 'true');

    if (this.data.get('in-header') === 'true') {
      this.popup.classList.add('popup--in-header');
    } else {
      this.popup.classList.remove('popup--in-header');
    }

    new PopperJs(this.element, this.popup, {
      placement: this.data.get('popup-placement'),
    });

    window.setTimeout(() => {
      this.popup.setAttribute('data-should-show', 'true');
    }, 16);
  }
}
